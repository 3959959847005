import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { IPharmacy } from "../interfaces/IPharmacy";
import { fetchPharmacySelectOptions } from "./Service";

interface PharmacyProps {
  id?: number | null,
  pharmacy: IPharmacy | undefined,
  setPharmacy: (pharmacy: IPharmacy | undefined) => void,
  onChange?: (pharmacy: IPharmacy | undefined) => void
  isDisabled?: boolean,
}

export const PharmacySelect: React.FC<PharmacyProps> = ({
                                                          id = null,
                                                          pharmacy = undefined,
                                                          setPharmacy = () => {
                                                          },
                                                          onChange = () => {
                                                          },
                                                          isDisabled = false
                                                        }) => {

  const [pharmacies, setPharmacies] = useState<Array<IPharmacy>>([]);

  useEffect(() => {
    if (pharmacies.length > 0 && id !== null) {
      onPharmacyChanges(pharmacies.find(pharmacy => Number(pharmacy.id) === Number(id)));
    }
  }, [pharmacies]);

  const loadOptions = (inputValue: string, callback: any) => {
    if (inputValue) {
      const filtered = pharmacies.filter(pharmacy => pharmacy.name.toLowerCase().includes(inputValue.toLowerCase()));
      callback(filtered.map((pharmacy: any) => ({ label: pharmacy.name, value: pharmacy.id })));
      return;
    }
    fetchPharmacySelectOptions().then((results: Array<IPharmacy>) => {
      const pharmacies: Array<any> = results.filter((pharmacy: any) => pharmacy.active === 1); // remove disabled pharmacies
      setPharmacies(pharmacies);
      callback(pharmacies.map((pharmacy: any) => ({ label: pharmacy.name, value: pharmacy.id })));
    }).catch(error => console.error("An error occurred", error));
  };

  const onPharmacyChanges = (value: IPharmacy | undefined) => {
    const pharmacy: IPharmacy | undefined = pharmacies.find((pharmacy: IPharmacy) => pharmacy.id === value?.id);
    setPharmacy(pharmacy);
    onChange(pharmacy);
  };

  return (
    <>
      <div className="form-group">
        <label className="common-form-label">Nome Farmacia</label>
        <AsyncSelect
          className="pharmacy-search"
          classNamePrefix="custom-dropdown"
          placeholder=""
          defaultOptions
          loadOptions={loadOptions}
          isDisabled={isDisabled}
          value={{ label: pharmacy?.name, value: pharmacy?.id }}
          onChange={(value) => {
            const pharmacy: IPharmacy | undefined = pharmacies.find(p => p.id === value?.value);
            onPharmacyChanges(pharmacy);
          }} />
      </div>
      <div className="row">
        <div className="col-12 col-md-3">
          <label className="common-form-label">Persona di riferimento</label>
          <input type="text" className="form-control common-form-input" id="contact" name="contact"
                 readOnly={true}
                 defaultValue={pharmacy?.contact} />
        </div>
        <div className="col-12 col-md-3">
          <label className="common-form-label">Telefono</label>
          <input type="text" className="form-control common-form-input" id="phone" name="phone"
                 readOnly={true}
                 defaultValue={pharmacy?.phone} />
        </div>
        <div className="col-12 col-md-3">
          <label className="common-form-label">Città</label>
          <input type="text" className="form-control common-form-input" id="city" name="city"
                 readOnly={true}
                 defaultValue={pharmacy?.city} />
        </div>
        <div className="col-12 col-md-3">
          <label className="common-form-label">Indirizzo</label>
          <input type="text" className="form-control common-form-input" id="address" name="address"
                 readOnly={true}
                 defaultValue={pharmacy?.address} />
        </div>
      </div>
    </>
  );
};
