import React from 'react'

interface KPICardProps {
    title: React.ReactNode,
    value: number | string | undefined,
    description?: string,
    hasTrafficLight?: boolean,
    trafficLightColor?: string,
    loading?: boolean,
    active?: boolean,
    callback?: () => any,
    width?: string,
    height?: string,
    fontSize?: string,
    dataToggle?: string,
    dataTarget?: string,
    key?: number
}

export const KPI: React.FC<KPICardProps> = ({
    title = 'Titolo',
    value = 0,
    description = '',
    hasTrafficLight = true,
    trafficLightColor = 'danger',
    loading = false,
    active = false,
    callback = () => {
    },
    width = '12.5rem',
    height = '10rem',
    fontSize = '1.2rem',
    dataToggle = "",
    dataTarget = ""
}) => {

    const trafficLight = (color: string) => {
        return (
            <>
                {!loading && <div
                    className={`traffic-light ${hasTrafficLight ? `bg-${color}` : 'bg-transparent'} animate__animated animate__bounceIn`}
                    style={{ width: '20px', height: '20px', borderRadius: '10px' }} />
                }
            </>
        );
    }

    return (
        <div
            style={{
                width: width,
                height: height,
                position: "relative",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                margin: '.5rem'
            }}>
            {
                loading &&
                <div className="spinner-wrapper" style={{
                    position: 'absolute',
                    zIndex: 2,
                    width: '100%',
                    height: '100%',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: '5px',
                    backgroundColor: "rgba(0, 0 , 0, .85)"
                }}>
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Caricamento...</span>
                    </div>
                </div>
            }
            <div className={"card text-white MuiPaper-elevation2" + (active && " card-active")}
                style={{
                    position: 'absolute',
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'black'
                }}
                data-toggle={dataToggle}
                data-target={dataTarget}
                onClick={callback()}
            >
                <div className="card-header">
                    <div className="d-flex flex-row justify-content-end align-items-center">
                        {trafficLight(trafficLightColor)}
                    </div>
                </div>
                <div className="card-body" style={{
                    marginTop: '-2rem' // traffic light
                }}>
                    <h1 className="card-text text-center mb-0" style={{ color: 'white' }}>{value}</h1>
                    <p className="text-center" style={{
                        fontSize: fontSize,
                        lineHeight: fontSize
                    }}>
                        {title}
                    </p>
                </div>
                <div className="card-footer">
                    <small style={{
                        fontSize: '.6rem',
                        lineHeight: '.6rem'
                    }}>{description}</small>
                </div>
            </div>
        </div>
    );
}
