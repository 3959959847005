import React, { useEffect, useState } from 'react';
import { fetchAgentStock, fetchAllAgents, fetchAllProducts, updateStock } from './Service';
import Select from 'react-select';
import useAuth from '../../../hooks/useAuth';
import { useToasts } from 'react-toast-notifications'
import MaterialTable from 'material-table';
import './style.scss';
import { StockPharmacySpecialistAudit } from './StockPharmacySpecialistAudit';
import { EnUserRole } from '../../../helpers/PrivateRoute/PrivateRoute';
import { DateTime } from 'luxon';
import { PatchedPagination } from "../../../components/PatchedPagination/PatchedPagination";

interface StockManagementProps {
}

export const StockPharmacySpecialist: React.FC<StockManagementProps> = ({ }) => {
    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [agents, setAgents] = useState<Array<any>>([]);
    const [agentSelected, setAgentSelected] = useState<any>(null);
    const [agentsStock, setAgentsStock] = useState<any[]>([]);
    const { addToast } = useToasts();

    useEffect(() => {
        load().catch(error => console.error(error));
    }, []);

    useEffect(() => {
        if (agentSelected) {
            loadAgentProductsStock().catch(error => console.error(error));
        }
    }, [agentSelected]);

    const load = async () => {
        setLoading(true);
        if (user.role === EnUserRole.AGENT) {
            setAgentSelected(user.sub);
        } else {
            const agents: Array<any> = await fetchAllAgents() as Array<any>;
            agents.forEach(pharmacySpecialist => {
                pharmacySpecialist.label = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
                pharmacySpecialist.value = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
            });
            setAgents(agents);
        }
        setLoading(false);
    }

    const loadAgentProductsStock = async () => {
        try {
            setLoading(true);
            const products: Array<any> = await fetchAllProducts() as Array<any>;
            const stock: any = await fetchAgentStock(agentSelected);
            const agentsStock: Array<any> = products.filter(value => value.sample === 1).map((product: any, index: number) => {
                const stockFiltered: Array<any> = stock.filter((value: any) => value.productId === product.id);
                if (stockFiltered.length !== 0) {
                    const first: any = stockFiltered[0];
                    Object.assign(product, {
                        uid: index,
                        quantity: first.quantity,
                        updatedOn: first.updatedOn,
                        new_quantity: 0
                    });
                } else {
                    Object.assign(product, {
                        uid: index,
                        quantity: 0,
                        updatedOn: null,
                        new_quantity: 0
                    });
                }
                return product;
            });
            setAgentsStock(agentsStock);
            setLoading(false);
        } catch (error) {
            console.error('Stock Pharmacy Specialist', error);
            setLoading(false);
            addToast('Errore durante il recupero delle informazioni necessari.', {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    const handleUpdateStock = async () => {
        try {
            setSubmit(true);
            const dto: Array<any> = agentsStock.map(value => {
                const stock = { productId: 0, quantity: 0 };
                stock.productId = value.id;
                stock.quantity = value.new_quantity || 0;
                return stock;
            }).filter(value => value.quantity !== 0);
            await updateStock(agentSelected, dto);
            addToast('Salvato con successo !', { appearance: 'success', autoDismiss: true });
            setSubmit(false);
            await loadAgentProductsStock();
        } catch (error) {
            console.error('Stock Pharmacy Specialist', error);
            setSubmit(false);
            addToast('Errore durante l\'aggiornamento dei dati.', { appearance: 'error', autoDismiss: true });
        }
    };

    const handleAgent = (value: any) => {
        if (!agentSelected || value.id != agentSelected) {
            setAgentsStock([]);
            setAgentSelected(value.id);
        }
    }

    const parseDatetime = (date: any): string => {
        return new Date(date).toLocaleDateString('it-IT', {
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    return (
        <div className="content">
            <div className="container-fluid container-material-table-height-fix">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    {
                        [EnUserRole.ADMIN, EnUserRole.PM].includes(user.role) && agents.length > 0 &&
                        <form style={{ width: '100%' }}>
                            <div className="row justify-content-between align-items-end">
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="common-form-label" htmlFor="agentsSelect">Pharmacy Specialist</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="custom-dropdown"
                                        placeholder="Filtra per"
                                        name="Pharmacy Specialist"
                                        options={agents}
                                        onChange={(value) => handleAgent(value)}
                                    />
                                </div>
                                <div
                                    className="col-12 col-md-auto mb-2 d-flex flex-row justify-content-end align-items-center">
                                    {
                                        [EnUserRole.ADMIN].includes(user.role) && agentSelected &&
                                        <button type="button"
                                            className="btn common-btn"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                            disabled={submit}
                                            onClick={handleUpdateStock}>
                                            {
                                                submit &&
                                                <div className="spinner-wrapper mr-2">
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only text-light">Loading...</span>
                                                    </div>
                                                </div>
                                            }
                                            Aggiungi
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </div>

                <br />

                <MaterialTable
                  components={{
                      // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                      Pagination: PatchedPagination
                  }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Nessun dato presente',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'righe'
                        }
                    }}
                    columns={[
                        {
                            title: 'Nome del prodotto',
                            field: 'name',
                            editable: 'never',
                            type: 'string'
                        },
                        {
                            title: 'Aggiornato il',
                            field: 'updatedOn',
                            type: 'string',
                            editable: 'never',
                            render: (rowData: any) => {
                                const date: DateTime = DateTime.fromISO(rowData.updatedOn);
                                return (date.isValid ? parseDatetime(date) : 'Non assegnato')
                            }
                        },
                        {
                            title: 'Scorta attuale',
                            field: 'quantity',
                            type: 'numeric',
                            editable: 'never',
                            align: "left",
                            filtering: false,
                            cellStyle: { textAlign: 'left' }
                        },
                        {
                            title: 'Aggiorna stock',
                            field: 'new_quantity',
                            type: "numeric",
                            filtering: false,
                            editable: 'always',
                            hidden: [EnUserRole.PM, EnUserRole.AGENT].includes(user.role),
                            render: rowData =>
                                <div className="d-flex flex-row justify-content-end align-items-center">
                                    <span className="ml-2 material-icons MuiIcon-root" aria-hidden="true"
                                        title="Filter">add</span>
                                    <input type="text" style={{ maxWidth: '5rem' }} value={rowData.new_quantity}
                                        pattern="[0-9]*" onChange={
                                            (event) => {
                                                const _agentStock = [...agentsStock];
                                                const index = _agentStock.findIndex(item => item.uid == rowData.uid);
                                                const value = event.target.value;
                                                _agentStock[index].new_quantity = parseInt(value) || '';
                                                setAgentsStock(_agentStock);
                                            }
                                        } />
                                </div>
                        },
                    ]}
                    isLoading={loading || submit}
                    data={agentsStock}
                    options={{
                        showTitle: false,
                        search: false,
                        filtering: true,
                        pageSize: 25,
                        toolbar: false,
                        maxBodyHeight: '75vh',
                        pageSizeOptions: [10, 25, 50],
                        emptyRowsWhenPaging: false,
                        draggable: false,
                    }}
                    detailPanel={rowData => {
                        return (
                            <div
                                className="d-flex flex-row justify-content-center align-items-center py-2 px-4"
                                style={{
                                    backgroundColor: '#f4f4f4'
                                }}>
                                <StockPharmacySpecialistAudit agentId={agentSelected} productId={rowData.id} />
                            </div>
                        )
                    }} />
            </div>
        </div>
    );
}
