import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import "./Style.css";

interface BackButtonProps {
  callback?: () => void | null
}

export const BackButton: React.FC<BackButtonProps> = ({
                                                        callback = null
                                                      }) => {
  const history = useHistory();

  const back = () => {
    if (callback !== null) {
      callback();
      return;
    }
    history.goBack();
  }

  return (
    <div id="back-button">
      <FontAwesomeIcon icon={faArrowCircleLeft} onClick={() => back()} />
    </div>
  );
};
