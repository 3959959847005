import React, { MouseEvent, useEffect, useState } from "react";
import "./Style.css";
import { EnPharmacyStatus } from "../../../LanguageMappings";

interface EditTrainingEmployeeProps {
  id: string;
  product: any;
  lookup: boolean;
  active: boolean;
  loading: boolean;
  trained?: boolean;
  hidden?: boolean;
  onClickCallback: (productId: number) => void;
}

export const TrainingProductComponent: React.FC<EditTrainingEmployeeProps> = ({
  id,
  product,
  lookup,
  loading,
  active,
  onClickCallback,
  trained = false,
  hidden = false,
}) => {
  const [clicked, setClicked] = useState<Array<HTMLElement>>([]);

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(id);
    if (active) element?.classList?.add("badge-dark");
  }, [active]);

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(id);
    if (loading) {
      element?.classList?.add("loading");
    } else {
      element?.classList?.remove("loading");
    }
  }, [loading]);

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(id);
    if (lookup) {
      element?.classList?.add("lookup");
    } else {
      element?.classList?.remove("lookup");
    }
  }, [lookup]);

  const resetClickedProducts = (pharmacyStatus: string) => {
    if (pharmacyStatus !== EnPharmacyStatus.CLOSED) {
      return;
    }
    clicked.forEach((value: HTMLElement) =>
      value.classList.toggle("badge-dark")
    );
    setClicked([]);
  };

  const handleOnClick = (event: MouseEvent<HTMLSpanElement>) => {
    if (loading || lookup || active || hidden || trained) {
      event.preventDefault();
      return;
    }
    const element: HTMLElement | null = document.getElementById(id);
    element?.classList?.toggle("badge-dark");
    const index: number = clicked.findIndex((el) => el.id === element?.id) || 0;
    !!element && index === -1
      ? clicked.push(element)
      : clicked.splice(index, 1);
    setClicked(clicked);
    onClickCallback(product.id);
  };

  return (
    <span
      id={id}
      className={trained ? "badge badge-custom badge-light badge-dark mr-1 mb-1 trained" : "badge badge-custom badge-light mr-1 mb-1"}
      style={{
        display: hidden ? "none" : "block",
      }}
      onClick={handleOnClick}
    >
      {product.name}
    </span>
  );
};
