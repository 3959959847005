import React, { forwardRef, useEffect, useState } from "react";
import {
  fetchAllAppointments,
  fetchAllTrainingAppointments,
  fetchTrainablePharmacyIds,
} from "./Service";
import { useToasts } from "react-toast-notifications";
import { ToggleComponent } from "../../components/Toggle/ToggleComponent";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { KPI } from "../../components/KPI/KPI";
import { EnUserRole } from "../../helpers/PrivateRoute/PrivateRoute";
import { useQueryParams } from "../../hooks/useQueryParams";
import { IPharmacy } from "../../interfaces/IPharmacy";
import {
  fetchAllPharmacies,
  fetchPharmacyCycleDetails,
} from "../PharmaciesRegistry/Service";
import MaterialTable from "material-table";
import {
  CoachingTypeLabels,
  ContactTypeLabels,
  EnOutCome,
  EnTrainingType,
  OutcomeLabels,
  PositiveResultLabels,
  TrainingTypeLabels,
} from "./LanguageMappings";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { fetchAllAgents } from "../StockManagement/Service";
import { DateTime } from "luxon";
import "./Style.css";
import { PatchedPagination } from "../../components/PatchedPagination/PatchedPagination";

enum EnToggleState {
  APPOINTMENTS = "APPOINTMENTS",
  TRAININGS = "TRAININGS",
}

interface AppointmentsComponentProps {}

export const AppointmentsTrainingsComponent: React.FC<AppointmentsComponentProps> =
  ({}) => {
    const { user } = useAuth();
    const { addToast } = useToasts();
    const history = useHistory();
    const queryParams = useQueryParams();

    const [pharmacySpecialists, setPharmacySpecialists] = useState<Array<any>>(
      []
    );
    const [pharmacySpecialist, setPharmacySpecialist] = useState<any>();
    const [pharmacies, setPharmacies] = useState<Array<IPharmacy>>([]);
    const [pharmacyCycleDetails, setPharmacyCycleDetails] = useState<
      Array<any>
    >([]);
    const [clickedPharmacy, setClickedPharmacy] = useState<IPharmacy | null>();
    const [clickedPharmacyDetails, setClickedPharmacyDetails] = useState<
      Array<any>
    >([]);
    const [appointments, setAppointments] = useState<Array<any>>([]);
    const [trainings, setTrainings] = useState<Array<any>>([]);
    const [modalState, setModalState] = useState<string>("closed");

    const param: string | null = queryParams.get("toggleState");
    const [toggle, setToggle] = useState<string>(
      param || EnToggleState.APPOINTMENTS
    );
    const [loading, setLoading] = useState<boolean>(false);

    const [appointmentKPICardData, setAppointmentKPICardData] = useState<any>([
      {
        id: 1,
        title: "Ordini",
        length: 0,
      },
      {
        id: 2,
        title: "Riordini",
        length: 0,
      },
      {
        id: 3,
        title: "Visite",
        length: 0,
      },
      {
        id: 4,
        title: "Verifiche sell-out",
        length: 0,
      },
      {
        id: 5,
        title: "Allestimenti",
        length: 0,
      },
      {
        id: 6,
        title: "Mail Reminder",
        length: 0,
      },
      {
        id: 7,
        title: "Negativo",
        length: 0,
      },
      {
        id: 8,
        title: "Attività di Coaching",
        length: 0,
      },
    ]);
    const [trainingKPICardData, setTrainingKPICardData] = useState([
      {
        id: 1,
        title: "Training in corso",
        length: 0,
      },
      {
        id: 2,
        title: "Training da completare",
        length: 0,
      },
      {
        id: 3,
        title: "Tempo medio training",
        length: 0,
      },
      {
        id: 4,
        title: "Training completati",
        length: 0,
      },
    ]);

    useEffect(() => {
      load(toggle).catch((error) => console.error(error));
    }, []);

    const load = async (state: string, agentId?: number) => {
      setLoading(true);

      const pharmacySpecialists = (await fetchAllAgents()) as Array<any>;
      pharmacySpecialists.forEach((pharmacySpecialist) => {
        pharmacySpecialist.label = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
        pharmacySpecialist.value = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
      });
      setPharmacySpecialists(pharmacySpecialists);

      let results: Array<any> = [];
      if (state === EnToggleState.APPOINTMENTS) {
        results = (await fetchAllAppointments()) as Array<any>;
        if (agentId) {
          results = results.filter((value) => value.agentId === agentId);
        }
        setAppointments(results);
        handleAppointmentKPICardData(results);
      }

      if (state === EnToggleState.TRAININGS) {
        results = (await fetchAllTrainingAppointments()) as Array<any>;
        if (agentId) {
          results = results.filter((value) => value.agentId === agentId);
        }
        setTrainings(results);
        handleTrainingKPICardData(results);

        let trainablePharmacyIds: Array<number> =
          await fetchTrainablePharmacyIds();
        let pharmacies: Array<IPharmacy> =
          (await fetchAllPharmacies()) as Array<IPharmacy>;
        if (agentId) {
          pharmacies = pharmacies.filter(
            (pharmacy) => pharmacy.agentId === agentId
          );
        }
        setPharmacies(pharmacies);

        let pharmacyCycleDetails: Array<any> =
          (await fetchPharmacyCycleDetails()) as Array<any>;
        setPharmacyCycleDetails(pharmacyCycleDetails);
      }

      setLoading(false);
    };

    const filterByAgent = async (pharmacySpecialist: any) => {
      setPharmacySpecialist(pharmacySpecialist);
      !!pharmacySpecialist
        ? await load(toggle, pharmacySpecialist.id)
        : await load(toggle);
    };

    const handleAppointmentKPICardData = (results: Array<any> = []) => {
      // Also check the outcome occurences for Mail Reminder and Negativo
      const outComeArray = results.map((o) => o.contactOutcome);
      const outComeOccurrences: any = {};
      outComeArray.forEach(
        (x: any) => (outComeOccurrences[x] = (outComeOccurrences[x] || 0) + 1)
      );

      // Retrieves only positive appointments
      const filtered: Array<any> = results.filter(
        (value) =>
          Array.isArray(value.positiveResults) &&
          value.positiveResults.length !== 0
      );
      // Make a combined array for all the positive results
      const combinedArray: any = filtered.map((o) => o.positiveResults).flat();

      const occurrences: any = {};
      // Count the positive results occurrences
      combinedArray.forEach(
        (x: any) => (occurrences[x] = (occurrences[x] || 0) + 1)
      );

      // Updates the cards length value
      let tempCardData = [...appointmentKPICardData];
      tempCardData[0].length = occurrences["ORDER"] || 0;
      tempCardData[1].length = occurrences["RE_ORDER"] || 0;
      tempCardData[2].length = occurrences["VISIT"] || 0;
      tempCardData[3].length = occurrences["SELL_OUT_CHECK"] || 0;
      tempCardData[4].length = occurrences["ALLESTIMENTO"] || 0;
      tempCardData[5].length =
        (occurrences["MAIL_REMINDER"] || 0) +
        (outComeOccurrences["MAIL_REMINDER"] || 0);
      tempCardData[6].length =
        (occurrences["NEGATIVO"] || 0) + (outComeOccurrences["NEGATIVO"] || 0);
      tempCardData[7].length = occurrences["COACHING"] || 0;

      setAppointmentKPICardData(tempCardData);
    };

    const handleTrainingKPICardData = (results: Array<any> = []) => {
      // TODO
    };

    const handleToggleState = (value: boolean) => {
      const state: EnToggleState = !value
        ? EnToggleState.APPOINTMENTS
        : EnToggleState.TRAININGS;
      setToggle(state);
      setPharmacySpecialist(null);
      load(state).catch((error) => console.error(error));
    };

    const handleToggleModalState = () => {
      const state: string = modalState === "closed" ? "opened" : "closed";
      if (state === "closed") {
        setClickedPharmacy(null);
        setClickedPharmacyDetails([]);
      }
      setModalState(state);
    };

    const handleOnClickPharmacyCard = (pharmacy: IPharmacy) => {
      setClickedPharmacy(pharmacy);
      if (hasDetails(pharmacy, pharmacyCycleDetails)) {
        const details: Array<any> = getDetails(
          pharmacy,
          pharmacyCycleDetails,
          false
        );
        setClickedPharmacyDetails(details);
      }
      handleToggleModalState();
    };

    const navigateTo = (path?: string) => {
      if (path) {
        history.push(path);
        return;
      }
      toggle === EnToggleState.APPOINTMENTS
        ? history.push("/appointments-and-training/appointments")
        : history.push("/appointments-and-training/trainings");
    };

    const isToggleChecked = (): boolean => {
      return toggle !== EnToggleState.APPOINTMENTS;
    };

    const hasDetails = (
      pharmacy: IPharmacy,
      pharmacyCycleDetails: Array<any> = []
    ): boolean => {
      return (
        pharmacyCycleDetails.filter(
          (detail: any) => detail.cycle.pharmacy.id === pharmacy.id
        ).length !== 0
      );
    };

    const getDetails = (
      pharmacy: IPharmacy,
      pharmacyCycleDetails: Array<any> = [],
      first: boolean = true
    ): Array<any> => {
      // Dev: I don't understand why?
      // const filtered: Array<any> = first ?
      //     pharmacyCycleDetails.filter((detail: any, index: number) => ((detail.cycle.pharmacy.id === pharmacy.id) && (index === 0))) :
      //     pharmacyCycleDetails.filter((detail: any) => ((detail.cycle.pharmacy.id === pharmacy.id)));

      const filtered: Array<any> = pharmacyCycleDetails.filter(
        (detail: any) => detail.cycle.pharmacy.id === pharmacy.id
      );
      filtered.forEach(
        (detail) =>
          (detail.percentage = calculatePercentageOfCompletion(detail))
      );
      return filtered;
    };

    const getTotalTrainedProductsCount = (cycle: any): number => {
      const pharmacyCycleDetails: Array<any> = cycle.pharmacyCycleDetails;
      const counted: any = _.countBy(
        pharmacyCycleDetails,
        (detail) => detail.product.name
      );
      let sum: number = 0;
      Object.keys(counted).forEach((key) => (sum += counted[key]));
      return sum;
    };

    const parseTrainingType = (value: EnTrainingType): string => {
      switch (value) {
        case EnTrainingType.IN_PERSON:
          return TrainingTypeLabels.IN_PERSON;
        case EnTrainingType.REMOTE:
          return TrainingTypeLabels.REMOTE;
      }
      return "";
    };

    const parseTrainingTotalDurationFromMillis = (
      seconds: number = 0
    ): string => {
      if (seconds === 0) {
        return "-";
      }
      const date: DateTime = DateTime.fromSeconds(seconds);
      return `${date.hour}h ${date.minute}m`;
    };

    const parseTrainingPharmacyStatus = (value: string): string => {
      switch (value) {
        case "OPEN":
          return "Aperta";
        case "CLOSE":
          return "Chiusa";
      }
      return "";
    };

    const parseTrainingSessionType = (value: string): string => {
      switch (value) {
        case "A PERSON":
          return "1 Persona";
        case "TWO PEOPLE":
          return "2 Persone";
        case "THREE PEOPLE":
          return "3 Persone";
        case "GROUP":
          return "Di Gruppo";
        case "FULL STAFF":
          return "Staff Completo";
      }
      return "";
    };

    const parseAppointmentPositiveResult = (value: string) => {
      switch (value) {
        case "ORDER":
          return "ORDINE";
        case "RE_ORDER":
          return "RIORDINE";
        case "VISIT":
          return "VISITA";
        case "SELL_OUT_CHECK":
          return "VERIFICA SELL-OUT";
        case "ALLESTIMENTO":
          return "ALLESTIMENTO";
        case "MAIL_REMINDER":
          return "MAIL REMINDER";
        case "NEGATIVO":
          return "NEGATIVO";
        case "COACHING":
          return "ATTIVITÀ DI COACHING";
      }
    };

    const calculatePercentageOfCompletion = (detail: any): number => {
      const cycle: any = detail.cycle;
      const products: Array<any> = detail.products || [];
      const employees: Array<any> = detail.employees || [];
      const totalCount: number = products.length * employees.length;
      const totalTrainedProductsCount: number =
        getTotalTrainedProductsCount(cycle);
      const percentage: number = Math.round(
        (totalTrainedProductsCount * 100) / totalCount
      );
      return percentage || 0;
    };

    const modal = (
      id: string,
      title: string,
      subtitle: string | null,
      body: JSX.Element
    ) => {
      return (
        <div
          className="modal fade"
          id={id}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div
              className="modal-content"
              style={{ minHeight: window.innerHeight - window.innerHeight / 3 }}
            >
              <div className="modal-header">
                <div className="d-flex flex-column justify-content-start align-content-start">
                  <h5 className="modal-title">{title}</h5>
                  {subtitle && <p className="text-muted">{subtitle}</p>}
                </div>
                <button
                  type="button"
                  id="modal-details-close-button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleToggleModalState}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: 0 }}>
                {body}
              </div>
              {/*<div className="modal-footer">*/}
              {/*    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>*/}
              {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      );
    };

    const table = (
      title: string,
      columns: Array<any> = [],
      data: Array<any> = []
    ) => {
      return (
        <MaterialTable
          components={{
            // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
            Pagination: PatchedPagination,
          }}
          title={title}
          localization={{
            body: {
              emptyDataSourceMessage: "Nessun dato disponibile",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
          columns={columns}
          data={data}
          isLoading={loading}
          options={{
            search: false,
            filtering: true,
            minBodyHeight: window.innerHeight - window.innerHeight / 3,
            maxBodyHeight: window.innerHeight - window.innerHeight / 3,
            emptyRowsWhenPaging: false,
            showTitle: title.length > 0,
            toolbar: title.length > 0, // otherwise if the title is present will be not visible
            pageSizeOptions: [5],
            draggable: false,
            rowStyle: (rowData: any) => ({
              color:
                rowData.cycle && rowData.cycle.status === "COMPLETED"
                  ? "black"
                  : "orange",
            }),
          }}
          detailPanel={(rowData) => {
            const pharmacyCycle: any = rowData.cycle;
            if (!pharmacyCycle || !pharmacyCycle.pharmacyCycleDetails) {
              return <div>Nessun dato presente</div>;
            }
            // Return an object with training ID like keys and an array of related details.
            const trainingAppointmentGroup: any = _.groupBy(
              pharmacyCycle.pharmacyCycleDetails,
              (pharmacyCycleDetail) =>
                pharmacyCycleDetail.trainingAppointment.id
            );
            const elaborated: any[] = [];
            for (const key of Object.keys(trainingAppointmentGroup)) {
              let trainingAppointment: any = null;
              let employees: any[] = [];
              trainingAppointmentGroup[key].forEach(
                (value: any, index: number) => {
                  if (!trainingAppointment)
                    trainingAppointment = value.trainingAppointment;
                  const employee: any = employees.find(
                    (e) => e.id === value.pharmacyEmployee.id
                  );
                  if (!employee) {
                    value.pharmacyEmployee.products = [value.product];
                    value.pharmacyEmployee.duration = value.duration;
                    employees.push(value.pharmacyEmployee);
                  } else {
                    employee.products.push(value.product);
                  }
                }
              );
              employees.forEach((employee: any, index: number) => {
                if (
                  Array.isArray(employee.products) &&
                  employee.products.length > 0
                ) {
                  employee.products.sort(function (a: any, b: any) {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                }
              });
              elaborated.push({ trainingAppointment, employees });
            }
            return (
              <div
                className="d-flex flex-column justify-content-start align-items-start py-2 px-4"
                style={{
                  backgroundColor: "#f4f4f4",
                }}
              >
                {elaborated.map((object: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="card m-2 animate__animated animate__fadeIn"
                      style={{
                        width: "100%",
                        height: "auto",
                        border: "5px solid #d8d8d8",
                      }}
                    >
                      <div className="card-body">
                        <div className="row justify-content-between align-items-start">
                          <div className="col-12 col-md-4">
                            <div className="row justify-content-start align-items-start">
                              <div className="col-auto">
                                <p className="card-text">{++index}</p>
                              </div>
                              <div className="col">
                                <h6 className="card-title mb-2">TRAINING</h6>
                                <p className="card-text mb-0">
                                  <span className="mr-1">Data contatto</span>
                                  <b>
                                    {DateTime.fromISO(
                                      object?.trainingAppointment?.contactDate
                                    ).toFormat("dd/MM/yyyy")}
                                  </b>
                                </p>
                                <p className="card-text">
                                  <span className="mr-1">
                                    Data appuntamento
                                  </span>
                                  <b>
                                    {new Date(
                                      object?.trainingAppointment?.trainingDate
                                    )?.toLocaleDateString("it-IT", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </b>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-2">
                            <div className="row mb-1">
                              <div className="col-12">
                                <label className="mb-0">Tipo di Training</label>
                                <br />
                                <span>
                                  {parseTrainingType(
                                    object?.trainingAppointment?.trainingType
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-12">
                                <label className="mb-0">Durata totale</label>
                                <br />
                                <span>
                                  {parseTrainingTotalDurationFromMillis(
                                    object?.trainingAppointment?.totalDuration
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-12">
                                <label className="mb-0">Stato Farmacia</label>
                                <br />
                                <span>
                                  {parseTrainingPharmacyStatus(
                                    object?.trainingAppointment?.pharmacyStatus
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-12">
                                <label className="mb-0">Tipo di sessione</label>
                                <br />
                                <span>
                                  {parseTrainingSessionType(
                                    object?.trainingAppointment
                                      ?.trainingSessionType
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            {object?.employees?.map(
                              (employee: any, index: number) => {
                                return (
                                  <div className="row mb-1" key={index}>
                                    <div className="col-12">
                                      <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                                        <label className="mb-0">
                                          {employee.firstName}{" "}
                                          {employee.lastName}
                                        </label>
                                        {employee?.duration !== 0 && (
                                          <span className="mb-0">
                                            {employee?.duration}m
                                          </span>
                                        )}
                                      </div>
                                      {employee.products.map(
                                        (product: any, index: number) => {
                                          return (
                                            <span
                                              key={index}
                                              className="badge badge-dark mr-1"
                                            >
                                              {product.name}
                                            </span>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      );
    };

    const elaborateModalBody = (data: Array<any> = []): JSX.Element => {
      const columns: Array<any> = [
        {
          title: "Ciclo",
          field: "cycle.cycle",
          filtering: true,
        },
        {
          title: "Inizio",
          field: "cycle.startDate",
          type: "date",
          filtering: false,
          dateSetting: {
            format: "dd/MM/yyyy HH:mm",
          },
        },
        {
          title: "Fine",
          field: "cycle.endDate",
          type: "date",
          filtering: false,
          dateSetting: {
            format: "dd/MM/yyyy HH:mm",
          },
        },
      ];
      return table("", columns, data);
    };

    const parseDate = (date: any) => {
      return new Date(date)?.toLocaleDateString("it-IT", {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="common-sticky-bar d-flex flex-row justify-content-end align-items-center mb-4">
            <ToggleComponent
              checked={isToggleChecked()}
              labelLeft={"APPUNTAMENTI"}
              labelRight={"TRAINING"}
              callback={handleToggleState}
            />
            {user.role === EnUserRole.AGENT && (
              <div className="ml-4">
                <button
                  type="button"
                  className="btn common-btn add-new-pharmacy"
                  onClick={() => navigateTo()}
                >
                  {toggle === EnToggleState.APPOINTMENTS ? "Nuovo" : "Gestisci"}
                </button>
              </div>
            )}
          </div>

          {user.role !== EnUserRole.AGENT && (
            <div className="d-flex flex-row flex-wrap justify-content-start align-items-center my-4">
              <form className="ml-3" style={{ width: "100%" }}>
                <div className="row">
                  <div className="col-12 col-lg-6 mb-2">
                    <label
                      className="common-form-label"
                      htmlFor="pharmacy-specialist"
                    >
                      Pharmacy Specialist
                    </label>
                    <Select
                      isClearable={true}
                      className="basic-single"
                      classNamePrefix="custom-dropdown"
                      placeholder=""
                      name="pharmacy-specialist"
                      options={pharmacySpecialists}
                      value={pharmacySpecialist}
                      onChange={(pharmacySpecialist) =>
                        filterByAgent(pharmacySpecialist)
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          )}

          {toggle === EnToggleState.APPOINTMENTS && (
            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center my-5">
              {appointmentKPICardData.map((card: any, index: number) => (
                <KPI
                  key={index}
                  title={card.title}
                  value={card.length}
                  active={false}
                  trafficLightColor="black"
                  height="10rem"
                  width="13rem"
                  fontSize="1.3rem"
                  loading={loading}
                />
              ))}
            </div>
          )}

          {toggle === EnToggleState.APPOINTMENTS ? (
            <MaterialTable
              components={{
                // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                Pagination: PatchedPagination,
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "Nessun dato disponibile",
                  filterRow: {
                    filterTooltip: "Filtro",
                  },
                },
                pagination: {
                  labelRowsSelect: "righe",
                },
                header: {
                  actions: "",
                },
              }}
              columns={[
                {
                  title: "Nome Farmacia",
                  field: "pharmacyName",
                  type: "string",
                },
                {
                  title: "Data Appuntamento",
                  field: "appointmentDate",
                  type: "string",
                  editable: "never",
                  render: (rowData: any) => {
                    return !rowData.appointmentDate
                      ? "N/A"
                      : new Date(rowData?.appointmentDate)?.toLocaleDateString(
                          "it-IT",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        );
                  },
                },
                {
                  title: "Esito Contatto",
                  field: "contactOutcome",
                  type: "string",
                  lookup: {
                    POSITIVE: "Positivo",
                    NEGATIVE: "Negativo",
                    MAIL_REMINDER: "Mail Reminder",
                  },
                  render: (rowData: any) => {
                    if (!!rowData.contactOutcome)
                      return (
                        <span>{OutcomeLabels[rowData.contactOutcome]}</span>
                      );
                    return "";
                  },
                },
                {
                  title: "Esito Appuntamento",
                  field: "positiveResults",
                  type: "string",
                  lookup: {
                    ORDER: "ORDINE",
                    RE_ORDER: "RIORDINE",
                    VISIT: "VISITA",
                    SELL_OUT_CHECK: "VERIFICA SELL OUT",
                    ALLESTIMENTO: "ALLESTIMENTO",
                    MAIL_REMINDER: "MAIL REMINDER",
                    NEGATIVO: "NEGATIVO",
                    COACHING: "ATTIVITÀ DI COACHING",
                  },
                  render: (rowData: any) => {
                    return (
                      <span>
                        {!!rowData.positiveResults &&
                          rowData.positiveResults.map(
                            (item: string, index: number) => {
                              if (!!item) {
                                return (
                                  <span
                                    key={index}
                                    className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1"
                                  >
                                    {PositiveResultLabels[item]}
                                  </span>
                                );
                              }
                              return "";
                            }
                          )}
                      </span>
                    );
                  },
                  customFilterAndSearch: (value, rowData: any) => {
                    const positiveResults: Array<string> = rowData.positiveResults || [];
                    console.log('Positive Results', positiveResults);
                    return value.length === 0 ||
                      positiveResults.some(
                        (result) => value.indexOf(result) >= 0
                      )
                      ? rowData
                      : null;
                  },
                },
                // {
                //     title: 'Stato Appuntamento',
                //     field: 'status',
                //     type: 'string',
                //     lookup: {
                //         PENDING: 'NON COMPLETATO',
                //         COMPLETED: 'COMPLETATO'
                //     },
                //     render: (rowData: any) => {
                //         return rowData.status === 'COMPLETED' ?
                //             <span className="badge badge-pill badge-success mat-appoint-badge-table px-2 py-1 m-1">COMPLETATO</span>
                //             :
                //             <span className="badge badge-pill badge-warning mat-appoint-badge-table px-2 py-1 m-1">NON COMPLETATO</span>;
                //     },
                // }
              ]}
              isLoading={loading}
              data={_.orderBy(appointments, ["contactDate"], ["desc"])}
              options={{
                showTitle: false,
                search: false,
                filtering: true,
                pageSize: 10,
                toolbar: false,
                actionsColumnIndex: -1,
                maxBodyHeight: "75vh",
                pageSizeOptions: [10, 25, 50],
                emptyRowsWhenPaging: false,
                draggable: false,
                rowStyle: (rowData: any) => ({
                  color: rowData.status === "COMPLETED" ? "black" : "orange",
                  backgroundColor:
                    rowData.pharmacyStatus === "ELIMINATA" ||
                    rowData.pharmacyStatus === "CATENE_E_GRUPPI"
                      ? "#aaaaaa45"
                      : "",
                }),
              }}
              icons={{
                Add: forwardRef((props, ref) => (
                  <AddBox {...props} ref={ref} color="action" />
                )),
                Edit: forwardRef((props, ref) => (
                  <Edit {...props} ref={ref} color="action" />
                )),
                Delete: forwardRef((props, ref) => (
                  <Delete {...props} ref={ref} color="action" />
                )),
              }}
              actions={[
                (rowData: any) => {
                  return {
                    icon: () => (
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ fontSize: "1rem" }}
                          className="text-muted"
                        />
                      </div>
                    ),
                    tooltip: "Dettaglio",
                    onClick: (event, rowData: any) =>
                      history.push(
                        `/appointments-and-training/appointments/${rowData.id}/detail`
                      ),
                  };
                },
                (rowData: any) => {
                  return {
                    icon: () => (
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ fontSize: "1rem" }}
                          className="text-muted"
                        />
                      </div>
                    ),
                    hidden:
                      user.role !== EnUserRole.AGENT ||
                      rowData?.status === "COMPLETED",
                    tooltip: "Modifica",
                    onClick: (event, rowData: any) =>
                      history.push(
                        `/appointments-and-training/appointments/${rowData.id}/edit`
                      ),
                  };
                },
              ]}
              detailPanel={(rowData: any) => {
                return (
                  <div
                    className="row justify-content-start align-items-start px-4 py-4"
                    style={{ backgroundColor: "#f4f4f4" }}
                  >
                    <div className="col-12 col-lg-6 mb-2">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <b>Dettaglio</b>
                        </li>
                        {rowData?.agentName && (
                          <Li
                            label="Pharmacy Specialist"
                            values={rowData.agentName}
                          />
                        )}
                        {rowData?.pharmacyName && (
                          <Li
                            label="Nome Farmacia"
                            values={rowData.pharmacyName}
                          />
                        )}
                        {rowData?.contactType && (
                          <Li
                            label="Tipo Contatto"
                            values={ContactTypeLabels[rowData.contactType]}
                          />
                        )}
                        {rowData?.contactDate && (
                          <Li
                            label="Data Contatto"
                            values={parseDate(rowData?.contactDate)}
                          />
                        )}
                        {rowData?.appointmentDate && (
                          <Li
                            label="Data Appuntamento"
                            values={parseDate(rowData?.appointmentDate)}
                          />
                        )}
                        {rowData?.contactOutcome && (
                          <Li
                            label="Esito Contatto"
                            values={rowData.contactOutcome}
                            type={OutcomeLabels}
                            badge={true}
                            badgeColor={
                              rowData.contactOutcome === EnOutCome.NEGATIVE
                                ? "danger"
                                : "success"
                            }
                          />
                        )}
                        {(rowData?.contactOutcome === "POSITIVE" &&
                          rowData?.positiveResults?.length !== 0) && (
                            <Li
                              label="Esito Appuntamento"
                              values={rowData.positiveResults}
                              type={PositiveResultLabels}
                              badge={true}
                            />
                          )}
                        {(rowData?.positiveResults?.length !== 0 &&
                          rowData?.positiveResults?.includes("COACHING") &&
                          rowData?.coachingType !== null) && (
                            <Li
                              label="Giornata"
                              values={rowData.coachingType}
                              type={CoachingTypeLabels}
                              badge={true}
                            />
                          )}
                        {rowData?.pharmacyStatus !== "NORMAL" && (
                          <Li
                            label="Stato Farmacia"
                            values={rowData.pharmacyStatus}
                            type={{
                              ELIMINATA: "Eliminata",
                              CATENE_E_GRUPPI: "Catene e gruppi",
                              RICONTATTARE: "Ricontattare",
                            }}
                            badge={true}
                            badgeColor={
                              rowData.pharmacyStatus !== "RICONTATTARE"
                                ? "danger"
                                : "info"
                            }
                          />
                        )}
                        {rowData?.pharmacyStatus === "ELIMINATA" && (
                          <Li
                            label="Motivazione"
                            values={rowData.negativeResult.reason}
                            badge={true}
                          />
                        )}
                      </ul>
                    </div>
                    {rowData?.positiveResults?.includes("ORDER") && (
                      <div className="col-12 col-lg-6 mb-2">
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                            <b>Ordine</b>
                          </li>
                          {rowData?.orders
                            ?.reverse()
                            .map((order: any, index: number) => (
                              <Li
                                key={index}
                                label={order.product.name}
                                values={order.quantity}
                                badge={true}
                              />
                            ))}
                        </ul>
                      </div>
                    )}
                    {rowData?.positiveResults?.includes("RE_ORDER") && (
                      <div className="col-12 col-lg-6 mb-2">
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                            <b>Riordine</b>
                          </li>
                          {rowData?.reorders
                            ?.reverse()
                            .map((reorder: any, index: number) => (
                              <Li
                                key={index}
                                label={reorder.product.name}
                                values={reorder.quantity}
                                badge={true}
                              />
                            ))}
                        </ul>
                      </div>
                    )}
                    {rowData?.sampling === 1 && (
                      <div className="col-12 col-lg-6 mb-2">
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                            <b>Compionionatura</b>
                          </li>
                          {rowData?.samples?.map(
                            (sample: any, index: number) => (
                              <Li
                                key={index}
                                label={sample.product.name}
                                values={sample.quantity}
                                badge={true}
                              />
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              }}
            />
          ) : (
            <div
              id="history-trainings-component"
              className="d-flex flex-column"
            >
              {!loading ? (
                <>
                  {pharmacies.length !== 0 ? (
                    <div className="d-flex flex-row flex-wrap justify-content-start align-items-center">
                      {pharmacies.map((pharmacy: IPharmacy, index: number) => {
                        return (
                          <div
                            key={index}
                            className="card custom-card m-3 animate__animated animate__fadeIn"
                            data-toggle="modal"
                            data-target="#modal-details"
                            onClick={() => handleOnClickPharmacyCard(pharmacy)}
                            style={{
                              width: "20rem",
                              height: "10rem",
                              border: "5px solid #D8D8D8",
                              minHeight: "200px",
                            }}
                          >
                            <div className="card-body">
                              <h5 className="card-title mb-1">
                                {pharmacy.name}
                              </h5>
                              {hasDetails(pharmacy, pharmacyCycleDetails) ? (
                                getDetails(pharmacy, pharmacyCycleDetails)
                                  .slice(0, 1)
                                  .map((detail: any, index: number) => {
                                    return (
                                      <div key={index}>
                                        <p className="card-text">
                                          <span className="text-muted mr-1">
                                            Ciclo corrente:
                                          </span>
                                          <span>{detail.cycle.cycle}</span>
                                          <br />
                                          <span className="text-muted mr-1">
                                            Completamento:
                                          </span>
                                          <span>{detail.percentage || 0}%</span>
                                        </p>
                                        <div className="progress">
                                          <div
                                            className="progress-bar bg-dark"
                                            role="progressbar"
                                            style={{
                                              width: `${
                                                detail.percentage || 0
                                              }%`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                              ) : (
                                <>
                                  <p className="card-text">
                                    <span className="text-muted mr-1">
                                      Ciclo corrente:
                                    </span>
                                    <span>-</span>
                                    <br />
                                    <span className="text-muted mr-1">
                                      Completamento:
                                    </span>
                                    <span>0%</span>
                                  </p>
                                  <div className="progress">
                                    <div
                                      className="progress-bar bg-dark"
                                      role="progressbar"
                                      style={{ width: `0%` }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="d-flex flex-row justify-content-center align-items-center p-4">
                      <label className="text-muted">
                        Nessun dato disponibile
                      </label>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex flex-row justify-content-center align-items-center p-4">
                  <div className="spinner-wrapper mr-2">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only text-light">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {modal(
          "modal-details",
          `${clickedPharmacy?.name}`,
          "Dettaglio",
          elaborateModalBody(clickedPharmacyDetails)
        )}
      </div>
    );
  };

const Li: React.FC<{
  label: string | null;
  values: string | Array<string | null> | null;
  badge?: boolean;
  badgeColor?: string;
  type?: any;
}> = ({
  label = "Label",
  values = [],
  badge = false,
  badgeColor = "info",
  type = null,
}) => {
  const [isArray, setIsArray] = useState<boolean>(false);

  useEffect(() => {
    if (values) setIsArray(Array.isArray(values));
  }, [values]);

  const items = (values: Array<string | null>): JSX.Element[] => {
    return values.map((value: string | null, index: number) =>
      badge ? (
        <span
          key={index}
          className={`badge badge-pill badge-${badgeColor} mat-appoint-badge-table px-2 py-1 m-1`}
        >
          {parseValueType(value)}
        </span>
      ) : (
        <span key={index} className="text-dense">
          {parseValueType(value)}
        </span>
      )
    );
  };

  const item = (values: string | null): JSX.Element => {
    return (
      <>
        {badge ? (
          <span
            className={`badge badge-pill badge-${badgeColor} mat-appoint-badge-table px-2 py-1 m-1`}
          >
            {parseValueType(values)}
          </span>
        ) : (
          <span className="text-dense">{parseValueType(values)}</span>
        )}
      </>
    );
  };

  const parseValueType = (value: string | null) => {
    return type !== null && value !== null ? type[value] : value;
  };

  return (
    <li className="list-group-item">
      <div className="row justify-content-between align-items-center">
        <div className="col-12 col-sm-6 text-left">
          <span className="text-muted">{label}</span>
        </div>
        <div className="col-12 col-sm-6 text-right">
          {isArray && Array.isArray(values)
            ? items(values as Array<string | null>)
            : item(values as string | null)}
        </div>
      </div>
    </li>
  );
};
