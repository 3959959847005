import React, {useEffect, useState} from 'react';
import {fetchGeneralOrdersPerYear, fetchPharmacies, fetchUpdatePharmacyStock} from './Service';
import Select, {OptionsType} from 'react-select';
import useAuth from '../../../hooks/useAuth';
import {useToasts} from 'react-toast-notifications'
import MaterialTable, {Column} from 'material-table';
import './style.scss';
import {StockPharmacyAudit} from './StockPharmacyAudit';
import {DateTime} from 'luxon';
import { PatchedPagination } from "../../../components/PatchedPagination/PatchedPagination";

interface StockManagementProps {

}

export const StockPharmacy: React.FC<StockManagementProps> = ({}) => {

    const {user} = useAuth();

    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [pharmacies, setPharmacies] = useState<Array<any>>([]);
    const [pharmacy, setPharmacy] = useState<any>(null);
    const [optionsType, setOptionsType] = useState<Array<OptionsType<any>>>([]);
    const [stocks, setStocks] = useState<Array<any>>([]);
    const [year, setYear] = useState<number>(DateTime.local().year);
    const [month, setMonth] = useState<number>(DateTime.local().month);
    const {addToast} = useToasts();

    const [data, setData] = useState<any>({year, month: 1})

    useEffect(() => {
        setData({...data, year, month})
        load().catch(error => console.error(error));
    }, []);

    useEffect(() => {
        loadPharmacyProducts().catch(error => console.error(error));
    }, [pharmacy, year]);

    const load = async () => {
        try {
            setLoading(true);
            // Load list of pharmacies
            const pharmacies: Array<any> = await fetchPharmacies() as Array<any>;
            setPharmacies(pharmacies);
            // Set the select options
            const options: Array<any> = new Array<any>();
            pharmacies.forEach(pharmacy => options.push({label: pharmacy.name, value: pharmacy.id}))
            setOptionsType(options);
            setLoading(false);
        } catch (error) {
            console.error('StockPharmacy', error);
            addToast('Errore nel caricamento dei dati.', {appearance: 'error', autoDismiss: true});
            setLoading(false);
        }
    }

    const loadPharmacyProducts = async () => {
        try {
            setLoading(true);
            if (!pharmacy) {
                return;
            }
            const previous: Array<any> = await fetchGeneralOrdersPerYear(pharmacy.id, (year - 1)) as Array<any>;
            const currents: Array<any> = await fetchGeneralOrdersPerYear(pharmacy.id, year) as Array<any>;
            setStocks(currents);
            setLoading(false);
        } catch (error) {
            console.error('StockPharmacy Products', error);
            addToast('Errore nel caricamento dei prodotti.', {appearance: 'error', autoDismiss: true});
            setLoading(false);
        }
    }

    const onClickUpdateButton = async () => {
        try {
            setSubmit(true);
            const entries: Array<any> = new Array<any>();
            stocks.forEach(stock => entries.push({productId: stock.productId, quantity: 0}));
            await fetchUpdatePharmacyStock(pharmacy.id, entries);
            addToast('Salvato con successo!', {appearance: 'success', autoDismiss: true});
            await loadPharmacyProducts();
            setSubmit(false);
        } catch (error) {
            console.error('StockPharmacy Update', error);
            addToast('Errore nell\'aggiornamento dei dati.', {appearance: 'error', autoDismiss: true});
            setSubmit(false);
        }
    };

    const onChangePharmacySelect = (id: number) => {
        if (!id) {
            return;
        }
        const pharmacy: any = pharmacies.find(p => p.id === id);
        setPharmacy(pharmacy);
    }

    const onChangeYear = (type: string) => {
        if (!pharmacy || (year === (year - 1))) {
            return;
        }
        setYear(type === 'next' ? (year + 1) : (year - 1));
    }

    const getColumns = () => {
        const customColumns: Array<Column<any>> = [
            {
                title: 'Prodotto',
                field: 'productName',
                editable: 'never',
                type: 'string',
                filtering: false
            }
        ];

        ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'].forEach((item: string, index: number) => {
            customColumns.push({
                title: item,
                editable: 'never',
                type: 'string',
                filtering: false,
                sorting: false,
                // headerStyle: isCurrentMonth(index + 1) ? { opacity: '1' } : { opacity: '.6' },
                render: (rowData: any) => {
                    const filtered = rowData.months.filter((item: IStock) => Number(item.month) === (index + 1))
                    return filtered.map((stock: IStock, index: number) => {
                        return (
                            <div key={index} className="d-flex justify-content-center align-items-center">
                                {
                                    (year === data.year) ?
                                        handleCurrentYear(stock.month, stock.currentStock, null, null, null, null, stock.isNotAvailable) :
                                        handlePreviousYear(stock.month, stock.currentStock, null, null, null, null, stock.isNotAvailable)
                                }
                            </div>
                        );
                    });
                }

            });
        });
        return customColumns;
    };

    const handleColor = (value: number): string => {
        if (value < 0) {
            return 'red';
        }
        if (value >= 0 && value <= 2) {
            return 'orange';
        }
        return 'black';
    }

    const handleNotAvailable = (): JSX.Element => {
        return (<small style={{opacity: '.4'}}>N/A</small>);
    }

    const handleCurrentYear = (month: number, currentStock?: number | null, previousStock?: number | null, orderQuantity?: number | null, reorderQuantity?: number | null, selloutQuantity?: number | null, isNotAvailable?: boolean): JSX.Element => {
        if (month > (data.month) || isNotAvailable) {
            return handleNotAvailable();
        }
        return handleValue(month, currentStock, previousStock, orderQuantity, reorderQuantity, selloutQuantity);
    }

    const handlePreviousYear = (month: number, currentStock?: number | null, previousStock?: number | null, orderQuantity?: number | null, reorderQuantity?: number | null, selloutQuantity?: number | null, isNotAvailable?: boolean): JSX.Element => {
        if (isNotAvailable) {
            return handleNotAvailable();
        }
        return handleValue(month, currentStock, previousStock, orderQuantity, reorderQuantity, selloutQuantity);
    }

    const handleValue = (month: number | null, currentStock: number | null = null, previousStock: number | null = null, orderQuantity: number | null = null, reorderQuantity: number | null = null, selloutQuantity: number | null = null): JSX.Element => {
        if (previousStock !== null) {
            return <span>{previousStock}</span>;
        }
        if (orderQuantity !== null) {
            return <span>{orderQuantity}</span>;
        }
        if (reorderQuantity !== null) {
            return <span>{reorderQuantity}</span>;
        }
        if (selloutQuantity !== null) {
            return <span>{selloutQuantity}</span>;
        }
        if (currentStock !== null) {
            return <span style={{color: handleColor(currentStock)}}><b>{currentStock}</b></span>
        }
        return handleNotAvailable();
    }

    const isCurrentMonth = (index: number): boolean => {
        return (year === data.year && index === data.month);
    }

    return (
        <div className="content">
            <div className="container-fluid container-material-table-height-fix">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    {
                        pharmacies.length > 0 &&
                        <form style={{width: '100%'}}>
                            <div className="row justify-content-between align-items-end">
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="common-form-label" htmlFor="agentsSelect">Farmacie</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="custom-dropdown"
                                        placeholder="Filtra per"
                                        name="Farmacie"
                                        options={pharmacies.map(pharmacy => ({
                                            label: pharmacy.name,
                                            value: pharmacy.id
                                        }))}
                                        onChange={(selected: any) => onChangePharmacySelect(selected.value)}
                                    />
                                </div>
                            </div>
                        </form>
                    }
                </div>
                <div className="d-flex flex-row justify-content-end align-items-center"
                     style={{width: '100%', opacity: !!pharmacy ? '1' : '.4'}}>
                    <label style={{color: 'black', fontSize: '1.25rem'}}>
                        {
                            (year === data.year) &&
                            <i className="fa fa-caret-left animate__animated animate__fadeIn"
                               style={{cursor: 'pointer'}}
                               onClick={() => onChangeYear('prev')} aria-hidden="true"/>
                        }
                        &nbsp;{year}&nbsp;
                        {
                            (year < data.year) &&
                            <i className="fa fa-caret-right animate__animated animate__fadeIn"
                               style={{cursor: 'pointer'}}
                               onClick={() => onChangeYear('next')}
                               aria-hidden="true"/>
                        }
                    </label>
                </div>
                <MaterialTable
                  components={{
                      // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                      Pagination: PatchedPagination
                  }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Nessun dato presente',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'righe'
                        },
                        header: {
                            actions: ''
                        }
                    }}
                    columns={getColumns()}
                    isLoading={loading}
                    data={stocks}
                    options={{
                        showTitle: false,
                        search: false,
                        filtering: false,
                        pageSize: 25,
                        toolbar: false,
                        maxBodyHeight: '75vh',
                        pageSizeOptions: [10, 25, 50],
                        emptyRowsWhenPaging: false,
                        draggable: false
                    }}
                    detailPanel={rowData => {
                        return (
                            <div
                                className="d-flex flex-row justify-content-center align-items-center py-2 px-4"
                                style={{
                                    backgroundColor: '#f4f4f4'
                                }}>
                                <StockPharmacyAudit
                                    rowData={rowData}
                                    year={year}
                                    data={data}
                                    pharmacyId={pharmacy.id}
                                    productId={rowData.productId}
                                    handleCurrentYear={handleCurrentYear}
                                    handlePreviousYear={handlePreviousYear}
                                    handleColor={handleColor}/>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}

interface IStock {
    currentStock: number;
    month: number;
    orderQuantity: number;
    previousStock: number;
    reorderQuantity: number;
    selloutQuantity: number;
    isNotAvailable: boolean;
    isVerified: boolean;
}
