import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {fetchPharmacyById, fetchUpdatePharmacy, fetchUpdatePharmacyByAgent} from '../Service';
import {fetchAllAgents} from '../../StockManagement/Service';
import {useToasts} from 'react-toast-notifications'
import useAuth from '../../../hooks/useAuth';
import {EnUserRole} from '../../../helpers/PrivateRoute/PrivateRoute';
import Select from 'react-select';
import {withStyles} from '@material-ui/core/styles';
import {green, grey} from '@material-ui/core/colors';
import {Switch} from '@material-ui/core';
import {IPharmacy} from '../../../interfaces/IPharmacy';
import {BackButton} from '../../../components/BackButton/BackButton';

interface ModifyPharmacyProps {
}

export const ModifyPharmacy: React.FC<ModifyPharmacyProps> = (props: any) => {
    const history = useHistory();
    const {addToast} = useToasts();
    const {user, saveUser} = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [pharmacy, setPharmacy] = useState<IPharmacy>({} as IPharmacy);
    const [agents, setAgents] = useState<Array<any>>([]);
    const [agentIndex, setAgentIndex] = useState<any>(0);
    const [active, setActive] = React.useState<boolean>(false);

    useEffect(() => {
        load().catch(error => console.error(error));
    }, []);

    const load = async () => {
        const pharmacyId = props.match.params.id;
        if (!pharmacyId) {
            return;
        }
        setLoading(true);
        const agents: Array<any> = await fetchAllAgents() as Array<any>;
        agents.forEach((agent: any) => {
            agent.label = `${agent.firstName} ${agent.lastName}`;
            agent.value = `${agent.firstName} ${agent.lastName}`;
        });
        setAgents(agents);
        const pharmacy: IPharmacy = await fetchPharmacyById(pharmacyId) as IPharmacy;
        setPharmacy(pharmacy);
        setActive(pharmacy.active === 1);
        if (pharmacy.agentId !== null) {
            const agentIndex: number = agents.findIndex((value: any) => value.id === pharmacy.agentId);
            if (agentIndex !== -1) {
                setAgentIndex(agentIndex);
            }
        }
        setLoading(false);
    }

    const GreenSwitch = withStyles({
        switchBase: {
            color: grey[300],
            '&$checked': {
                color: green[500],
            },
            '&$checked + $track': {
                backgroundColor: green[500],
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const handleAgent = (value: any) => {
        if (value === null) return;
        if (pharmacy) {
            pharmacy.agentId = value.id;
            setPharmacy(pharmacy);
        }
    }

    const handleChange = (e: any, type: string) => {
        if (type === 'name') setPharmacy({...pharmacy, name: e?.target?.value});
        else if (type === 'address') setPharmacy({...pharmacy, address: e?.target?.value});
        else if (type === 'city') setPharmacy({...pharmacy, city: e?.target?.value});
        else if (type === 'province') setPharmacy({...pharmacy, province: e?.target?.value});
        else if (type === 'region') setPharmacy({...pharmacy, region: e?.target?.value});
        else if (type === 'microbrick') setPharmacy({...pharmacy, microbrick: e?.target?.value});
        else if (type === 'vat') setPharmacy({...pharmacy, VAT: e?.target?.value});
        else if (type === 'organic') setPharmacy({...pharmacy, organic: e?.target?.value});
        else if (type === 'email') setPharmacy({...pharmacy, email: e?.target?.value});
        else if (type === 'phone') setPharmacy({...pharmacy, phone: e?.target?.value});
        else if (type === 'contact') setPharmacy({...pharmacy, contact: e?.target?.value});
        else if (type === 'website') setPharmacy({...pharmacy, website: e?.target?.value});
        else if (type === 'facebook') setPharmacy({...pharmacy, facebook: e?.target?.value});
        else if (type === 'instagram') setPharmacy({...pharmacy, instagram: e?.target?.value});
        else if (type === 'note') setPharmacy({...pharmacy, note: e?.target?.value});
        else if (type === 'active') setPharmacy({...pharmacy, active: ((e?.target?.checked) ? 1 : 0)});
    }

    const handleActive = (e: any) => {
        const value: boolean = e?.target?.checked;
        setActive(value);
        handleChange(e, 'active');
    };

    const handleUpdatePharmacy = () => {
        setSubmit(true);
        fetchUpdatePharmacy(pharmacy.id, pharmacy).then((response: any) => {
            if (response === 1) history.push('/pharmacy-registry');
            setSubmit(false);
        }).catch((error: any) => {
            if (error.type == 'FORM_VALIDATION') {
                error.response.forEach((err: any) => {
                    addToast(err, {appearance: 'error', autoDismiss: false});
                })
            } else {
                addToast(error.message, {appearance: 'error', autoDismiss: false});
            }
            setSubmit(false);
        });
    }

    const handleUpdatePharmacyByAgent = () => {
        const partial: any = {
            organic: (pharmacy.organic !== 0) ? pharmacy.organic : null,
            contact: (pharmacy.contact !== "") ? pharmacy.contact : null,
            email: (pharmacy.email !== "") ? pharmacy.email : null,
            phone: (pharmacy.phone !== "") ? pharmacy.phone : null,
            note: (pharmacy.note !== "") ? pharmacy.note : null,
            facebook: (pharmacy.facebook !== "") ? pharmacy.facebook : null,
            instagram: (pharmacy.instagram !== "") ? pharmacy.instagram : null,
            website: (pharmacy.website !== "") ? pharmacy.website : null
        };
        setSubmit(true);
        fetchUpdatePharmacyByAgent(user.sub, pharmacy.id, partial).then((response: any) => {
            if (response === 1) history.push('/pharmacy-registry');
            setSubmit(false);
        }).catch((error: any) => {
            if (error.type == 'FORM_VALIDATION') {
                error.response.forEach((err: any) => {
                    addToast(err, {appearance: 'error', autoDismiss: false});
                });
            } else {
                addToast(error.message, {appearance: 'error', autoDismiss: false});
            }
            setSubmit(false);
        });
    }

    const goBack = () => {
        history.goBack();
    }

    const navigateToEmployeesPage = (pharmacy: IPharmacy) => {
        history.push(`/pharmacy-registry/${pharmacy.id}/employees`)
    }

    return (
        <div className="content">
            <div className="container-fluid animate__animated animate__fadeIn">
                <div className="d-flex justify-content-between">
                    <BackButton />
                    <button type="button" className="btn common-btn"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            disabled={loading || submit}
                            onClick={user.role !== EnUserRole.AGENT ? handleUpdatePharmacy : handleUpdatePharmacyByAgent}>
                        {
                            submit &&
                            <div className="spinner-wrapper mr-2">
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only text-light">Loading...</span>
                                </div>
                            </div>
                        }
                        Salva
                    </button>
                </div>
                <div className="container mt-3">
                    {
                        !loading ?
                            <form>
                                {
                                    (user.role != EnUserRole.AGENT) &&
                                    <div className="form-row">
                                        <div className="col-md-9 col-lg-9 mb-3">
                                            <label>Farmacia Attiva</label><br/>
                                            <GreenSwitch checked={active} name="checkedActive"
                                                         disabled={loading || submit}
                                                         onChange={handleActive}/>
                                        </div>
                                    </div>
                                }
                                <div className="form-row">
                                    <div className="col-md-9 col-lg-9 mb-3">
                                        <label className="common-form-label">Nome Farmacia<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               value={pharmacy?.name}
                                               placeholder="Nome Farmacia"
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'name')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-9 col-lg-9 mb-3">
                                        <label className="common-form-label">Indirizzo<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               value={!pharmacy.address ? '' : pharmacy.address}
                                               placeholder="Indirizzo"
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'address')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 col-lg-6 mb-3">
                                        <label className="common-form-label">Citta<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               value={!pharmacy.city ? '' : pharmacy.city}
                                               placeholder="Citta" aria-label="Citta"
                                               disabled={loading || submit}
                                               aria-describedby="basic-addon2"
                                               onChange={(e) => handleChange(e, 'city')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                    <div className="col-md-3 col-lg-3 mb-3">
                                        <label className="common-form-label">Provincia<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               value={!pharmacy.province ? '' : pharmacy.province}
                                               placeholder="Provincia" aria-label="Provincia"
                                               disabled={loading || submit}
                                               aria-describedby="basic-addon2"
                                               onChange={(e) => handleChange(e, 'province')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                    <div className="col-md-3 col-lg-3 mb-3">
                                        <label className="common-form-label">Regione<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               value={!pharmacy.region ? '' : pharmacy.region}
                                               placeholder="Regione" aria-label="Regione"
                                               disabled={loading || submit}
                                               aria-describedby="basic-addon2"
                                               onChange={(e) => handleChange(e, 'region')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <label className="common-form-label">Codice VAT<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               id="validationDefault04"
                                               value={!pharmacy.VAT ? '' : pharmacy.VAT}
                                               placeholder="Codice VAT"
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'vat')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <label className="common-form-label">Microbrick</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               id="validationDefault03"
                                               value={!pharmacy.microbrick ? '' : pharmacy.microbrick}
                                               placeholder="Microbrick"
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'microbrick')}
                                               readOnly={user.role === EnUserRole.AGENT}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-row">
                                            <div className="col-md-9 col-lg-9 mb-3">
                                                <label className="common-form-label">Organico farmacia</label><br/>
                                                <i className="fa fa-users fa-lg"
                                                   onClick={() => navigateToEmployeesPage(pharmacy)} aria-hidden="true"
                                                   style={{cursor: 'pointer'}}/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-11 mb-3">
                                                <label className="common-form-label">Persona di riferimento</label><br/>
                                                <input type="text" className="form-control common-form-input"
                                                       id="validationDefault03"
                                                       value={!pharmacy.contact ? '' : pharmacy.contact}
                                                       placeholder="Persona di riferimento"
                                                       disabled={loading || submit}
                                                       onChange={(e) => handleChange(e, 'contact')}/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-11 mb-3">
                                                <label className="common-form-label">Telefono</label><br/>
                                                <input type="text" className="form-control common-form-input"
                                                       id="validationDefault03"
                                                       value={!pharmacy.phone ? '' : pharmacy.phone}
                                                       placeholder="Telefono"
                                                       disabled={loading || submit}
                                                       onChange={(e) => handleChange(e, 'phone')}/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-11 mb-3">
                                                <label className="common-form-label">E-mail</label><br/>
                                                <input type="text" className="form-control common-form-input"
                                                       id="validationDefault03"
                                                       value={!pharmacy.email ? '' : pharmacy.email}
                                                       placeholder="E-mail"
                                                       disabled={loading || submit}
                                                       onChange={(e) => handleChange(e, 'email')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-lg-8">
                                        <div className="form-row">
                                            <label className="common-form-label">Note</label><br/>
                                            <textarea className="form-control common-form-input" placeholder="Note"
                                                      value={!pharmacy.note ? '' : pharmacy.note}
                                                      id="exampleFormControlTextarea1"
                                                      disabled={loading || submit}
                                                      onChange={(e) => handleChange(e, 'note')} rows={12}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label className="common-form-label">Sito Web</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Sito Web"
                                               disabled={loading || submit}
                                               aria-label="Sito Web" aria-describedby="basic-addon2"
                                               value={!pharmacy.website ? '' : pharmacy.website}
                                               onChange={(e) => handleChange(e, 'website')}/>
                                    </div>
                                    <div className="col">
                                        <label className="common-form-label">Facebook</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Facebook"
                                               disabled={loading || submit}
                                               aria-label="Facebook" aria-describedby="basic-addon2"
                                               value={!pharmacy.facebook ? '' : pharmacy.facebook}
                                               onChange={(e) => handleChange(e, 'facebook')}/>
                                    </div>
                                    <div className="col">
                                        <label className="common-form-label">Instagram</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Instagram"
                                               disabled={loading || submit}
                                               aria-label="Instagram" aria-describedby="basic-addon2"
                                               value={!pharmacy.instagram ? '' : pharmacy.instagram}
                                               onChange={(e) => handleChange(e, 'instagram')}/>
                                    </div>
                                </div>

                                {(user.role != EnUserRole.AGENT) && <div className="form-row mt-3">
                                    <div className="col-md-4 col-lg-4">
                                        <label className="common-form-label">Pharmacy Specialist assegnato</label><br/>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="custom-dropdown"
                                            placeholder="Select"
                                            name="agent"
                                            options={agents}
                                            disabled={loading || submit}
                                            onChange={(value) => handleAgent(value)}
                                            defaultValue={agents[agentIndex]}
                                            isClearable={false}
                                        />
                                    </div>
                                </div>}
                            </form>
                            :
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div className="spinner-wrapper mr-2">
                                    <div className="spinner-border spinner-border-md" role="status">
                                        <span className="sr-only text-light">Loading...</span>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}
