import React, { useEffect, useState } from "react";
import { fetchGeneralOrdersPerYear } from "../../pages/AppointmentsTrainings/Service";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Style.css";
import _ from "lodash";

interface SelloutProps {
  pharmacy: any,
  date: any,
  sellouts: Array<any>;
  setSellouts: any;
  isDetail?: boolean;
}

export const SelloutTable: React.FC<SelloutProps> = ({
                                                       pharmacy = null,
                                                       date = null,
                                                       sellouts = [],
                                                       setSellouts,
                                                       isDetail = false
                                                     }) => {
  const today: Date = new Date();
  const [previousYear, setPreviousYear] = useState<number>(
    today.getFullYear() - 1
  );
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());
  const [nextYear, setNextYear] = useState<number>(today.getFullYear());
  const [loading, setLoading] = useState(false);

  const appointmentMonth = new Date(date).getMonth();
  const appointmentYear = new Date(date).getFullYear();

  const [groupedByMonth, setGroupedByMonth] = useState<Array<any>>([]);

  const [months, setMonths] = useState<Array<any>>([
    { id: 1, name: "GEN", invalid: false },
    { id: 2, name: "FEB", invalid: false },
    { id: 3, name: "MAR", invalid: false },
    { id: 4, name: "APR", invalid: false },
    { id: 5, name: "MAG", invalid: false },
    { id: 6, name: "GIU", invalid: false },
    { id: 7, name: "LUG", invalid: false },
    { id: 8, name: "AGO", invalid: false },
    { id: 9, name: "SET", invalid: false },
    { id: 10, name: "OTT", invalid: false },
    { id: 11, name: "NOV", invalid: false },
    { id: 12, name: "DIC", invalid: false }
  ]);

  useEffect(() => {
    load(currentYear);
    return () => {
      setSellouts([]);
    };
  }, []);

  const load = async (year: number) => {
    try {
      if (sellouts.some((value) => value.year === year)) return; // data for year selected is already fetched
      setLoading(true);
      const pharmacyId: number = pharmacy;
      const data: any[] =
        (await fetchGeneralOrdersPerYear(pharmacyId, year)) || [];
      const updated = [...sellouts, ...data];
      setSellouts(_.cloneDeep(updated));

      const elaborated: any[] = [];
      updated.forEach((sellout: any) => {
        sellout.months.forEach((month: any) => {
          elaborated.push({ productId: sellout.productId, ...month });
        });
      });
      setGroupedByMonth(elaborated);

      setLoading(false);
    } catch (e) {
      console.error("An error occurred", e);
      setLoading(false);
    }
  };

  const handleSelloutYear = (type: "next" | "prev") => {
    if (type === "prev" && currentYear === previousYear) return;
    if (type === "next" && currentYear === nextYear) return;
    const year: number = type === "next" ? currentYear + 1 : currentYear - 1;
    load(year)
      .then(() => setCurrentYear(year))
      .catch((error) => console.error("An error occurred", error));
  };

  const isSameOrFutureMonth = (month: number): boolean => {
    return currentYear === appointmentYear && month >= appointmentMonth + 1;
  };

  const isEditable = (month: any): boolean => {
    month.isEditable = true;
    return true;
  };

  const isNegative = (value: number): boolean => {
    return Math.sign(value) === -1;
  };

  const filterByYear = (year: number) => {
    return sellouts.filter((value) => value.year === Number(year));
  };

  const elaborateProductTotalStock = (sellout: any): number => {
    let totalQuantity: number = 0;
    sellout.months.forEach((month: any) => {
      if (!month.isVerified)
        totalQuantity += Number.isNaN(month.selloutQuantity)
          ? 0
          : month.selloutQuantity;
    });
    return sellout.totalStock - totalQuantity;
  };

  const verify = (month: any): string => {
    const result = Number.isNaN(month.selloutQuantity)
      ? Number.NaN
      : month.selloutQuantity;
    const filtered: any[] = groupedByMonth.filter((value: any) => value.month === month.month);
    const hasVerifiedMonth: boolean = filtered.some((value: any) => value.isVerified);
    if (hasVerifiedMonth && month.selloutQuantity === null) {
      month.invalid = true;
    }
    return result;
  };

  return (
    <div
      className="table table-responsive"
      style={{ position: "relative", zIndex: 1 }}
    >
      <>
        <table id="sellout-table" className="table m-0 p-0">
          <thead>
          <tr>
            <th scope="col" colSpan={13}>
              VERIFICA SELL OUT
            </th>
            <th scope="col" style={{ textAlign: "right" }}>
              <div className="d-flex flex-row justify-content-center align-content-center flex-nowrap">
                {currentYear > previousYear && (
                  <i
                    className="fa fa-caret-left fa-2x"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSelloutYear("prev")}
                  />
                )}
                <span className="mx-2 mt-1">{currentYear}</span>
                {currentYear < nextYear && (
                  <i
                    className="fa fa-caret-right fa-2x"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSelloutYear("next")}
                  />
                )}
              </div>
            </th>
          </tr>
          <tr>
            <th scope="col">Products</th>
            {months.map((month: any, index: number) => (
              <th key={index} id={month.id} scope="col">
                {month.name}
              </th>
            ))}
            <th
              scope="col"
              style={{ textAlign: "right", backgroundColor: "#f3f3f3" }}
            >
              <div className="d-flex flex-column justify-content-end align-content-end">
                <span>Stock</span>
              </div>
            </th>
          </tr>
          <tr className="actions">
            <th scope="col" />
            {months.map((month: any, index: number) => (
              <th key={index} scope="col">
                {month.invalid && (
                  <FontAwesomeIcon
                    icon={faEraser}
                    style={{ fontSize: "1rem" }}
                    onClick={() => {
                    }}
                  />
                )}
              </th>
            ))}
            <th scope="col" />
          </tr>
          </thead>
          <tbody style={{ listStyleType: "none", padding: 0 }}>
          {filterByYear(currentYear).map((sellout: any, sIndex: number) => (
            <tr key={sIndex}>
              <td style={{ textAlign: "left" }}>
                <small>{sellout.productName}</small>
              </td>
              {sellout.months.map((month: any, mIndex: number) => (
                <td key={mIndex}>
                  {
                    <div className="d-flex flex-column justify-content-center align-content-start">
                      {month.isNotAvailable ||
                      isSameOrFutureMonth(month.month) ||
                      (isDetail && !month.isVerified) ? (
                        <span
                          style={{
                            width: "4rem",
                            textIndent: 2,
                            borderRadius: 5,
                            border: "1px solid transparent",
                            fontSize: ".65rem"
                          }}
                        >
                            N/A
                          </span>
                      ) : (
                        isEditable(month) && (
                          <input
                            type="number"
                            className={
                              month.isVerified
                                ? "readonly-input"
                                : month.invalid
                                ? "error-input"
                                : ""
                            }
                            name="quantity"
                            readOnly={month.isVerified}
                            required
                            min={0}
                            tabIndex={sIndex * 10 + mIndex}
                            defaultValue={verify(month)}
                            onBlur={(e) => {
                              const target: HTMLInputElement = e.target;
                              let value: number = parseInt(target.value);
                              month.invalid =
                                Number.isNaN(value) || isNegative(value);
                              setSellouts(_.cloneDeep(sellouts));
                            }}
                            onChange={(e) => {
                              const target: HTMLInputElement = e.target;
                              let value: number = parseInt(target.value);
                              const edited = _.cloneDeep(sellouts);
                              edited.forEach((s: any) => {
                                if (s.year !== currentYear) return;
                                s.months.forEach((m: any) => {
                                  // is the same product and the same month
                                  if (
                                    s.productId === sellout.productId &&
                                    m.month === month.month
                                  ) {
                                    if (!m.dirty) m.dirty = true;
                                    m.invalid =
                                      Number.isNaN(value) ||
                                      isNegative(value);
                                    m.selloutQuantity = Number.isNaN(value)
                                      ? null
                                      : value;
                                  }
                                  // is different product and the same month
                                  if (
                                    s.productId !== sellout.productId &&
                                    m.month === month.month
                                  ) {
                                    if (!m.dirty) m.dirty = true;
                                    m.invalid =
                                      Number.isNaN(m.selloutQuantity) ||
                                      isNegative(m.selloutQuantity);
                                    if (m.selloutQuantity === null)
                                      m.selloutQuantity = 0;
                                  }
                                });
                                if (s.productId === sellout.productId)
                                  s.newCurrentStock = elaborateProductTotalStock(
                                    s
                                  );
                              });
                              setSellouts(edited);
                            }}
                            style={{
                              width: "4rem",
                              textIndent: 2,
                              borderRadius: 5,
                              border: "1px solid #ddd"
                            }}
                          />
                        )
                      )}
                    </div>
                  }
                </td>
              ))}
              <td style={{ textAlign: "right", backgroundColor: "#f3f3f3" }}>
                <small>
                  {sellout.hasOwnProperty("newCurrentStock") && (
                    <>
                      {sellout.newCurrentStock < 0 && (
                        <b style={{ fontSize: "1.1rem", color: "red" }}>
                          {sellout.newCurrentStock}
                        </b>
                      )}
                      {sellout.newCurrentStock >= 0 &&
                      sellout.newCurrentStock <= 2 && (
                        <b style={{ fontSize: "1.1rem", color: "orange" }}>
                          {sellout.newCurrentStock}
                        </b>
                      )}
                      {sellout.newCurrentStock > 2 && (
                        <b className="" style={{ fontSize: "1.1rem" }}>
                          {sellout.newCurrentStock}
                        </b>
                      )}
                    </>
                  )}
                  <span className="text-muted ml-2">({sellout.totalStock})</span>
                </small>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </>
      {!loading && sellouts.length === 0 && (
        <div className="d-flex flex-row justify-content-center align-items-center bg-white py-5 bordered">
          <small className="text-muted">Nessun prodotto disponibile</small>
        </div>
      )}
      {loading && (
        <div
          className="container-fluid w-100 h-100"
          style={{
            position: "absolute",
            top: 0,
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, .5)"
          }}
        >
          <div className="row justify-content-center align-items-center w-100 h-100">
            <div className="col text-center">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Caricamento...</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
