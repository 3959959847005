import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';

interface Auth {
    user: any;
    saveUser: any;
    logout: any;
}

export const getAuthDetails = () => {
    const token = localStorage.getItem('Token');
    if (token) {
        const userObj: any = jwt_decode(token);
        return { ...userObj, ...{ loggedIn: true } };
    } else {
        return { loggedIn: false };
    }
}

const AuthContext = React.createContext<Auth>({ user: getAuthDetails(), saveUser: null, logout: null });

export const AuthContextProvider = (props: any) => {

    const history = useHistory();
    const [currentUser, setCurrentUser] = useState<any>(props.settings);

    const saveUser = (values: any) => {
        setCurrentUser(values)
    };

    const logout = (values: any) => {
        localStorage.clear();
        saveUser({ loggedIn: false });
        if (history) { history.push('/login'); }
    };

    return (
        <AuthContext.Provider value={{ user: currentUser, saveUser, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;
export default AuthContext;
