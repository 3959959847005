import React from "react";
import { ProductComponent } from "./ProductComponent";

interface ProductManagementProps {
}

export const ProductManagementComponent: React.FC<ProductManagementProps> = () => {
  return (
    <ProductComponent />
  );
};
