import React, {useEffect, useState} from 'react';
import './Style.css';
import {useHistory, useLocation} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface ReportProps {
}

export const Report: React.FC<ReportProps> = ({}) => {

    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth();

    const [rootHeight, setRootHeight] = useState<number>(800);
    const [navbarHeight, setNavbarHeight] = useState<number>(60);
    const [pagesBarHeight, setPagesBarHeight] = useState<number>(60);

    const [pages, setPages] = useState<Array<any>>([
        {id: 'nPrDC', name: 'PANEL FARMACIE'},
        {id: 'cDVGC', name: 'FARMACIE DA CONTATTARE EX-NOVO'},
        {id: 'HXnGC', name: 'FARMACIE DA RICONTATTARE'},
        {id: '9QXGC', name: 'ATTIVITÁ IN FARMACIA'},
        {id: '5u4FC', name: 'SELLIN / SELLOUT'},
        {id: 'CbRGC', name: 'CAMPIONATURA'},
        {id: 'YJVGC', name: 'STOCK FARMACIA'},
        {id: '8ZHGC', name: 'TRAININGS'},
    ]);

    useEffect(() => {
        if (location.pathname.endsWith('report')) {
            history.push('/report/nPrDC');
        }
    }, [location.pathname]);

    useEffect(() => {
        setRootHeight(document.getElementById('root')?.offsetHeight || 800);
        setNavbarHeight(document.getElementById('navbar')?.offsetHeight || 60);
        setPagesBarHeight(document.getElementById('pages')?.offsetHeight || 60);
    }, []);

    const getSrc = (page: any): string => {
        return `https://datastudio.google.com/embed/reporting/2da25738-d28e-4d07-a06c-dcd43a5daf7c/page/${page.id}`;
    }

    return (
        <div id="report" className="content-fluid" style={{marginTop: navbarHeight}}>

            <ul id="pages" className="nav nav-tabs" style={{marginTop: (navbarHeight / 2)}}>
                {
                    pages.map((page: any, index: number) => {
                        return (
                            <li key={index} className="nav-item">
                                <a className={location.pathname.includes(`/report/${page.id}`) ? 'nav-link nav-link-custom custom-active' : 'nav-link nav-link-custom '}
                                   onClick={() => history.push(`/report/${page.id}`)}>{page.name}</a>
                            </li>
                        )
                    })
                }
            </ul>

            <br />

            {
                pages.map((page: any, index: number) => {
                    return (
                        <div key={index} id={`iframe-${page.id}-wrapper`}>
                            {
                                location.pathname.endsWith(page.id) &&
                                <iframe src={getSrc(page)}
                                        frameBorder="0" style={{minHeight: (rootHeight - (navbarHeight + pagesBarHeight)), width: '100%', border: 0}}/>
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}
