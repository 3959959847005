import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import { fetchAllPharmacyEmployees, fetchUpdatePharmacyEmployee, fetchAddPharmacyEmployee } from '../Service';
import { Switch, withStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import {BackButton} from '../../../components/BackButton/BackButton';
import { PatchedPagination } from "../../../components/PatchedPagination/PatchedPagination";
import Button from "@material-ui/core/Button";
import PDFadd from "@material-ui/icons/Folder";

enum EnModalAction {
    ADD = 0,
    EDIT = 1
}

interface PharmacyEmployeesProps {
}

export const PharmacyEmployees: React.FC<PharmacyEmployeesProps> = ({ }) => {
    let history = useHistory();
    let params: any = useParams();
    const { addToast } = useToasts();

    const [employees, setEmployees] = useState<any>([]);
    const [employeeId, setEmployeeId] = useState<number>();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [designation, setDesignation] = useState<string>("");
    const [active, setActive] = useState<number>(1);

    const [modalAction, setModalAction] = useState<EnModalAction>(EnModalAction.ADD);

    const [loading, setLoading] = useState<boolean>(false);

    const GreenSwitch = withStyles({
        switchBase: {
            color: grey[300],
            '&$checked': {
                color: green[500],
            },
            '&$checked + $track': {
                backgroundColor: green[500],
            },
        },
        checked: {},
        track: {},
    })(Switch);

    useEffect(() => {
        load().catch(error => console.error(error));
    }, []);

    const load = async () => {
        const pharmacyId: any = params?.id;
        if (!pharmacyId) {
            history.goBack();
            return;
        }
        setLoading(true);
        const employees: Array<any> = await fetchAllPharmacyEmployees(pharmacyId) as Array<any>;
        setEmployees(employees);
        setLoading(false);
    }

    const handleOnChangeFirstName = (event: any) => {
        setFirstName(event.target.value);
    }

    const handleOnChangeLastName = (event: any) => {
        setLastName(event.target.value);
    }

    const handleOnChangeEmail = (event: any) => {
        setEmail(event.target.value);
    }

    const handleOnChangeRole = (event: any) => {
        setRole(event.target.value);
    }

    const handleOnChangeDesignation = (event: any) => {
        setDesignation(event.target.value);
    }

    const handleOnChangeActive = (event: any) => {
        setActive(event.target.checked ? 1 : 0);
    }

    const handleModalActionAdd = async () => {
        try {
            setLoading(true);
            const employee: any = {
                firstName,
                lastName,
                role,
                designation,
                active,
                //email
            };
            const pharmacyId: any = params?.id;
            await fetchAddPharmacyEmployee(pharmacyId, employee);
            await load();
            reset();
            setLoading(false);
            addToast('Salvato con successo!', { appearance: 'success', autoDismiss: true });
        } catch (error) {
            setLoading(false);
            if (error.type == "FORM_VALIDATION") {
                error.response.forEach((err: any) => {
                    addToast(err, { appearance: 'error', autoDismiss: false });
                });
            } else {
                addToast(error, { appearance: 'error', autoDismiss: false });
            }
        }
    }

    const handleModalActionEdit = async (employeeId: any) => {
        try {
            setLoading(true);
            const employee: any = {
                firstName,
                lastName,
                role,
                designation: role == "Medico" ? designation : role,
                active,
                //email
            };
            const pharmacyId: any = params?.id;
            await fetchUpdatePharmacyEmployee(pharmacyId, employeeId, employee);
            await load();
            reset();
            setLoading(false);
            addToast('Salvato con successo!', { appearance: 'success', autoDismiss: true });
        } catch (error) {
            setLoading(false);
            if (error.type == "FORM_VALIDATION") {
                error.response.forEach((err: any) => {
                    addToast(err, { appearance: 'error', autoDismiss: false });
                });
            } else {
                addToast(error, { appearance: 'error', autoDismiss: false });
            }
        }
    }

    const goBack = () => {
        history.goBack();
    }

    const reset = () => {
        setFirstName('');
        setLastName('');
        setRole('');
        setDesignation('');
        setEmail('');
        setActive(1);
        const ROLE: any = document.getElementById('role');
        if (ROLE != null) {
            ROLE.value = '';
        }
        const MODAL: any = document.getElementById("closePharmacyEmployeesModal");
        if (MODAL != null) {
            MODAL.click();
        }
    }

    return (
        <div className="content">
            <div className="container-fluid container-material-table-height-fix">

                <div className="d-flex flex-row justify-content-start mb-4">
                    <BackButton />
                </div>

                <MaterialTable
                  components={{
                      // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                      Pagination: PatchedPagination
                  }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Nessun dato disponibile',
                            addTooltip: 'Aggiungi',
                            deleteTooltip: 'Elimina',
                            editTooltip: 'Modifica',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            },
                            editRow: {
                                deleteText: 'Vuoi effettuare la modifica?',
                                cancelTooltip: 'Annulla',
                                saveTooltip: 'Salva'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'righe'
                        },
                        header: {
                            actions: ''
                        },
                        toolbar: {
                            searchTooltip: 'Cerca',
                            searchPlaceholder: 'Cerca'
                        }
                    }}
                    options={{
                        search: false,
                        showTitle: false,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50],
                        actionsColumnIndex: -1,
                        draggable: false,
                    }}
                    columns={[
                        {
                            title: 'Nome',
                            field: 'firstName',
                            initialEditValue: ''
                        },
                        {
                            title: 'Cognome',
                            field: 'lastName',
                            initialEditValue: ''
                        },
                        {
                            title: 'Email',
                            field: 'email',
                            initialEditValue: ''
                        },
                        {
                            title: 'Ruolo',
                            field: 'role',
                            initialEditValue: '',
                            render: (rowData) => {
                                return (
                                    <>
                                        <span>{rowData.role}</span>
                                        &nbsp;
                                        {
                                            rowData.role == 'Medico' && <small>({rowData.designation})</small>
                                        }
                                        {/* <small>({rowData.designation})</small> */}
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Titolo',
                            field: 'designation',
                            initialEditValue: '',
                            hidden: true
                        },
                        {
                            title: 'Attivo',
                            field: 'active',
                            render: (rowData) => {
                                return (
                                    <GreenSwitch checked={rowData.active === 1} disabled={true} name="checkedActive" />
                                )
                            }
                        }
                    ]}
                    data={employees}
                    actions={[
                        {
                            icon: () => <div
                                style={{ width: '24px', height: '24px', display: 'flex', flexDirection: 'row' }}
                                data-toggle="modal" data-target="#pharmacyEmployeesModal"><AddBox color="action" />
                            </div>,
                            tooltip: 'Aggiungi',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                                setModalAction(EnModalAction.ADD);
                            }
                        },
                        {
                            icon: () => <div
                                style={{ width: '24px', height: '24px', display: 'flex', flexDirection: 'row' }}
                            ><PDFadd color="action" /></div>,
                            tooltip: 'Documenti',
                            onClick: (event, rowData) => {
                                const employee: any = rowData;

                                const path: string = `/pharmacy-registry/${params?.id}/employees/${employee.id}/documents`;
                                history.push(path);
                            }
                        },
                        {
                            icon: () => <div
                                style={{ width: '24px', height: '24px', display: 'flex', flexDirection: 'row' }}
                                data-toggle="modal" data-target="#pharmacyEmployeesModal"><Edit color="action" /></div>,
                            tooltip: 'Modifica',
                            onClick: (event, rowData) => {
                                setModalAction(EnModalAction.EDIT);
                                const employee: any = rowData;
                                setEmployeeId(employee.id);
                                setFirstName(employee.firstName);
                                setLastName(employee.lastName);
                                setRole(String(employee.role));
                                setDesignation(employee.designation);
                                setActive(employee.active);
                                setEmail(employee.email);
                            }
                        }
                    ]}
                />
            </div>
            <div className="modal fade" id="pharmacyEmployeesModal" tabIndex={-1} role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"
                                id="exampleModalLabel">{modalAction === EnModalAction.ADD ? 'Aggiungi' : 'Modifica'}</h5>
                            <button onClick={()=> reset()} type="button" id="closePharmacyEmployeesModal" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="d-flex flex-column mb-2">
                                    <label className="common-form-label" htmlFor="lastName" style={{ marginBottom: '0.2rem' }}>Nome</label>
                                    <input type="text" className="form-control common-form-input" id="firstName" name="firstName"
                                           aria-describedby="firstNameHelp"
                                           placeholder="Inserisci il nome" value={firstName}
                                           onChange={handleOnChangeFirstName} />
                                </div>
                                <div className="d-flex flex-column mb-2">
                                    <label className="common-form-label" htmlFor="lastName" style={{ marginBottom: '0.2rem' }}>Cognome</label>
                                    <input type="text" className="form-control common-form-input" id="lastName" name="lastName"
                                           aria-describedby="lastNameHelp"
                                           placeholder="Inserisci il cognome" value={lastName}
                                           onChange={handleOnChangeLastName} />
                                </div>
                                {/*<div className="d-flex flex-column mb-2">
                                    <label className="common-form-label" htmlFor="lastName" style={{ marginBottom: '0.2rem' }}>Email</label>
                                    <input type="text" className="form-control common-form-input" id="email" name="email"
                                           aria-describedby="lastNameHelp"
                                           placeholder="Inserisci l'email" value={email}
                                           onChange={handleOnChangeEmail} />
                                </div>*/}
                                <div className="d-flex flex-column mb-2">
                                    <label className="common-form-label" htmlFor="role" style={{ marginBottom: '0.2rem' }}>Ruolo</label>
                                    <select className="form-control common-form-input" name="role" id="role"
                                            onChange={handleOnChangeRole} defaultValue={''}>
                                        <option value={''} />
                                        <option value="Titolare" selected={role == "Titolare"}>Titolare</option>
                                        <option value="Medico" selected={role == "Medico"}>Medico</option>
                                        <option value="Nutrizionista" selected={role == "Nutrizionista"}>Nutrizionista</option>
                                        <option value="Farmacista collaboratore" selected={role == "Farmacista collaboratore"}>Farmacista collaboratore</option>
                                        <option value="Addetto reparto cosmetico" selected={role == "Addetto reparto cosmetico"}>Addetto reparto cosmetico</option>
                                        <option value="Stagista" selected={role == "Stagista"}>Stagista</option>
                                        <option value="Magazziniere" selected={role == "Magazziniere"}>Magazziniere</option>
                                        <option value="Estetista" selected={role == "Estetista"}>Estetista</option>
                                    </select>
                                </div>
                                {
                                    role === 'Medico' &&
                                    <div className="d-flex flex-column mb-2">
                                        <label className="common-form-label" htmlFor="designation" style={{ marginBottom: '0.2rem' }}>Specializzazione</label>
                                        <input type="text" className="form-control common-form-input" id="designation" name="designation"
                                               aria-describedby="designationHelp"
                                               placeholder="" value={designation}
                                               onChange={handleOnChangeDesignation} />
                                    </div>
                                }
                                {
                                    modalAction === EnModalAction.EDIT &&
                                    <div className="d-flex flex-column mb-2">
                                        <label htmlFor="active" style={{ marginTop: '0.2rem' }}>Active</label>
                                        <GreenSwitch
                                            id="active"
                                            checked={active === 1}
                                            name="active"
                                            onChange={handleOnChangeActive}
                                        />
                                    </div>

                                }
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn common-btn add-new-pharmacy"
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    modalAction === EnModalAction.ADD ? handleModalActionAdd() : handleModalActionEdit(employeeId)
                                }}
                                disabled={loading}>
                                {modalAction === EnModalAction.ADD ? "Salva" : "Modifica"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
