import React, {useState} from 'react';
import AsyncSelect from 'react-select/async';
import {fetchNegativeResultSelectOptions} from '../Service';
import {INegativeResult} from '../../interfaces/INegativeResult';

interface NegativeResultProps {
    negativeResult: INegativeResult | undefined,
    setNegativeResult: (negativeResult: INegativeResult | undefined) => void,
    onChange?: (negativeReason: any | undefined) => void
    isDisabled?: boolean,
}

export const NegativeResult: React.FC<NegativeResultProps> = ({
                                                                        negativeResult = undefined,
                                                                        setNegativeResult = () => {
                                                                        },
                                                                        onChange = () => {
                                                                        },
                                                                        isDisabled = false
                                                                    }) => {

    const [negativeResults, setNegativeResults] = useState<Array<INegativeResult>>([]);

    const loadOptions = (inputValue: string, callback: any) => {
        if (inputValue) {
            const filtered = negativeResults.filter(negativeReason => negativeReason.reason.toLowerCase().includes(inputValue.toLowerCase()));
            callback(filtered.map((negativeResult: INegativeResult) => ({
                label: negativeResult.reason,
                value: negativeResult.id
            })));
            return;
        }
        fetchNegativeResultSelectOptions().then((results: Array<INegativeResult>) => {
            const negativeResults: Array<any> = results;
            setNegativeResults(negativeResults);
            callback(negativeResults.map((negativeResult: INegativeResult) => ({
                label: negativeResult.reason,
                value: negativeResult.id
            })));
        }).catch(error => console.error('An error occurred', error));
    }

    const onPharmacyChanges = (value: INegativeResult | undefined) => {
        const negativeResult: INegativeResult | undefined = negativeResults.find((negativeResult: INegativeResult) => negativeResult.id === value?.id);
        setNegativeResult(negativeResult);
        onChange(negativeResult);
    }

    return (
        <>
            <label className="common-form-label">Motivazione</label>
            <AsyncSelect
                className="negative-result-search"
                classNamePrefix="custom-dropdown"
                placeholder=''
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                isDisabled={isDisabled}
                value={{label: negativeResult?.reason, value: negativeResult?.id}}
                onChange={(value) => {
                    const negativeResult: INegativeResult | undefined = negativeResults.find(nr => nr.id === value?.value);
                    onPharmacyChanges(negativeResult);
                }}/>
        </>
    );
}
