import React, { useEffect, useState } from "react";
import { fetchProducts } from "../../StockManagement/Service";
import { EnAppointmentPharmacyStatus } from "../LanguageMappings";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { PharmacySelect } from "../../../components/PharmacySelect";
import {
  fetchCreateAppointment,
  fetchPharmacyStockByPharmacyId,
  fetchProductSampleStockByAgentId
} from "../Service";
import { DatePickerComponent } from "../../../components/DatePicker/DatePickerComponent";
import { SelloutTable } from "../../../components/SelloutTable/SelloutTable";
import { GeneralSelection } from "../../../components/GeneralSelection/GeneralSelection";
import Select from "react-select";
import swal from "sweetalert";
import { IPharmacy } from "../../../interfaces/IPharmacy";
import * as _ from "lodash";
import { INegativeResult } from "../../../interfaces/INegativeResult";
import { NegativeResult } from "../../../components/NegativeResult/NegativeResult";
import "./Style.css";
import { BackButton } from "../../../components/BackButton/BackButton";

interface AppointmentFormProps {
}

export const AddAppointmentComponent: React.FC<AppointmentFormProps> = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToasts();

  // Default
  const [products, setProducts] = useState<Array<any>>([]);
  const [stockSpecialist, setStockSpecialist] = useState<Array<any>>([]);
  const [stockPharmacy, setStockPharmacy] = useState<Array<any>>([]);

  // Form entities
  const [pharmacy, setPharmacy] = useState<IPharmacy | undefined>();
  const [contactDate, setContactDate] = useState<any>();
  const [appointmentDate, setAppointmentDate] = useState<any>();
  const [contactType, setContactType] = useState<{
    value: string;
    label: string;
  }>();
  const [contactOutcome, setContactOutcome] = useState<{
    value: string;
    label: string;
  }>();
  const [positiveResults, setPositiveResults] = useState<Array<any>>([]);
  const [negativeResult, setNegativeResult] = useState<INegativeResult>();
  const [checkboxPharmacyStatusDeleted, setCheckboxPharmacyStatusDeleted] =
    useState<boolean>(false);
  const [checkboxPharmacyStatusReContact, setCheckboxPharmacyStatusReContact] =
    useState<boolean>(false);
  const [
    checkboxPharmacyStatusChainGroups,
    setCheckboxPharmacyStatusChainGroups
  ] = useState<boolean>(false);
  const [pharmacyStatus, setPharmacyStatus] =
    useState<EnAppointmentPharmacyStatus>(EnAppointmentPharmacyStatus.NORMAL);
  const [samples, setSamples] = useState<Array<any>>([]);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [reorders, setReOrders] = useState<Array<any>>([]);
  const [sellouts, setSellouts] = useState<Array<any>>([]);
  const [sampling, setSampling] = useState<0 | 1 | 2>(2);
  const [coachingType, setCoachingType] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchProducts(), fetchProductSampleStockByAgentId(user.sub)])
      .then((results: Array<any>) => {
        setProducts(results[0] || []);
        setStockSpecialist(results[1] || []);
        setLoading(false);
      })
      .catch((e) => {
        console.error("An error occurred", e);
        addToast("Errore durante il caricamento dei dati.", {
          appearance: "error",
          autoDismiss: false
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!pharmacy) {
      return;
    }
    setLoading(true);
    fetchPharmacyStockByPharmacyId(pharmacy.id)
      .then((stock: Array<any>) => {
        setStockPharmacy(stock || []);
        setLoading(false);
      })
      .catch((e) => {
        console.error("An error occurred", e);
        addToast(
          "Errore durante il caricamento dei dati relativi allo stock della farmacia.",
          {
            appearance: "error",
            autoDismiss: false
          }
        );
        setLoading(false);
      });
  }, [pharmacy]);

  useEffect(() => {
    if (!products) {
      return;
    }
    setOrders(elaborateStockFor("order", stockPharmacy));
    setReOrders(elaborateStockFor("reOrder", stockPharmacy));
  }, [products]);

  useEffect(() => {
    handleAppointmentDateChanges(undefined);
    handlePharmacyStatusCheckboxChanges();
    handleNegativeResultChanges(undefined);
    handleSamplingChanges(2);
    handlePositiveResultsChanges(null);
  }, [contactOutcome]);

  useEffect(() => {
    handlePharmacyStatusCheckboxChanges();
    handleNegativeResultChanges(undefined);
  }, [positiveResults]);

  useEffect(() => {
    if (sampling !== 1) {
      return;
    }
    setSamples(
      elaborateStockFor("sample", stockSpecialist, (value) => value.stock > 0)
    );
  }, [sampling]);

  const elaborateStockFor = (
    key: "sample" | "order" | "reOrder",
    stock: Array<any>,
    callback: (value: any) => boolean = () => true
  ): Array<any> => {
    const samples: Array<any> = products.filter((value) => value[key] === 1);
    const mapped: Array<any> = samples.map((value) => ({
      id: value.id,
      name: value.name,
      quantity: undefined,
      active: false,
      stock: stockSpecialist.reduce(
        (previousValue, currentValue) =>
          currentValue.productId === value.id
            ? currentValue.quantity
            : previousValue,
        0
      )
    }));
    return mapped.filter(callback);
  };

  const handlePharmacyChanges = (pharmacy: IPharmacy | undefined) => {
    setPharmacy(pharmacy);
  };

  const handleContactDateChanges = (date: any) => {
    setContactDate(date);
  };

  const handleContactTypeChanges = (data: any) => {
    setContactType(data);
  };

  const handleContactOutcomeChanges = (data: any) => {
    setContactOutcome(data);
  };

  const handleAppointmentDateChanges = (date: any) => {
    setAppointmentDate(date);
  };

  const handlePositiveResultsChanges = (
    type:
      | "ORDER"
      | "RE_ORDER"
      | "VISIT"
      | "SELL_OUT_CHECK"
      | "ALLESTIMENTO"
      | "NEGATIVO"
      | "MAIL_REMINDER"
      | "COACHING"
      | null = null,
    intersection: Array<string> = []
  ) => {
    if (intersection.length !== 0) {
      return;
    }
    const results: Array<string> =
      type !== null
        ? _.xor(positiveResults, [type])
        : _.drop(positiveResults, positiveResults.length);
    setPositiveResults(results);
  };

  const handleCoachingType = (value: string | undefined) => {
    setCoachingType(value);
  }

  const handleNegativeResultChanges = (
    negativeResult: INegativeResult | undefined
  ) => {
    setNegativeResult(negativeResult);
  };

  const handlePharmacyStatusCheckboxChanges = (
    type: EnAppointmentPharmacyStatus = EnAppointmentPharmacyStatus.NORMAL
  ) => {
    switch (type) {
      case EnAppointmentPharmacyStatus.ELIMINATA:
        setCheckboxPharmacyStatusDeleted(true);
        setCheckboxPharmacyStatusReContact(false);
        setCheckboxPharmacyStatusChainGroups(false);
        break;
      case EnAppointmentPharmacyStatus.RICONTATTARE:
        setCheckboxPharmacyStatusDeleted(false);
        setCheckboxPharmacyStatusReContact(true);
        setCheckboxPharmacyStatusChainGroups(false);
        break;
      case EnAppointmentPharmacyStatus.CATENE_E_GRUPPI:
        setCheckboxPharmacyStatusDeleted(false);
        setCheckboxPharmacyStatusReContact(false);
        setCheckboxPharmacyStatusChainGroups(true);
        break;
      case EnAppointmentPharmacyStatus.NORMAL:
        setCheckboxPharmacyStatusDeleted(false);
        setCheckboxPharmacyStatusReContact(false);
        setCheckboxPharmacyStatusChainGroups(false);
        break;
    }
    setPharmacyStatus(type);
  };

  const handleSamplingChanges = (value: 0 | 1 | 2) => {
    setSampling(sampling === value ? 2 : value);
  };

  const handleSampleChanges = (sample: any, value: number) => {
    const index: number = samples.findIndex((o) => o.id === sample.id);
    const updated: any = samples[index];
    updated.quantity = value;
    samples.splice(index, 1, updated);
    setSamples([...samples]);
  };

  const handleReOrderChanges = (reorder: any, value: number) => {
    const index: number = reorders.findIndex((o) => o.id === reorder.id);
    const updated: any = reorders[index];
    updated.quantity = value;
    reorders.splice(index, 1, updated);
    setReOrders([...reorders]);
  };

  const handleOrderChanges = (order: any, value: number) => {
    const index: number = orders.findIndex((o) => o.id === order.id);
    const updated: any = orders[index];
    updated.quantity = value;
    orders.splice(index, 1, updated);
    setOrders([...orders]);
  };

  const hasSelloutInvalidMonth = (): boolean => {
    return sellouts.some((sellout: any) => {
      return sellout.months.some((month: any, i: number) => {
        return month.invalid === true;
      });
    });
  };

  const hasSelloutNegativeStock = (): boolean => {
    return sellouts.some((sellout: any) => {
      if (!sellout.hasOwnProperty("newCurrentStock")) return false;
      return Math.sign(sellout.newCurrentStock) === -1;
    });
  };

  const isSelloutUntouched = (): boolean => {
    return sellouts.every((sellout: any) => {
      return sellout.months.every((month: any, i: number) => {
        return month.dirty === false && month.invalid === false;
      });
    });
  };

  const reset = () => {
    setPharmacy(undefined);
    setContactDate(undefined);
    setContactType(undefined);
    setContactOutcome(undefined);
    setAppointmentDate(undefined);
    setPositiveResults([]);
    resetListOf(orders, "quantity", setOrders);
    resetListOf(reorders, "quantity", setReOrders);
    resetListOf(samples, "quantity", setSamples);
    setCheckboxPharmacyStatusDeleted(false);
    setCheckboxPharmacyStatusReContact(false);
    setCheckboxPharmacyStatusChainGroups(false);
    setSampling(2);
  };

  const resetListOf = (
    list: Array<any>,
    key: string,
    set: (list: Array<any>) => void
  ): void => {
    list.forEach((item) => {
      if (item[key]) {
        item[key] = undefined;
      }
    });
    set([...list]);
  };

  const submit = async (dto: any) => {
    setSubmitting(true);
    fetchCreateAppointment(dto)
      .then(() => {
        setSubmitting(false);
        addToast("Salvato con successo !", {
          appearance: "success",
          autoDismiss: true
        });
        goBack();
      })
      .catch((error: any) => {
        setSubmitting(false);
        console.error("An error occurred", error);
        if (error.type === "FORM_VALIDATION") {
          error.response.forEach((e: any) =>
            addToast(e, { appearance: "error", autoDismiss: false })
          );
        } else {
          addToast(error.message, { appearance: "error", autoDismiss: false });
        }
      });
  };

  const preSubmit = async () => {
    try {
      let isConfirmed;

      const dto: any = {
        appointment: {
          pharmacyId: pharmacy?.id,
          contactDate,
          appointmentDate,
          contactType: contactType?.value,
          contactOutcome: contactOutcome?.value,
          positiveResults,
          negativeResultId: negativeResult?.id,
          pharmacyStatus,
          sampling,
          coachingType
        },
        orders: [],
        reorders: [],
        samples: [],
        sellouts: [],
        makePharmacyInactive:
          (contactOutcome?.value === "NEGATIVE" ||
            positiveResults?.includes("NEGATIVO")) &&
          (pharmacyStatus === EnAppointmentPharmacyStatus.ELIMINATA ||
            pharmacyStatus === EnAppointmentPharmacyStatus.CATENE_E_GRUPPI)
      };

      if (positiveResults?.includes("ORDER")) {
        dto.orders = orders
          ?.filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity
          }));
        if (dto.orders.length === 0) {
          addToast("L'ordine richiede almeno un articolo!", {
            appearance: "error",
            autoDismiss: false
          });
          return;
        }
      }

      if (positiveResults?.includes("RE_ORDER")) {
        dto.reorders = reorders
          ?.filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity
          }));
        if (dto.reorders.length === 0) {
          addToast("Il riordine richiede almeno un articolo!", {
            appearance: "error",
            autoDismiss: false
          });
          return;
        }
      }

      if (positiveResults?.includes("SELL_OUT_CHECK")) {
        if (isSelloutUntouched()) {
          addToast(`La verifica sell-out richiede almeno un articolo!`, {
            appearance: "error",
            autoDismiss: false
          });
          return;
        }
        if (hasSelloutInvalidMonth()) {
          addToast(
            `La verifica sell-out richiede tutti gli articoli per i mesi valorizzati!`,
            {
              appearance: "error",
              autoDismiss: false
            }
          );
          return;
        }
        dto.sellouts = [];
        sellouts?.forEach((s: any) => {
          s.months.forEach((m: any) => {
            let createSelloutDto: any = {};
            createSelloutDto.quantity = m.selloutQuantity;
            createSelloutDto.month = m.month;
            createSelloutDto.year = s.year;
            createSelloutDto.productId = s.productId;
            const element =
              dto.sellouts.find(
                (value: any) =>
                  value.productId === s.productId && value.month === m.month
              ) || undefined;
            if (
              element === undefined &&
              !m.isNotAvailable &&
              !m.isVerified &&
              m.selloutQuantity !== null
            ) {
              dto.sellouts.push(createSelloutDto);
            }
          });
        });
      }

      if (sampling === 1) {
        if (samples.length === 0) {
          addToast("Compila la tabella CAMPIONI", {
            appearance: "error",
            autoDismiss: false
          });
          return;
        }
        dto.samples = samples
          .filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity
          }));
      }

      if (hasSelloutNegativeStock()) {
        await swal(
          "ATTENZIONE",
          "La verifica sell-out contiene stock negativi.",
          "warning",
          {
            buttons: {
              checked: {
                text: "Ok",
                value: true,
                visible: true,
                className: "btn btn-sm btn-outline-dark",
                closeModal: true
              }
            }
          }
        );
      }

      isConfirmed = await swal(
        "",
        "Sei sicuro di voler salvare questo appuntamento?",
        "info",
        {
          buttons: {
            cancel: {
              text: "No",
              value: false,
              visible: true,
              className: "btn btn-sm btn-outline-dark",
              closeModal: true
            },
            confirm: {
              text: "Si",
              value: true,
              visible: true,
              className: "btn btn-sm btn-outline-dark",
              closeModal: true
            }
          }
        }
      );

      if (isConfirmed) {
        await submit(dto);
      }
    } catch (error) {
      console.error("An error occurred", error);
      if (error.type === "FORM_VALIDATION") {
        error.response.forEach((err: any) =>
          addToast(err, { appearance: "error", autoDismiss: false })
        );
      } else {
        addToast(error.message, { appearance: "error", autoDismiss: false });
      }
    }
  };

  const goBack = () => {
    history.push("/appointments-and-training?toggleState=APPOINTMENTS");
  };

  return (
    <form id="add-appointment" className="content">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <BackButton callback={goBack} />
          </div>
          <div className="d-flex flex-row justify-content-end align-content-center">
            <button
              type="button"
              className="btn common-btn"
              onClick={reset}
              disabled={submitting || !pharmacy}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn common-btn ml-2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
              disabled={
                submitting ||
                !pharmacy ||
                !contactDate ||
                !contactType ||
                !contactOutcome
              }
              onClick={preSubmit}
            >
              {submitting && (
                <div className="spinner-wrapper mr-2">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only text-light">Loading...</span>
                  </div>
                </div>
              )}
              Salva
            </button>
          </div>
        </div>

        <div className="container my-5">
          <PharmacySelect
            pharmacy={pharmacy}
            setPharmacy={setPharmacy}
            onChange={handlePharmacyChanges}
            isDisabled={!!pharmacy}
          />
        </div>

        {pharmacy && (
          <div
            className="container-fluid animate__animated animate__fadeIn p-5"
            style={{
              position: "relative",
              zIndex: 0,
              border: "3px dashed #CACACA"
            }}
          >
            <div className="row justify-content-start align-items-center">
              <div className="col-12 col-lg-4 my-1">
                <DatePickerComponent
                  label="Data Contatto"
                  currentDate={contactDate}
                  setDate={handleContactDateChanges}
                  dateType="appointmentContactDate"
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect={false}
                />
              </div>
              <div className="col-12 col-lg-4 my-1">
                <label className="common-form-label">Tipo contatto</label>
                <Select
                  className="basic-single"
                  classNamePrefix="custom-dropdown"
                  name="Tippo contatto"
                  placeholder="Seleziona il tipo di contatto"
                  value={contactType}
                  isDisabled={!contactDate}
                  options={[
                    { value: "PHONE", label: "Telefonico" },
                    { value: "IN_PERSON", label: "Fisico" },
                    { value: "MAIL", label: "Mail" }
                  ]}
                  onChange={(value) => handleContactTypeChanges(value)}
                />
              </div>
              <div className="col-12 col-lg-4 my-1">
                <label className="common-form-label">Esito Contatto</label>
                <Select
                  className="basic-single"
                  classNamePrefix="custom-dropdown"
                  name="contactOutcome"
                  placeholder="Seleziona l'esito del contatto"
                  value={contactOutcome}
                  isDisabled={!contactDate}
                  options={[
                    { value: "POSITIVE", label: "Positivo" },
                    { value: "NEGATIVE", label: "Negativo" },
                    { value: "MAIL_REMINDER", label: "Mail Reminder" }
                  ]}
                  onChange={(value) => {
                    handleContactOutcomeChanges(value);
                  }}
                />
              </div>
            </div>

            {contactOutcome?.value === "POSITIVE" && (
              <>
                <hr className="hr-text" data-content="ESITO POSITIVO" />
                {/* APPOINTMENT DATE */}
                <div className="row mb-4">
                  <div className="col-4">
                    <DatePickerComponent
                      label="Data appuntamento"
                      currentDate={appointmentDate}
                      minDate={contactDate}
                      setDate={handleAppointmentDateChanges}
                      dateType="appointmentDate"
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeSelect={true}
                    />
                  </div>
                </div>
                {/* POSITIVE RESULT */}
                <>
                  {appointmentDate && (
                    <div className="row mb-4">
                      <div className="col-12">
                        <label className="common-form-label">
                          Esito Appuntamento
                        </label>
                        <ul id="contact-outcome" className="list-group">
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                            onClick={() =>
                              handlePositiveResultsChanges(
                                "ORDER",
                                _.intersection(positiveResults, [
                                  "RE_ORDER",
                                  "SELL_OUT_CHECK",
                                  "VISIT",
                                  "ALLESTIMENTO",
                                  "MAIL_REMINDER",
                                  "NEGATIVO",
                                  "COACHING"
                                ])
                              )
                            }
                          >
                            ORDINE
                            <div
                              id="order"
                              className={`custom-checkbox ${
                                positiveResults.includes("ORDER") &&
                                "checkbox-active"
                              } ${
                                _.intersection(positiveResults, [
                                  "RE_ORDER",
                                  "SELL_OUT_CHECK",
                                  "VISIT",
                                  "ALLESTIMENTO",
                                  "MAIL_REMINDER",
                                  "NEGATIVO",
                                  "COACHING"
                                ]).length !== 0 && "combo-disabled"
                              }`}
                            />
                          </li>
                          {pharmacy?.isClient && (
                            <>
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                onClick={() =>
                                  handlePositiveResultsChanges(
                                    "RE_ORDER",
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ])
                                  )
                                }
                              >
                                RIORDINE
                                <div
                                  id="order"
                                  className={`custom-checkbox ${
                                    positiveResults.includes("RE_ORDER") &&
                                    "checkbox-active"
                                  } ${
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ]).length !== 0 && "combo-disabled"
                                  }`}
                                />
                              </li>
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                onClick={() =>
                                  handlePositiveResultsChanges(
                                    "SELL_OUT_CHECK",
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ])
                                  )
                                }
                              >
                                VERIFICA SELL-OUT
                                <div
                                  id="order"
                                  className={`custom-checkbox ${
                                    positiveResults.includes(
                                      "SELL_OUT_CHECK"
                                    ) && "checkbox-active"
                                  } ${
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ]).length !== 0 && "combo-disabled"
                                  }`}
                                />
                              </li>
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                onClick={() =>
                                  handlePositiveResultsChanges(
                                    "COACHING",
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ])
                                  )
                                }
                              >
                                ATTIVITÀ DI COACHING
                                <div
                                  id="order"
                                  className={`custom-checkbox ${
                                    positiveResults.includes("COACHING") &&
                                    "checkbox-active"
                                  } ${
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ]).length !== 0 && "combo-disabled"
                                  }`}
                                />
                              </li>

                              <li
                                className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                onClick={() =>
                                  handlePositiveResultsChanges(
                                    "VISIT",
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ])
                                  )
                                }
                              >
                                VISITA
                                <div
                                  id="order"
                                  className={`custom-checkbox ${
                                    positiveResults.includes("VISIT") &&
                                    "checkbox-active"
                                  } ${
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ]).length !== 0 && "combo-disabled"
                                  }`}
                                />
                              </li>
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                onClick={() =>
                                  handlePositiveResultsChanges(
                                    "ALLESTIMENTO",
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ])
                                  )
                                }
                              >
                                ALLESTIMENTO
                                <div
                                  id="order"
                                  className={`custom-checkbox ${
                                    positiveResults.includes("ALLESTIMENTO") &&
                                    "checkbox-active"
                                  } ${
                                    _.intersection(positiveResults, [
                                      "ORDER",
                                      "MAIL_REMINDER",
                                      "NEGATIVO"
                                    ]).length !== 0 && "combo-disabled"
                                  }`}
                                />
                              </li>
                            </>
                          )}
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                            onClick={() =>
                              handlePositiveResultsChanges(
                                "MAIL_REMINDER",
                                _.intersection(positiveResults, [
                                  "ORDER",
                                  "RE_ORDER",
                                  "VISIT",
                                  "SELL_OUT_CHECK",
                                  "ALLESTIMENTO",
                                  "NEGATIVO",
                                  "COACHING"
                                ])
                              )
                            }
                          >
                            MAIL REMINDER
                            <div
                              id="order"
                              className={`custom-checkbox ${
                                positiveResults.includes("MAIL_REMINDER") &&
                                "checkbox-active"
                              } ${
                                _.intersection(positiveResults, [
                                  "ORDER",
                                  "RE_ORDER",
                                  "VISIT",
                                  "SELL_OUT_CHECK",
                                  "ALLESTIMENTO",
                                  "NEGATIVO",
                                  "COACHING"
                                ]).length !== 0 && "combo-disabled"
                              }`}
                            />
                          </li>
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                            onClick={() =>
                              handlePositiveResultsChanges(
                                "NEGATIVO",
                                _.intersection(positiveResults, [
                                  "ORDER",
                                  "RE_ORDER",
                                  "VISIT",
                                  "SELL_OUT_CHECK",
                                  "ALLESTIMENTO",
                                  "MAIL_REMINDER",
                                  "COACHING"
                                ])
                              )
                            }
                          >
                            NEGATIVO
                            <div
                              id="order"
                              className={`custom-checkbox ${
                                positiveResults.includes("NEGATIVO") &&
                                "checkbox-active"
                              } ${
                                _.intersection(positiveResults, [
                                  "ORDER",
                                  "RE_ORDER",
                                  "VISIT",
                                  "SELL_OUT_CHECK",
                                  "ALLESTIMENTO",
                                  "MAIL_REMINDER",
                                  "COACHING"
                                ]).length !== 0 && "combo-disabled"
                              }`}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </>
                {/* ORDER */}
                <>
                  {appointmentDate && positiveResults.includes("ORDER") && (
                    <>
                      <hr className="hr-text" data-content="ORDINE" />
                      <div className="row mb-4">
                        <div className="col-12">
                          <GeneralSelection
                            title="Ordine"
                            handleChange={handleOrderChanges}
                            data={orders}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
                {/* RE_ORDER */}
                <>
                  {appointmentDate && positiveResults.includes("RE_ORDER") && (
                    <>
                      <hr className="hr-text" data-content="RIORDINE" />
                      <div className="row mb-4">
                        <div className="col-12">
                          <GeneralSelection
                            title="Riordine"
                            handleChange={handleReOrderChanges}
                            data={reorders}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
                {/* SELL-OUT */}
                <>
                  {appointmentDate &&
                  positiveResults.includes("SELL_OUT_CHECK") && (
                    <>
                      <hr className="hr-text" data-content="VERIFICA SELL-OUT" />
                      <div className="row mb-4">
                        <div className="col-12">
                          <SelloutTable
                            pharmacy={pharmacy?.id}
                            date={appointmentDate}
                            sellouts={sellouts}
                            setSellouts={setSellouts}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
                {/* ATTIVITÀ DI COACHING*/}
                <>
                  {
                    positiveResults?.includes("COACHING") &&
                    <>
                      <hr className="hr-text" data-content="ATTIVITÀ DI COACHING" />
                      <div className="row">
                        <div className="col-12 col-lg-4 mb-2">
                          <label
                            className="common-form-label"
                            htmlFor="coaching"
                          >
                            Giornata
                          </label>
                          <Select
                            isClearable={false}
                            className="basic-single"
                            classNamePrefix="custom-dropdown"
                            placeholder=""
                            name="coaching"
                            options={[
                              { label: "Tutto", value: "ALL" },
                              { label: "Mattina", value: "MORNING" },
                              { label: "Pomeriggio", value: "AFTERNOON" }
                            ]}
                            onChange={(type) => {
                              handleCoachingType(type?.value);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                </>
              </>
            )}

            {/* NEGATIVE */}
            {(contactOutcome?.value === "NEGATIVE" ||
              positiveResults.includes("NEGATIVO")) && (
              <>
                <hr className="hr-text" data-content="ESITO NEGATIVO" />

                {/* PHARMACY STATUS */}
                <div className="row justify-content-start align-items-center mb-4">
                  <div className="col-auto col-xl-2 my-2">
                    <label className="common-form-label">Stato farmacia</label>
                    <div
                      className="d-flex flex-row justify-content-start align-items-center"
                    >
                      <div
                        id="delete-pharmacy"
                        onClick={() =>
                          handlePharmacyStatusCheckboxChanges(
                            EnAppointmentPharmacyStatus.ELIMINATA
                          )
                        }
                        className={
                          checkboxPharmacyStatusDeleted
                            ? "custom-checkbox checkbox-delete checkbox-active"
                            : "custom-checkbox checkbox-delete"
                        }
                      />
                      <label className="common-form-label ml-2 mb-0">
                        Eliminata
                      </label>
                    </div>
                  </div>
                  <div className="col-auto col-xl-2 my-2">
                    <label className="common-form-label">&nbsp;</label>
                    <div
                      className="d-flex flex-row justify-content-start align-items-center"
                    >
                      <div
                        id="re-contact-pharmacy"
                        onClick={() =>
                          handlePharmacyStatusCheckboxChanges(
                            EnAppointmentPharmacyStatus.RICONTATTARE
                          )
                        }
                        className={
                          checkboxPharmacyStatusReContact
                            ? "custom-checkbox checkbox-delete checkbox-active"
                            : "custom-checkbox checkbox-delete"
                        }
                      />
                      <label className="common-form-label ml-2 mb-0">
                        Ricontattare
                      </label>
                    </div>
                  </div>
                  <div className="col-auto col-xl-2 my-2">
                    <label className="common-form-label">&nbsp;</label>
                    <div
                      className="d-flex flex-row justify-content-start align-items-center"
                    >
                      <div
                        id="chains-groups-pharmacy"
                        onClick={() =>
                          handlePharmacyStatusCheckboxChanges(
                            EnAppointmentPharmacyStatus.CATENE_E_GRUPPI
                          )
                        }
                        className={
                          checkboxPharmacyStatusChainGroups
                            ? "custom-checkbox checkbox-delete checkbox-active"
                            : "custom-checkbox checkbox-delete"
                        }
                      />
                      <label className="common-form-label ml-2 mb-0">
                        Catene-Gruppi
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 my-2">
                    <NegativeResult
                      negativeResult={negativeResult}
                      setNegativeResult={setNegativeResult}
                      onChange={handleNegativeResultChanges}
                      isDisabled={
                        pharmacyStatus === EnAppointmentPharmacyStatus.NORMAL
                      }
                    />
                  </div>
                </div>
              </>
            )}

            {/* SAMPLING */}
            {(((contactOutcome?.value === "POSITIVE" && appointmentDate) || contactOutcome?.value === "NEGATIVE") && !positiveResults.includes('MAIL_REMINDER')) && (
              <>
                <hr className="hr-text" data-content="CAMPIONATURA" />
                <div className="row justify-content-start align-items-center mb-4">
                  <div className="col my-1">
                    <label style={{ color: "black" }}>Campioni</label>
                    <br />
                    <button
                      type="button"
                      className={
                        sampling === 1
                          ? "btn btn-sm btn-dark"
                          : "btn btn-sm btn-outline-dark"
                      }
                      onClick={() => handleSamplingChanges(1)}
                    >
                      Si
                    </button>
                    <button
                      type="button"
                      className={
                        sampling === 0
                          ? "btn btn-sm btn-dark"
                          : "btn btn-sm btn-outline-dark"
                      }
                      onClick={() => handleSamplingChanges(0)}
                    >
                      No
                    </button>
                  </div>
                  {sampling === 1 && (
                    <div className="col-12 my-2">
                      <GeneralSelection
                        title="Campionatura"
                        hasStock={true}
                        handleChange={handleSampleChanges}
                        data={samples}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {loading && (
              <div
                className="container-fluid w-100 h-100"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 10,
                  backgroundColor: "rgba(255, 255, 255, .6)"
                }}
              >
                <div className="row justify-content-center align-items-center w-100 h-100">
                  <div className="col text-center">
                    <div className="spinner-border text-dark" role="status">
                      <span className="sr-only">Caricamento...</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </form>
  );
};
