import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useToasts } from "react-toast-notifications";
import it from "date-fns/locale/it";
import { useHistory, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {
  fetchProductSampleStockByAgentId,
  fetchTrainableProductsByPharmacyId,
  fetchTrainingAppointmentById, fetchUpdatedTrainingAppointmentPharmacyCycle
} from "../Service";
import { DateTime } from "luxon";
import { EnTrainingType, TrainingTypeLabels } from "../LanguageMappings";
import {
  fetchAllPharmacyEmployees,
  fetchPharmacyCycleDetailsByPharmacyId,
} from "../../PharmaciesRegistry/Service";
import { IPharmacy } from "../../../interfaces/IPharmacy";
import { TrainingEmployeeComponent } from "./components/TrainingEmployee/TrainingEmployeeComponent";
import { BackButton } from "../../../components/BackButton/BackButton";
import { GeneralSelection } from "../../../components/GeneralSelection/GeneralSelection";
import { fetchProducts } from "../../StockManagement/Service";

registerLocale("it", it);

interface EditTrainingProps {}

export const EditTrainingComponent: React.FC<EditTrainingProps> = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToasts();
  const params: any = useParams();

  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [training, setTraining] = useState<any>();
  const [pharmacy, setPharmacy] = useState<IPharmacy>();
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [products, setProducts] = useState<Array<any>>([]);
  const [trainableProducts, setTrainableProducts] = useState<Array<any>>([]);
  const [details, setDetails] = useState<Array<any>>([]);

  const [trainingPharmacyStatus, setTrainingPharmacyStatus] =
    useState<string>();
  const [trainingSessionType, setTrainingSessionType] = useState<string>();
  const [trainingTotalDurationHours, setTrainingTotalDurationHours] =
    useState<number>(0);
  const [trainingTotalDurationMinutes, setTrainingTotalDurationMinutes] =
    useState<number>(0);
  const [trainingEmployeeData, setTrainingEmployeeData] = useState<Array<any>>(
    []
  );

  const [sampling, setSampling] = useState<number>(2);
  const [samples, setSamples] = useState<Array<any>>([]);

  useEffect(() => {
    const trainingId: number = parseInt(params.id);
    load(trainingId).catch((error) => console.error(error));
  }, []);

  const load = async (trainingId: number) => {
    setLoading(true);

    const training: any = await fetchTrainingAppointmentById(trainingId);
    setTraining(training);

    const pharmacy: any = training.pharmacy;
    setPharmacy(pharmacy);

    const sampling: number = training.sampling;
    setSampling(sampling);

    const pharmacyStatus: string = training.pharmacyStatus || "";
    setTrainingPharmacyStatus(pharmacyStatus);

    const trainingSessionType: string = training.trainingSessionType || "";
    setTrainingSessionType(trainingSessionType);

    const totalDuration: number = training.totalDuration;
    parseTotalDurationFromMillis(totalDuration);

    const employees: Array<any> = (await fetchAllPharmacyEmployees(
      pharmacy.id
    )) as Array<any>;
    setEmployees(employees);

    let products: Array<any> = await fetchProducts();
    setProducts(products);

    let trainableProducts: Array<any> =
      (await fetchTrainableProductsByPharmacyId(
        pharmacy.id,
        true
      )) as Array<any>;
    setTrainableProducts(trainableProducts);

    const pharmacyCycle: any = await fetchPharmacyCycleDetailsByPharmacyId(
      pharmacy.id
    );

    const specialistSampleList: Array<any> =
      await fetchProductSampleStockByAgentId(user.sub);
    const elaboratedSamples: Array<any> = elaborateStockData(
      products,
      specialistSampleList,
      "sample",
      (value) => value.stock > 0
    );
    setSamples(elaboratedSamples);

    const details: Array<any> = pharmacyCycle?.pharmacyCycleDetails;
    setDetails(details);

    setLoading(false);
  };

  const elaborateStockData = (
    products: Array<any>,
    stock: Array<any>,
    key: "sample" | "order" | "reOrder",
    callback: (value: any) => boolean = () => true
  ): Array<any> => {
    const samples: Array<any> = products.filter((value) => value[key] === 1);
    const mapped: Array<any> = samples.map((value) => ({
      id: value.id,
      name: value.name,
      quantity: undefined,
      active: false,
      stock: stock.reduce(
        (previousValue, currentValue) =>
          currentValue.productId === value.id
            ? currentValue.quantity
            : previousValue,
        0
      ),
    }));
    return mapped.filter(callback);
  };

  const goBack = () => {
    const path: string = pharmacy?.id
      ? `/appointments-and-training/trainings?pharmacyId=${pharmacy?.id}`
      : "/appointments-and-training/trainings";
    history.push(path);
  };

  const parseDate = (value: string) => {
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
  };

  const parseTrainingType = (value: EnTrainingType): string => {
    switch (value) {
      case EnTrainingType.IN_PERSON:
        return TrainingTypeLabels.IN_PERSON;
      case EnTrainingType.REMOTE:
        return TrainingTypeLabels.REMOTE;
    }
    return "";
  };

  const parseTotalDurationFromMillis = (seconds: number = 0) => {
    if (seconds === 0) {
      return;
    }
    const date: DateTime = DateTime.fromSeconds(seconds);
    setTrainingTotalDurationHours(date.hour);
    setTrainingTotalDurationMinutes(date.minute);
  };

  const handleSelectPharmacyStatus = (event: any) => {
    const value = event?.target?.value;
    setTrainingPharmacyStatus(value);
  };

  const handleSelectSessionType = (event: any) => {
    const value = event?.target?.value;
    setTrainingSessionType(value);
  };

  const handleInputTrainingTotalDurationHours = (event: any) => {
    const value = event?.target?.value;
    if (value) setTrainingTotalDurationHours(parseInt(value));
  };

  const handleInputTrainingTotalDurationMinutes = (event: any) => {
    const value = event?.target?.value;
    if (value) setTrainingTotalDurationMinutes(parseInt(value));
  };

  const handleTrainingEmployeeData = (
    employeeId: number,
    duration: number | undefined,
    productIds: Array<number>
  ): void => {
    const employeeIndex: number = trainingEmployeeData.findIndex(
      (value) => value.employeeId === employeeId
    );
    if (employeeIndex === -1) {
      trainingEmployeeData.push({ employeeId, duration, products: productIds });
    } else {
      const data: any = trainingEmployeeData[employeeIndex];
      data.duration = duration;
      data.products = productIds;
    }
    setTrainingEmployeeData(trainingEmployeeData);
  };

  const handleSamplingChanges = (value: 0 | 1 | 2) => {
    setSampling(value);
  };

  useEffect(() => {
    console.log('Campionatura', samples);
  }, [samples]);

  const handleSampleChanges = (sample: any, value: number) => {
    const index: number = samples.findIndex((o) => o.id === sample.id);
    const updated: any = samples[index];
    updated.quantity = value;
    samples.splice(index, 1, updated);
    setSamples([...samples]);
  };

  const handleButtonSave = async () => {
    try {
      const request: any = {
        pharmacyStatus: trainingPharmacyStatus,
        trainingSessionType: trainingSessionType,
        totalDuration: null,
        employeeTrainingData: trainingEmployeeData,
        sampling,
        samples: [],
      };

      const now: DateTime = DateTime.fromObject({
        hour: trainingTotalDurationHours,
        minute: trainingTotalDurationMinutes,
      });
      request.totalDuration = now.toSeconds();

      if (sampling === 1) {
        if (samples.length === 0) {
          addToast("Compila la tabella CAMPIONI", {
            appearance: "error",
            autoDismiss: false,
          });
          return;
        }
        request.samples = samples
          .filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity,
          }));
      }

      const id: number | undefined = pharmacy ? pharmacy.id : undefined;
      if (!id) {
        addToast("Farmacia non valida.", {
          appearance: "error",
          autoDismiss: false,
        });
        return;
      }

      setSubmit(true);
      await fetchUpdatedTrainingAppointmentPharmacyCycle(
        id,
        training.id,
        request
      );
      addToast("Salvato con successo!", {
        appearance: "success",
        autoDismiss: true,
      });
      setSubmit(false);
      goBack();
    } catch (error) {
      console.error("An error occurred", error);
      if (error.type === "FORM_VALIDATION") {
        error.response.forEach((err: any) => {
          addToast(err, { appearance: "error", autoDismiss: false });
        });
      } else {
        addToast(error.message, { appearance: "error", autoDismiss: false });
      }
      setSubmit(false);
    }
  };

  const getEmployeeDuration = (
    details: Array<any> = [],
    employee: any
  ): number | undefined => {
    const filtered: Array<any> = details.filter((detail: any) => {
      return (
        training.id === detail.trainingAppointment.id &&
        detail.pharmacyEmployee.id === employee.id
      );
    });
    return filtered.length > 0 ? filtered[0].duration : undefined;
  };

  const isCompleted = () => {
    return training?.status === 1;
  };

  return (
    <div className="content" id="page-edit-training">
      <div className="container-fluid animate__animated animate__fadeIn">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <BackButton callback={goBack} />
          {!isCompleted() && (
            <button
              type="button"
              className="btn common-btn"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={submit}
              onClick={handleButtonSave}
            >
              {submit && (
                <div className="spinner-wrapper mr-2">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only text-light">Loading...</span>
                  </div>
                </div>
              )}
              Salva
            </button>
          )}
        </div>

        <div className="container my-5 p-2">
          <div className="row my-2">
            <div className="col-12 col-md-2">
              <label>Nome Farmacia</label>
            </div>
            <div className="col-auto">{pharmacy?.name}</div>
          </div>
          <div className="row my-2">
            <div className="col-12 col-md-2">
              <label>Data Contatto</label>
            </div>
            <div className="col-auto">{parseDate(training?.contactDate)}</div>
          </div>
          <div className="row my-2">
            <div className="col-12 col-md-2">
              <label>Data Appuntamento</label>
            </div>
            <div className="col-auto">{parseDate(training?.trainingDate)}</div>
          </div>
          <div className="row my-2">
            <div className="col-12 col-md-2">
              <label>Tipo di Training</label>
            </div>
            <div className="col-auto">
              {parseTrainingType(training?.trainingType)}
            </div>
          </div>
        </div>

        <div
          className="container-fluid my-5 py-2 px-3"
          style={{ border: "3px dashed #CACACA" }}
        >
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-6 mb-2">
              <div className="d-flex flex-column justify-content-start align-items-start p-2 pt-4">
                <h6
                  className="p-0 m-0"
                  style={{ borderBottom: "1px solid #CACACA", width: "100%" }}
                >
                  Dettaglio
                </h6>
                <small className="text-muted">
                  Specifica lo stato della farmacia ed il tipo di sessione.
                </small>
              </div>

              <div className="row justify-content-start align-items-center p-2">
                <div className="col-12 col-md-6 mb-2">
                  <label
                    className="common-form-label"
                    htmlFor="pharmacyStatus"
                    style={{ marginBottom: "0.2rem" }}
                  >
                    Stato Farmacia {training?.trainingPharmacyStatus}
                  </label>
                  <select
                    className="form-control common-form-input"
                    name="pharmacyStatus"
                    id="pharmacyStatus"
                    onChange={handleSelectPharmacyStatus}
                    value={trainingPharmacyStatus}
                    disabled={submit || isCompleted()}
                  >
                    <option value={""} />
                    <option value={"OPEN"}>Aperta</option>
                    <option value={"CLOSED"}>Chiusa</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <label
                    className="common-form-label"
                    htmlFor="trainingSessionType"
                    style={{ marginBottom: "0.2rem" }}
                  >
                    Tipo di sessione
                  </label>
                  <select
                    className="form-control common-form-input"
                    name="trainingSessionType"
                    id="trainingSessionType"
                    onChange={handleSelectSessionType}
                    value={trainingSessionType}
                    disabled={submit || isCompleted()}
                  >
                    <option value={""} />
                    <option value={"A PERSON"}>1 Persona</option>
                    <option value={"TWO PEOPLE"}>2 Persone</option>
                    <option value={"THREE PEOPLE"}>3 Persone</option>
                    <option value={"GROUP"}>Di Gruppo</option>
                    <option value={"FULL STAFF"}>Staff Completo</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <div className="d-flex flex-column justify-content-start align-items-start p-2 pt-4">
                <h6
                  className="p-0 m-0"
                  style={{ borderBottom: "1px solid #CACACA", width: "100%" }}
                >
                  Durata totale
                </h6>
                <small className="text-muted">
                  Specifica quante ore e minuti hai trascorso in farmacia.
                </small>
              </div>

              <div className="row justify-content-start align-items-center p-2">
                <div className="col-12 col-md-6 mb-2">
                  <label
                    className="common-form-label"
                    htmlFor="hour"
                    style={{ marginBottom: "0.2rem" }}
                  >
                    Ore Totali
                  </label>
                  <input
                    type="number"
                    className="form-control common-form-input"
                    id="hour"
                    name="hour"
                    placeholder="Ore"
                    aria-describedby="hourHelp"
                    min={0}
                    maxLength={2}
                    max={8}
                    value={
                      trainingTotalDurationHours === 0
                        ? ""
                        : trainingTotalDurationHours
                    }
                    onChange={handleInputTrainingTotalDurationHours}
                    disabled={submit || isCompleted()}
                  />
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <label
                    className="common-form-label"
                    htmlFor="minute"
                    style={{ marginBottom: "0.2rem" }}
                  >
                    Minuti Totali
                  </label>
                  <input
                    type="number"
                    className="form-control common-form-input"
                    id="minute"
                    name="minute"
                    placeholder="Minuti"
                    aria-describedby="minuteHelp"
                    min={0}
                    maxLength={2}
                    max={60}
                    value={
                      trainingTotalDurationMinutes == 0
                        ? ""
                        : trainingTotalDurationMinutes
                    }
                    onChange={handleInputTrainingTotalDurationMinutes}
                    disabled={submit || isCompleted()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-start align-items-start p-2 pt-4">
            <h6
              className="p-0 m-0"
              style={{ borderBottom: "1px solid #CACACA", width: "100%" }}
            >
              Campionatura
            </h6>
          </div>

          {isCompleted() ? (
            <>
              <div className="d-flex flex-column justify-content-start align-items-start p-2 pt-4">
                <label className="common-form-label">Campioni</label>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <span className="badge badge-dark mr-1 p-2">
                    {training?.sampling === 1 ? "SI" : "NO"}
                  </span>
                </div>
              </div>
              {training?.samples?.length > 0 && (
                <div className="d-flex flex-column justify-content-start align-items-start p-2 pt-4">
                  <ul className="list-group w-100">
                    <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                      <b>Dettaglio</b>
                    </li>
                    {training?.samples?.map((sample: any, index: number) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center text-center"
                      >
                        <span className="text-muted">
                          {sample?.product?.name}
                        </span>
                        <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                          {sample?.quantity}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="d-flex flex-column justify-content-start align-items-start p-2">
                <div className="pb-4">
                  <label style={{ color: "black" }}>Campioni</label>
                  <div className="d-flex flex-row">
                    <button
                      type="button"
                      className={
                        sampling === 1
                          ? "btn btn-sm btn-dark"
                          : "btn btn-sm btn-outline-dark"
                      }
                      onClick={() => handleSamplingChanges(1)}
                    >
                      Si
                    </button>
                    <button
                      type="button"
                      className={
                        sampling === 0
                          ? "btn btn-sm btn-dark"
                          : "btn btn-sm btn-outline-dark"
                      }
                      onClick={() => handleSamplingChanges(0)}
                    >
                      No
                    </button>
                  </div>
                </div>
                {sampling === 1 && (
                  <GeneralSelection
                    title="Campionatura"
                    hasStock={true}
                    handleChange={handleSampleChanges}
                    data={samples}
                  />
                )}
              </div>
            </>
          )}

          <div className="d-flex flex-column justify-content-start align-items-start p-2 pt-4">
            <h6
              className="p-0 m-0"
              style={{ borderBottom: "1px solid #CACACA", width: "100%" }}
            >
              Dipendenti / Prodotti
            </h6>
            <small className="text-muted">
              Per ciascun dipendente specifica la durata del training ed i
              prodotti oggetto del training.
            </small>
          </div>
          {employees.length === 0 ? (
            <div className="d-flex flex-row justify-content-center align-items-center py-2">
              <small className="text-muted">Nessun dipendente trovato</small>
            </div>
          ) : (
            employees
              .filter((employee: any) => {
                return employee.active !== 0;
              })
              .map((employee, index) => (
                <TrainingEmployeeComponent
                  key={index}
                  id={`training-employee-${index}`}
                  appointmentId={training?.id}
                  employee={employee}
                  duration={getEmployeeDuration(details, employee)}
                  products={trainableProducts}
                  details={details}
                  lookup={isCompleted()}
                  handleTrainingEmployeeData={handleTrainingEmployeeData}
                  loading={loading}
                />
              ))
          )}
        </div>
      </div>
    </div>
  );
};
