import React, {useEffect, useState} from 'react'
import {KPI} from '../../components/KPI/KPI';
import Select from 'react-select';
import {EnUserRole} from '../../helpers/PrivateRoute/PrivateRoute';
import useAuth from '../../hooks/useAuth';
import MaterialTable from 'material-table';
import {fetchAllAgents} from '../StockManagement/Service';
import {useToasts} from 'react-toast-notifications';
import {DateTime} from 'luxon';
import {fetchAllAppointments, fetchAllTrainings, retrievesReportsFor} from './Service';
import {IReports} from '../../interfaces/IReports';
import { PatchedPagination } from "../../components/PatchedPagination/PatchedPagination";
import moment from 'moment';

interface AgentProps {
}

export const HomeComponent: React.FC<AgentProps> = () => {

    const {user, saveUser} = useAuth();
    const {addToast} = useToasts();

    const [pharmacySpecialists, setPharmacySpecialist] = useState<Array<any>>([]);
    const [reports, setReports] = useState<IReports>();
    const [appointments, setAppointments] = useState<Array<any>>([]);
    const [trainings, setTrainings] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const now = DateTime.local();

    useEffect(() => {
        load().catch((error: any) => console.error('HomeComponent', error));
    }, [])

    const load = async (role: string = user.role, agentId?: number): Promise<void> => {
        try {
            setLoading(true);
            const pharmacySpecialists = await fetchAllAgents() as Array<any>;
            pharmacySpecialists.forEach(pharmacySpecialist => {
                pharmacySpecialist.label = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
                pharmacySpecialist.value = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
            });
            setPharmacySpecialist(pharmacySpecialists);
            const reports: IReports = agentId ? await retrievesReportsFor(role.toLowerCase(), agentId) : await retrievesReportsFor(role.toLowerCase());
            setReports(reports);
            debugKPICard(reports);
            const appointments = await fetchAllAppointments() as Array<any>;
            setAppointments(appointments);
            const trainings = await fetchAllTrainings() as Array<any>;
            setTrainings(trainings);
            setData({
                future: {
                    appointments: appointments.filter(value => filtering(value, 'appointmentDate', agentId)),
                    trainings: trainings.filter(value => filtering(value, 'trainingDate', agentId))
                }
            })
            setLoading(false);
        } catch (e) {
            addToast('Error: ' + e.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const filtering = (value: any, key: string, agentId: number | null = null, isBefore: boolean = false) => {
        if (agentId) {
            return ((value.agentId === agentId) && (isBefore ? (DateTime.fromISO(value[key]) < now) : (DateTime.fromISO(value[key]) >= now)));
        }
        return (isBefore ? (DateTime.fromISO(value[key]) < now) : (DateTime.fromISO(value[key]) >= now));
    }

    const elaborateTrafficLightStatus = (context: string = 'DEFAULT', value: number = 0): string => {
        switch (context) {
            case 'LINKED':
                return 'success';
            case 'WITH_ACTIVE_ORDER':
                if (value > 0) return 'success';
                return 'danger';
            case 'SHOULD_BE_CONTACTED':
                if (value > 1) return 'danger';
                return 'success';
            case 'SHOULD_BE_CONTACTED_AGAIN':
                if (value > 0) return 'danger';
                return 'success';
            case 'NOT_CONTACTED_FOR_A_LONG_TIME':
                if (value > 1) return 'danger';
                return 'success';
            case 'SHOULD_BE_TRAINED':
                if (value > 0) return 'danger';
                return 'success';
        }
        return 'danger';
    };

    const parseDate = (date: any) => {
        return new Date(date).toLocaleDateString('it-IT');
    }

    const parseContactType = (contactType: string) => {
        if (contactType === 'PHONE') {
            return 'Telefonico';
        } else if (contactType === 'IN_PERSON') {
            return 'Fisico'
        } else {
            return 'Mail'
        }
    }

    const filterByAgent = async (pharmacySpecialist: any) => {
        !!pharmacySpecialist ? await load(EnUserRole.AGENT, pharmacySpecialist.id) : await load(user.role);
    }

    const table = (title: string, columns: Array<any> = [], data: Array<any> = [], panel: undefined |((rowData: any) => React.ReactNode) = undefined) => {
        return (
            <MaterialTable
                style={{ height: '100%'}}
              components={{
                  // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                  Pagination: PatchedPagination
              }}
                title={title}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Nessun dato disponibile',
                        filterRow: {
                            filterTooltip: 'Filtro'
                        }
                    }
                }}
                columns={columns}
                data={data}
                isLoading={loading}
                options={{
                    search: false,
                    filtering: true,
                    //minBodyHeight: '250px',
                    //maxBodyHeight: '250px',
                    emptyRowsWhenPaging: false,
                    showTitle: title.length > 0,
                    toolbar: title.length > 0, // otherwise if the title is present will be not visible
                    pageSizeOptions: [5],
                    draggable: false,
                    sorting: false
                }}
              detailPanel={panel}
            />
        );
    }

    const getAppointmentTableColumns = () => {
        return (user.role !== EnUserRole.AGENT ?
                [{
                    title: 'Pharmacy Specialist',
                    field: 'agentName'
                }, {
                    title: 'Farmacia',
                    field: 'pharmacyName'
                }, {
                    title: 'Data contatto',
                    field: 'contactDate',
                    render: ((data: any) => parseDate(data.contactDate))
                }, {
                    title: 'Data appuntamento',
                    field: 'appointmentDate',
                    render: ((data: any) => parseDate(data.appointmentDate))
                }]
                :
                [{
                    title: 'Farmacia',
                    field: 'pharmacyName',
                    filtering: true
                }, {
                    title: 'Data contatto',
                    field: 'contactDate',
                    render: ((data: any) => parseDate(data.contactDate))
                }, {
                    title: 'Data appuntamento',
                    field: 'appointmentDate',
                    render: ((data: any) => parseDate(data.appointmentDate))
                }]
        );
    }

    const getTrainingTableColumns = () => {
        return (user.role !== EnUserRole.AGENT ?
            [{
                title: 'Pharmacy Specialist',
                field: 'agentName'
            }, {
                title: 'Farmacia',
                field: 'pharmacyName'
            }, {
                title: 'Data contatto',
                field: 'contactDate',
                render: ((data: any) => parseDate(data.contactDate))
            }, {
                title: 'Data appuntamento',
                field: 'trainingDate',
                render: ((data: any) => parseDate(data.trainingDate))
            }]
            :
            [{
                title: 'Farmacia',
                field: 'pharmacyName',
                filtering: true
            }, {
                title: 'Data contatto',
                field: 'contactDate',
                render: ((data: any) => parseDate(data.contactDate))
            }, {
                title: 'Data appuntamento',
                field: 'trainingDate',
                render: ((data: any) => parseDate(data.trainingDate))
            }]
        );
    }

    const modal = (id: string, title: string, subtitle: string | null, body: JSX.Element) => {
        return (
            <div className="modal fade" id={id} tabIndex={-1} role="dialog" aria-labelledby="modalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content" style={{ height: '75dvh' }}>
                        <div className="modal-header">
                            <div className="d-flex flex-column justify-content-start align-content-start">
                                <h5 className="modal-title">{title}</h5>
                                {subtitle && <p className="text-muted">{subtitle}</p>}
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{padding: 0, minHeight: '100%' }}>
                            {body}
                        </div>
                        {/*<div className="modal-footer">*/}
                        {/*    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>*/}
                        {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    }

    const getModalBodyPharmaciesTable = (data: Array<any> = []): JSX.Element => {
        return (table('', (
            user.role !== EnUserRole.AGENT ? [
                {
                    title: 'Pharmacy Specialist',
                    field: 'pharmacySpecialist',
                    filtering: true
                },
                {
                    title: 'Farmacia',
                    field: 'pharmacyName',
                    filtering: true
                },
                {
                    title: 'Città',
                    field: 'pharmacyCity',
                    filtering: true
                },
                {
                    title: 'Contatto',
                    field: 'pharmacyContact',
                    filtering: true
                }
            ] : [
                {
                    title: 'Farmacia',
                    field: 'pharmacyName',
                    filtering: true
                },
                {
                    title: 'Città',
                    field: 'pharmacyCity',
                    filtering: true
                },
                {
                    title: 'Contatto',
                    field: 'pharmacyContact'
                }
            ]
        ), data));
    }

    const getModalBodyPharmaciesDocumentsEmployeesTable = (data: Array<any> = []): JSX.Element => {
        return (table('', (
            user.role !== EnUserRole.AGENT ? [
                {
                    title: 'Farmacia',
                    field: 'pharmacyName',
                    filtering: true
                },
                {
                    title: 'Nome',
                    field: 'employeeName',
                    filtering: true
                },
                {
                    title: 'Cognome',
                    field: 'employeeLastName',
                    filtering: true
                },
                {
                    title: 'Data firma',
                    field: 'date',
                    filtering: true,
                    render: (rowData: any) => {
                        return (
                            <div style={{position:'relative', display:'flex', flexDirection:'row', justifyContent:'start', alignItems:'center'}}>
                                <div>
                                    <p style={{ margin: '0 0 0 0'}}>{moment(new Date(rowData!.date)).format('DD/MM/YYYY HH:mm:ss')}</p>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: 'Email',
                    field: 'employeeEmail',
                    filtering: true
                }

            ] : [
                {
                    title: 'Farmacia',
                    field: 'pharmacyName',
                    filtering: true
                },
                {
                    title: 'Nome',
                    field: 'employeeName',
                    filtering: true
                },
                {
                    title: 'Cognome',
                    field: 'employeeLastName',
                    filtering: true
                },
                {
                    title: 'Data firma',
                    field: 'date',
                    filtering: true,
                    render: (rowData: any) => {
                        return (
                            <div style={{position:'relative', display:'flex', flexDirection:'row', justifyContent:'start', alignItems:'center'}}>
                                <div>
                                    <p style={{ margin: '0 0 0 0'}}>{moment(new Date(rowData!.date)).format('DD/MM/YYYY HH:mm:ss')}</p>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: 'Email',
                    field: 'employeeEmail',
                    filtering: true
                }
            ]
        ),
            data,
            ((rowData: any) => {
                // @ts-ignore
            return (<div
                className="row justify-content-start align-items-start px-4 py-4"
                style={{ backgroundColor: "#f4f4f4" }}
            >
                <div className="col-12 col-lg-6 mb-2">
                    <ul className="list-group">
                        <li className="list-group-item">
                            <b>Dettaglio</b>
                        </li>
                        {rowData?.pharmacyName && (
                            <Li
                                label="Pharmacy"
                                values={rowData.pharmacyName}
                            />
                        )}
                        {rowData?.pharmacyCity && (
                            <Li
                                label="Città"
                                values={rowData.pharmacyCity}
                            />
                        )}
                        {rowData?.employeeName && rowData?.employeeLastName && (
                            <Li
                                label="Dipendente"
                                values={rowData.employeeName + " " + rowData?.employeeLastName}
                            />
                        )}
                        {rowData?.date && (
                            <Li
                                label="Data Firma"
                                values={moment(new Date(rowData.date)).format('DD/MM/YYYY HH:mm:ss')}
                            />
                        )}
                        {rowData?.employeeEmail && (
                            <Li
                                label="Email"
                                values={rowData.employeeEmail}
                            />
                        )}
                        {
                            rowData?.informatives && rowData?.informatives.map((info : any) => {
                                return <Li
                                    label={info.text}
                                    values={info.value}
                                    badge={true}
                                    badgeColor={
                                        info.value === "Opt Out"
                                            ? "danger"
                                            : "success"
                                    }
                                />
                            })
                        }
                    </ul>
                </div>
            </div>);
        })));
    }

    const debugKPICard = (reports: IReports) => {
        console.log('Reports', reports);
        const joined = [
          ...reports.pharmacies.withActiveOrders,
          ...reports.pharmacies.shouldBeContacted,
          ...reports.pharmacies.shouldBeContactedAgain,
          ...reports.pharmacies.withChainAndGroups,
          ...reports.pharmacies.deleted
        ];
        console.log('Total linked pharmacy', reports.pharmacies.linked.length);
        console.log('Summed KPI Card', joined.length);
        // console.log('Difference', JSON.stringify(_.differenceBy(reports.pharmacies.linked, joined, (value: any) => value.pharmacyId).map(value => ({id: value.pharmacyId, name: value.pharmacyName}))));
    }

    return (
        <div className="content">
            <div className="container-fluid">
                <h4 className="text-muted">OVERALL</h4>
                <hr/>
                {
                    user.role !== EnUserRole.AGENT &&
                    <div className="d-flex flex-row flex-wrap justify-content-start align-items-center my-4">
                        <form style={{width: '100%'}}>
                            <div className="row">
                                <div className="col-12 col-lg-6 mb-2">
                                    <label className="common-form-label" htmlFor="pharmacy-specialist">Pharmacy
                                        Specialist</label>
                                    <Select
                                        isClearable={true}
                                        className="basic-single"
                                        classNamePrefix="custom-dropdown"
                                        placeholder=""
                                        name="pharmacy-specialist"
                                        options={pharmacySpecialists}
                                        onChange={(pharmacySpecialist) => filterByAgent(pharmacySpecialist)}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                }

                <div className="d-flex flex-row flex-wrap justify-content-center justify-content-lg-start align-items-center align-content-lg-start mt-5 mb-2">
                        <KPI title={'Panel farmacie'} value={reports?.pharmacies?.linked?.length}
                             trafficLightColor={'black'}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-1"/>
                        <KPI title={'Farmacie con ordini attivi'}
                             value={reports?.pharmacies?.withActiveOrders?.length}
                             trafficLightColor={elaborateTrafficLightStatus('WITH_ACTIVE_ORDER', reports?.pharmacies?.withActiveOrders?.length)}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-2"/>

                        <KPI title={<span>Farmacie da contattare<br/> ex-novo</span>}
                             value={reports?.pharmacies?.shouldBeContacted?.length}
                             trafficLightColor={elaborateTrafficLightStatus('SHOULD_BE_CONTACTED', reports?.pharmacies?.shouldBeContacted?.length)}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-3"/>
                        <KPI title={'Farmacie da ricontattare'}
                             value={((reports?.pharmacies?.shouldBeContactedAgain?.length || 0) + (reports?.appointments?.mailReminder?.length || 0))}
                             trafficLightColor={elaborateTrafficLightStatus('SHOULD_BE_CONTACTED_AGAIN', ((reports?.pharmacies?.shouldBeContactedAgain?.length || 0) + (reports?.appointments?.mailReminder?.length || 0)))}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-4"/>

                        <KPI title={'Farmacie non formate da troppo tempo'}
                             value={reports?.pharmacies?.notContactedForALongTime?.length}
                             trafficLightColor={elaborateTrafficLightStatus('NOT_CONTACTED_FOR_A_LONG_TIME', reports?.pharmacies?.notContactedForALongTime?.length)}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-5"
                        />
                        <KPI title={'Nuove farmacie da formare'}
                             value={reports?.pharmacies?.shouldBeTrained?.length}
                             trafficLightColor={elaborateTrafficLightStatus('SHOULD_BE_TRAINED', reports?.pharmacies?.shouldBeTrained?.length)}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-6"/>

                        <KPI title={'Catene e gruppi'}
                             value={reports?.pharmacies?.withChainAndGroups?.length}
                             trafficLightColor={'black'}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-7"/>
                        <KPI title={'Farmacie eliminate'}
                             value={reports?.pharmacies?.deleted?.length}
                             trafficLightColor={'black'}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-8"/>

                        <KPI title={'Documenti Firmati'}
                             value={reports ? `${reports?.pharmacies?.documents?.length} / ${reports?.employees}` : "0/0"}
                             trafficLightColor={'black'}
                             loading={loading}
                             active={true}
                             dataToggle="modal"
                             dataTarget="#kpi-card-detail-9"/>
                </div>

                <br/>
                <h4 className="text-muted">AGENDA</h4>
                <hr/>
                <div className="row justify-content-start align-content-center">
                    <div className="col-12 col-lg-6">
                        {
                            table('Appuntamenti', getAppointmentTableColumns(), (user.role !== EnUserRole.AGENT ? data?.future?.appointments : data?.future?.appointments?.filter((el: any) => el.contactOutcome === 'POSITIVE')))
                        }
                    </div>
                    <div className="col-12 col-lg-6">
                        {
                            table('Training', getTrainingTableColumns(), data?.future?.trainings)
                        }
                    </div>
                </div>

                {
                    modal('kpi-card-detail-1', 'Panel farmacie', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.linked))
                }
                {
                    modal('kpi-card-detail-2', 'Farmacie con ordini attivi', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.withActiveOrders))
                }
                {
                    modal('kpi-card-detail-3', 'Farmacie da contattare', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.shouldBeContacted))
                }
                {
                    modal('kpi-card-detail-4', 'Farmacie da ricontattare', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.shouldBeContactedAgain))
                }
                {
                    modal('kpi-card-detail-5', 'Farmacie non formate da troppo tempo', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.notContactedForALongTime))
                }
                {
                    modal('kpi-card-detail-6', 'Training non completati', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.shouldBeTrained))
                }
                {
                    modal('kpi-card-detail-7', 'Catene e gruppi', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.withChainAndGroups))
                }
                {
                    modal('kpi-card-detail-8', 'Farmacie eliminate', 'Dettaglio', getModalBodyPharmaciesTable(reports?.pharmacies?.deleted))
                }
                {
                    reports && reports.pharmacies?.documents?.length > 0 && modal('kpi-card-detail-9', 'Documenti firmati', 'Dettaglio', getModalBodyPharmaciesDocumentsEmployeesTable(reports?.pharmacies?.documents))
                }
            </div>
        </div>
    );
}

const Li: React.FC<{
    label: string | null;
    values: string | Array<string | null> | null;
    badge?: boolean;
    badgeColor?: string;
    type?: any;
}> = ({
          label = "Label",
          values = [],
          badge = false,
          badgeColor = "info",
          type = null,
      }) => {
    const [isArray, setIsArray] = useState<boolean>(false);

    useEffect(() => {
        if (values) setIsArray(Array.isArray(values));
    }, [values]);

    const items = (values: Array<string | null>): JSX.Element[] => {
        return values.map((value: string | null, index: number) =>
                badge ? (
                    <span
                        key={index}
                        className={`badge badge-pill badge-${badgeColor} mat-appoint-badge-table px-2 py-1 m-1`}
                    >
          {parseValueType(value)}
        </span>
                ) : (
                    <span key={index} className="text-dense">
          {parseValueType(value)}
        </span>
                )
        );
    };

    const item = (values: string | null): JSX.Element => {
        return (
            <>
                {badge ? (
                    <span
                        className={`badge badge-pill badge-${badgeColor} mat-appoint-badge-table px-2 py-1 m-1`}
                    >
            {parseValueType(values)}
          </span>
                ) : (
                    <span className="text-dense">{parseValueType(values)}</span>
                )}
            </>
        );
    };

    const parseValueType = (value: string | null) => {
        return type !== null && value !== null ? type[value] : value;
    };

    return (
        <li className="list-group-item">
            <div className="row justify-content-between align-items-center">
                <div className="col-12 col-sm-6 text-left">
                    <span className="text-muted">{label}</span>
                </div>
                <div className="col-12 col-sm-6 text-right">
                    {isArray && Array.isArray(values)
                        ? items(values as Array<string | null>)
                        : item(values as string | null)}
                </div>
            </div>
        </li>
    );
};
