// API to List out the Pharmacies
export const fetchAllAgents = (): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/users/agents`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};


export const updateStock = (agent_id: number, stock_details: any[] = []) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/product-samples/stock/agent/${agent_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      },
      body: JSON.stringify(stock_details)
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};
// API to List out the Pharmacies
export const fetchProducts = (): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};

// API to List out the Pharmacies
export const fetchAgentStock = (id: any) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/product-samples/stock/agent/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};


export const fetchStockProductAuditEntries = (agentId: number, productId: number) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/product-samples/stock/audit/agent/${agentId}/product/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};
