import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import Switch from "@material-ui/core/Switch";
import { fetchAllProducts } from "../AppointmentsTrainings/Service";
import { fetchProductAdd, fetchProductUpdate } from "../../components/Service";
import { useToasts } from "react-toast-notifications";
import { withStyles } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import AddBox from "@material-ui/icons/AddBox";
import Edit from "@material-ui/icons/Edit";
import { PatchedPagination } from "../../components/PatchedPagination/PatchedPagination";

interface ProductProps {}

export const ProductComponent: React.FC<ProductProps> = ({}) => {
  const { addToast } = useToasts();

  const [products, setProducts] = useState<Array<any>>([]);

  useEffect(() => {
    load().catch((error: any) => {
      addToast("Abbiamo riscontrato un errore durante il caricamento dei prodotti.", { appearance: "error", autoDismiss: false });
    });
  }, []);

  const load = async () => {
    const products: Array<any> = await fetchAllProducts();
    setProducts([...products]);
  };

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleGreenSwitchChanges = (e: any, props: any) => {
    const checked: boolean = e?.target?.checked;
    props.onChange(checked ? 1 : 0);
  };

  return (
    <div className="content">
      <div className="container-fluid container-material-table-height-fix">
        <div className="d-flex justify-content-start mb-4" />
        <MaterialTable
          components={{
            // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
            Pagination: PatchedPagination,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Nessun dato disponibile",
              addTooltip: "Aggiungi",
              deleteTooltip: "Elimina",
              editTooltip: "Modifica",
              filterRow: {
                filterTooltip: "Filtro",
              },
              editRow: {
                deleteText: "Vuoi effettuare la modifica?",
                cancelTooltip: "Annulla",
                saveTooltip: "Salva",
              },
            },
            pagination: {
              labelRowsSelect: "righe",
            },
            header: {
              actions: "",
            },
            toolbar: {
              searchTooltip: "Cerca",
              searchPlaceholder: "Cerca",
            },
          }}
          options={{
            search: true,
            showTitle: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            actionsColumnIndex: -1,
            draggable: false,
            addRowPosition: "first",
          }}
          columns={[
            {
              title: "Nome",
              field: "name",
              initialEditValue: "",
            },
            {
              title: "Descrizione",
              field: "description",
              initialEditValue: "",
              hidden: true,
            },
            {
              title: "Campione",
              field: "sample",
              initialEditValue: 0,
              render: (rowData) => {
                const checked: boolean = Number(rowData?.sample) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    disabled={true}
                    name="sample"
                  />
                );
              },
              editComponent: (props) => {
                const checked: boolean = Number(props?.rowData?.sample) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    onChange={(e) => handleGreenSwitchChanges(e, props)}
                    name="sample"
                  />
                );
              },
            },
            {
              title: "Ordine",
              field: "order",
              initialEditValue: 0,
              render: (rowData) => {
                const checked: boolean = Number(rowData?.order) === 1;
                return (
                  <GreenSwitch checked={checked} disabled={true} name="order" />
                );
              },
              editComponent: (props) => {
                const checked: boolean = Number(props?.rowData?.order) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    onChange={(e) => handleGreenSwitchChanges(e, props)}
                    name="order"
                  />
                );
              },
            },
            {
              title: "Riordine",
              field: "reOrder",
              initialEditValue: 0,
              render: (rowData) => {
                const checked: boolean = Number(rowData?.reOrder) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    disabled={true}
                    name="reOrder"
                  />
                );
              },
              editComponent: (props) => {
                const checked: boolean = Number(props?.rowData?.reOrder) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    onChange={(e) => handleGreenSwitchChanges(e, props)}
                    name="reOrder"
                  />
                );
              },
            },
            {
              title: "Training",
              field: "training",
              initialEditValue: 0,
              render: (rowData) => {
                const checked: boolean = Number(rowData?.training) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    disabled={true}
                    name="training"
                  />
                );
              },
              editComponent: (props) => {
                const checked: boolean = Number(props?.rowData?.training) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    onChange={(e) => handleGreenSwitchChanges(e, props)}
                    name="training"
                  />
                );
              },
            },
            {
              title: "Sell-out",
              field: "sellout",
              initialEditValue: 0,
              render: (rowData) => {
                const checked: boolean = Number(rowData?.sellout) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    disabled={true}
                    name="sellout"
                  />
                );
              },
              editComponent: (props) => {
                const checked: boolean = Number(props?.rowData?.sellout) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    onChange={(e) => handleGreenSwitchChanges(e, props)}
                    name="sellout"
                  />
                );
              },
            },
            {
              title: "Attivo",
              field: "active",
              initialEditValue: 0,
              render: (rowData) => {
                const checked: boolean = Number(rowData?.active) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    disabled={true}
                    name="active"
                  />
                );
              },
              editComponent: (props) => {
                const checked: boolean = Number(props?.rowData?.active) === 1;
                return (
                  <GreenSwitch
                    checked={checked}
                    onChange={(e) => handleGreenSwitchChanges(e, props)}
                    name="active"
                  />
                );
              },
            },
          ]}
          data={products}
          editable={{
            onRowAdd: (newData: any) => {
              return fetchProductAdd(newData)
                .then(async () => {
                  await load();
                  addToast(<><div>Il prodotto <b>{newData.name}</b> è stato inserito correttamente.</div></>, { appearance: "success", autoDismiss: false });
                })
                .catch((error) => {
                  if (error.type === "FORM_VALIDATION") {
                    error?.response?.forEach((e: any) => {
                      addToast(e, { appearance: "error", autoDismiss: false });
                    });
                  } else {
                    addToast(error.message, {
                      appearance: "error",
                      autoDismiss: false,
                    });
                  }
                });
            },
            onRowUpdate: (newData: any, oldData: any) => {
              return fetchProductUpdate(newData.id, newData)
                .then(async () => {
                  await load();
                  addToast(<><div>Il prodotto <b>{newData.name}</b> è stato modificato correttamente.</div></>, { appearance: "success", autoDismiss: false });
                })
                .catch((error) => {
                  if (error.type === "FORM_VALIDATION") {
                    error?.response?.forEach((e: any) => {
                      addToast(e, { appearance: "error", autoDismiss: false });
                    });
                  } else {
                    addToast(error.message, {
                      appearance: "error",
                      autoDismiss: false,
                    });
                  }
                });
            }
          }}
          icons={{
            Add: forwardRef((props, ref) => (
              <AddBox {...props} ref={ref} color="action" />
            )),
            Edit: forwardRef((props, ref) => (
              <Edit {...props} ref={ref} color="action" />
            )),
          }}
          // detailPanel={rowData => {
          //   return (
          //     <div className="content">
          //       <div className="container-fluid">
          //         <div className="card">
          //           <div className="card-body">
          //             <h6 style={{ textTransform: 'none' }}>Description:</h6>
          //             <p>{rowData.description}</p>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   )
          // }}
        />
      </div>
    </div>
  );
};
