import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Navbar } from '../components/Navbar';
import { Sidebar } from '../components/Sidebar';
import PrivateRoute, { EnUserRole } from '../helpers/PrivateRoute/PrivateRoute';
import { AppointmentsTrainingsComponent } from '../pages/AppointmentsTrainings/AppointmentsTrainingsComponent';
import { EditTrainingComponent } from '../pages/AppointmentsTrainings/Trainings/EditTrainingComponent';
import { AddTrainingComponent } from '../pages/AppointmentsTrainings/Trainings/AddTrainingComponent';
import { PharmacyRegistry } from '../pages/PharmaciesRegistry/PharmacyRegistry';
import { AddPharmacy } from '../pages/PharmaciesRegistry/AddPharmacy/AddPharmacy';
import { ModifyPharmacy } from '../pages/PharmaciesRegistry/ModifyPharmacy/ModifyPharmacy';
import { PharmacyEmployees } from '../pages/PharmaciesRegistry/PharmacyEmployees/PharmacyEmployees';
import { StockManagement } from '../pages/StockManagement/StockManagement';
import { LoginPage } from '../pages/Login/LoginPage';
import {AddAppointmentComponent} from '../pages/AppointmentsTrainings/Appointments/AddAppointmentComponent';
import {EditAppointmentComponent} from '../pages/AppointmentsTrainings/Appointments/EditAppointmentComponent';
import {DetailAppointmentComponent} from '../pages/AppointmentsTrainings/Appointments/DetailAppointmentComponent';
import { Report } from "../pages/Report/Report";
import {HomeComponent} from '../pages/Home/HomeComponent';
import {ProductManagementComponent} from '../pages/ProductManagement/ProductManagementComponent';
import {DocumentsEmployees} from "../pages/PharmaciesRegistry/DocumentsEmployees/DocumentsEmployees";

interface Props {
}

const routes: React.FC<Props> = () => {
    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route path="/login" exact component={LoginPage} />
            <Route component={(props: any) => DefaultContainer(props)} />
        </Switch>
    );
}

const DefaultContainer = (props: any) => (
    <div className="wrapper">
        <Sidebar {...props} />
        <div className="main-panel main-content">
            <Navbar {...props} />
            <Switch>
                <PrivateRoute path="/dashboard" exact component={HomeComponent} />

                {/* PHARMACY ROUTES */}
                <PrivateRoute path="/pharmacy-registry" exact component={PharmacyRegistry} />
                <PrivateRoute path="/pharmacy-registry/add" exact component={AddPharmacy} />
                <PrivateRoute path="/pharmacy-registry/:id" component={ModifyPharmacy} exact />
                <PrivateRoute path="/pharmacy-registry/:id/employees" component={PharmacyEmployees} exact />
                <PrivateRoute path="/pharmacy-registry/:id/employees/:idEmp/documents" component={DocumentsEmployees} exact/>

                {/* VIEW APPOINTMENT AND TRAINING */}
                <PrivateRoute exact path="/appointments-and-training" component={AppointmentsTrainingsComponent} />

                {/* ADD/EDIT APPOINTMENT */}
                <PrivateRoute path="/appointments-and-training/appointments" exact component={AddAppointmentComponent} />
                <PrivateRoute path="/appointments-and-training/appointments/:id/detail" exact component={DetailAppointmentComponent} />
                <PrivateRoute path="/appointments-and-training/appointments/:id/edit" exact component={EditAppointmentComponent} />

                {/* ADD/EDIT TRAINING */}
                <PrivateRoute path="/appointments-and-training/trainings" exact component={AddTrainingComponent} />
                <PrivateRoute path="/appointments-and-training/trainings/:id/edit" exact component={EditTrainingComponent} />

                {/* PRODUCT MANAGEMENT */}
                <PrivateRoute path="/product-management" exact>
                    <Route roles={[EnUserRole.ADMIN]} component={ProductManagementComponent} />
                </PrivateRoute>

                {/* STOCK MANAGEMENT */}
                <PrivateRoute path="/stock-management" exact={false}>
                    <Route roles={[EnUserRole.PM, EnUserRole.ADMIN, EnUserRole.AGENT]} component={StockManagement} />
                </PrivateRoute>

                {/* REPORTS */}
                <PrivateRoute path="/report" component={Report} />
            </Switch>
            {/* <Footer /> */}
        </div>
    </div>
)

export default routes;
