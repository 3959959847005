import React, {useEffect, useState} from 'react';
import {fetchAllPharmacies} from './Service';
import {Link, useHistory} from 'react-router-dom';
import {EnUserRole} from '../../helpers/PrivateRoute/PrivateRoute';
import {fetchAllAgents} from '../StockManagement/Service';
import {IPharmacy} from '../../interfaces/IPharmacy';
import useAuth from '../../hooks/useAuth';
import Select from 'react-select';
import './Style.css'

interface PharmacyRegistryProps {
}

export const PharmacyRegistry: React.FC<PharmacyRegistryProps> = (props) => {
    const history = useHistory();
    const {user} = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [pharmacyArray, setPharmacyArray] = useState<Array<IPharmacy>>([]);
    const [pharmacySpecialists, setPharmacySpecialists] = useState<Array<any>>([]);
    const [pharmacySpecialist, setPharmacySpecialist] = useState<any>();

    useEffect(() => {
        load().catch(error => console.error(error));
    }, []);

    const load = async (agentId?: number) => {
        setLoading(true);

        let pharmacies: Array<IPharmacy> = await fetchAllPharmacies() as Array<IPharmacy>;
        if (agentId) {
            pharmacies = pharmacies.filter(pharmacy => pharmacy.agentId === agentId);
        }
        setPharmacyArray(pharmacies);

        const pharmacySpecialists = await fetchAllAgents() as Array<any>;
        pharmacySpecialists.forEach(pharmacySpecialist => {
            pharmacySpecialist.label = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
            pharmacySpecialist.value = `${pharmacySpecialist.firstName} ${pharmacySpecialist.lastName}`;
        });
        setPharmacySpecialists(pharmacySpecialists);

        setLoading(false);
    }

    const [searchParam, setSearchParam] = useState('');

    const handleOnChangeInputSearch = (e: any) => {
        const value: string = e?.target?.value;
        setSearchParam(value)
    }

    const filterByAgent = async (pharmacySpecialist: any) => {
        setPharmacySpecialist(pharmacySpecialist);
        !!pharmacySpecialist ? await load(pharmacySpecialist.id) : await load();
    }

    const navigateToEditPage = (item: any) => {
        if (user.role === EnUserRole.AGENT && item.active === 0) {
            return;
        }
        const path: string = `/pharmacy-registry/${item.id}`;
        history.push(path);
    }

    const navigateToEmployeesPage = (item: any) => {
        if (user.role === EnUserRole.AGENT && item.active === 0) {
            return;
        }
        const path: string = `/pharmacy-registry/${item.id}/employees`;
        history.push(path);
    }

    const filteredPharmacy = pharmacyArray.filter((item: any) => {
        return item.name.toLowerCase().indexOf(searchParam.toLowerCase()) != -1;
    });

    return (
        <div id="pharmacy-registry" className="content">
            <div className="common-sticky-bar d-flex flex-row justify-content-between align-items-center mb-4">
                <input type="text"
                       id="search"
                       name="search"
                       className={user.role !== EnUserRole.AGENT ? 'smaller' : ''}
                       onChange={handleOnChangeInputSearch}
                       placeholder="Ricerca rapida"/>
                {
                    user.role !== EnUserRole.AGENT &&
                    <Link to="/pharmacy-registry/add">
                        <button type="button" className="btn common-btn">
                            <i className="fa fa-plus mr-2" aria-hidden="true"/>
                            Aggiungi
                        </button>
                    </Link>
                }
            </div>

            {
                user.role !== EnUserRole.AGENT &&
                <div className="d-flex flex-row flex-wrap justify-content-start align-items-center my-4">
                    <form className="m-2" style={{width: '100%'}}>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-lg-6 mb-2">
                                <label className="common-form-label" htmlFor="pharmacy-specialist">Pharmacy Specialist</label>
                                <Select
                                    isClearable={true}
                                    className="basic-single"
                                    classNamePrefix="custom-dropdown"
                                    placeholder=""
                                    name="pharmacy-specialist"
                                    options={pharmacySpecialists}
                                    value={pharmacySpecialist}
                                    onChange={(pharmacySpecialist) => filterByAgent(pharmacySpecialist)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            }

            {
                !loading ?
                    <>
                        {
                            filteredPharmacy.length !== 0 ?
                                <div id="pharmacies" className="d-flex flex-row flex-wrap justify-content-start align-items-center">
                                    {
                                        filteredPharmacy.map((item: any) => (
                                            <div key={item.id}
                                                 className={item.active === 1 ? "card custom-card m-2 animate__animated animate__fadeIn" : "card custom-card m-2 animate__animated animate__fadeIn disabled"}
                                                 style={{
                                                     width: '18.5rem',
                                                     height: '10rem',
                                                     border: (item.active === 1) ? '5px solid #c5c4c4' : '5px solid #c5c4c4',
                                                     minHeight: '200px'
                                                 }}>
                                                <div className="card-body"
                                                     onClick={() => navigateToEditPage(item)}>
                                                    <h5 className="card-title mb-1">{item.name}</h5>
                                                    <p className="mb-2 mt-1 card-text">{item.address}</p>
                                                    <ul className="m-0 p-0" style={{listStyle: 'none'}}>
                                                        <li><span
                                                            className="text-muted mr-1">Citta:</span> {item.city}
                                                        </li>
                                                        <li><span
                                                            className="text-muted mr-1">Provincia:</span> {item.province}
                                                        </li>
                                                        <li><span
                                                            className="text-muted mr-1">Regione:</span> {item.region}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card-footer">
                                                    <div
                                                        style={{zIndex: 1}}
                                                        className="d-flex flex-row justify-content-end align-items-center w-100"
                                                        onClick={() => navigateToEmployeesPage(item)}>
                                                        <i className="fa fa-users fa-lg" aria-hidden="true"/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div
                                    className="d-flex flex-row flex-wrap justify-content-center align-items-center mb-2">
                                    Farmacia non trovata
                                </div>
                        }
                    </>
                    :
                    <div className="d-flex flex-row justify-content-center align-items-center p-4">
                        <div className="spinner-wrapper mr-2">
                            <div className="spinner-border spinner-border-md" role="status">
                                <span className="sr-only text-light">Loading...</span>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
