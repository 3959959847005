export const fetchPharmacies = () => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/pharmacies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const fetchPharmacyProducts = (pharmacyId: number) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/pharmacies/stock/${pharmacyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const fetchPharmacyProductsAudits = (pharmacyId: number, productId: number) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/pharmacies/stock/${pharmacyId}/audit/product/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const fetchUpdatePharmacyStock = (pharmacyId: number, stocks: Array<any>) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/pharmacies/stock/${pharmacyId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      },
      body: JSON.stringify(stocks)
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const fetchGeneralOrdersPerYear = (pharmacyId: any, year: any) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/pharmacies/stock/${pharmacyId}/${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('Token')}`
      }
    })

        .then(async (response) => {
          if (response.status === 200 || response.status === 201) return resolve((await response.json()));
          reject((await response.json()));
        })
        .catch((error) => {
          reject(error);
        })
  });
};
