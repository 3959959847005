import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import './Style.css';
import {useHistory} from "react-router-dom";
import logo from '../../images/LOGO_NHCO.png';
import logo_light from '../../images/LOGO_NHCO_LIGHT.png';
import {login} from './Service';
import useAuth from '../../hooks/useAuth';

interface LoginPageProps {

}

export const LoginPage: React.FC<LoginPageProps> = ({}) => {
    let history = useHistory();
    const {user, saveUser} = useAuth();
    const [userObj, setUser] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
    }, [user]);

    const [authError, setAuthError] = useState({
        error: false,
        errorMsg: ''
    });
    const [submitted, setSubmitted] = useState(false);

    const handleLogin = (e: any) => {
        e.preventDefault();
        setSubmitted(true);
        setAuthError({...authError, error: false, errorMsg: ''});
        if (userObj.email !== '' && userObj.password !== '') {
            const obj = {
                email: userObj.email,
                password: userObj.password
            }
            login(obj).then((response: any) => {
                setSubmitted(false);
                localStorage.setItem('Token', response.token)
                const _user: any = jwt_decode(response.token);
                localStorage.setItem('User', JSON.stringify(_user));
                saveUser({..._user, ...{loggedIn: true}});
                history.push('/dashboard');
            }).catch((error: any) => {
                setSubmitted(false);
                setAuthError({...authError, error: true, errorMsg: error.message});
            });
        } else {
            setSubmitted(false);
            if (userObj.email === '') {
                setAuthError({...authError, error: true, errorMsg: "Perfavore, inserisci l'email"});
            } else if (userObj.password === '') {
                setAuthError({...authError, error: true, errorMsg: 'Perfavore, inserisci la password'});
            } else {
                setAuthError({...authError, error: true, errorMsg: 'Errore durante il tentativo di accesso.'});
            }
            return
        }
    }

    const handleInputChange = (e: any) => {
        if (e.target.name === 'email') setUser({...userObj, email: e.target.value});
        else setUser({...userObj, password: e.target.value});
    }

    return (
        <>
            <div id="login-page">
                <div className="d-flex flex-row justify-content-start align-content-center d-block d-lg-none p-2"
                     style={{backgroundColor: "black"}}>
                    <a className="navbar-brand" href="/">
                        <img src={logo_light} width="50" height="35" className="d-inline-block align-top" alt=""/>
                    </a>
                </div>
                <div className="row" style={{height: '90%'}}>
                    <div className="col-6 d-none d-lg-block common-bg-nhco">
                        <div className="d-flex flex-row justify-content-center align-items-center h-100 w-100">
                            <img src={logo} alt="NHCO Nutrition" style={{width: '250px', height: '250px'}} />
                        </div>
                    </div>
                    <div className="col col-lg-6">

                        <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">

                            <h3 className="mb-2">NHCO NUTRITION</h3>
                            <p className="mb-4 text-muted">Ben tornato! Effettua il login al tuo account.</p>

                            <form name="login" onSubmit={(e) => handleLogin(e)}>
                                <div className="form-group mb-4">
                                    <input type="email" name="email" id="email" className="login-input"
                                           placeholder="Email" value={userObj.email}
                                           onChange={(e) => handleInputChange(e)}/>
                                </div>
                                <div className="form-group mb-4">
                                    <input type="password" name="password" className="login-input"
                                           id="password" placeholder="Password" value={userObj.password}
                                           onChange={(e) => handleInputChange(e)}/>
                                </div>

                                <div className="d-flex flex-row justify-content-center align-items-center mb-2">
                                    <button type="submit" className="btn common-btn login-btn">
                                        {submitted && <i className="fa fa-spinner fa-spin" style={{marginRight: "5px"}}/>} Login
                                    </button>
                                </div>

                                <div className="d-flex flex-row justify-content-center align-items-center px-5" style={{height: '25px'}}>
                                    {authError?.errorMsg && <label className="text-danger animate__animated animate__bounceIn">{authError?.errorMsg}</label> }
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row"  style={{height: '10%', backgroundColor: 'black'}}/>
            </div>
        </>
    );
}
