import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/pages/App';
import * as serviceWorker from './serviceWorker';
import {ToastProvider} from 'react-toast-notifications';
import {AuthContextProvider, getAuthDetails} from './app/contexts/authContext';
import './index.scss';

ReactDOM.render(
    <ToastProvider>
        <AuthContextProvider settings={getAuthDetails()}>
            <App/>
        </AuthContextProvider>
    </ToastProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
