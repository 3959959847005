import {IPharmacy} from '../interfaces/IPharmacy';
import {INegativeResult} from '../interfaces/INegativeResult';

// API Navbar.tsx component
export const fetchNavbarLogout = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/auth/logout`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        })

            .then(async (response) => {
                resolve((await response));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// API ProductComponent.tsx component
export const fetchProductAdd = (data: any) => {

    return new Promise((resolve, reject) => {
        fetch(`/api/v1/products`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(data)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchProductUpdate = (id: any, data: any) => {

    return new Promise((resolve, reject) => {
        fetch(`/api/v1/products/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(data)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// API PharmacySelect.tsx component
export const fetchPharmacySelectOptions = (): Promise<Array<IPharmacy>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// API NegativeResult.tsx component
export const fetchNegativeResultSelectOptions = (): Promise<Array<INegativeResult>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/appointments/negative/reasons`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};
