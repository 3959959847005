import React, { useEffect } from 'react';
import './style.scss';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { StockPharmacySpecialist } from './StockPharmacySpecialist/StockPharmacySpecialist';
import { StockPharmacy } from './StockPharmacy/StockPharmacy';

interface StockManagementProps {
}

export const StockManagement: React.FC<StockManagementProps> = () => {
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        if (!location.pathname.includes('pharmacy-specialist') && !location.pathname.includes('pharmacies')) {
            history.push('/stock-management/pharmacy-specialist');
        }
    }, [location.pathname]);

    return (
        <div className="content">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={location.pathname.includes('pharmacy-specialist') ? 'nav-link nav-link-custom custom-active' : 'nav-link nav-link-custom '}
                        onClick={() => history.push('/stock-management/pharmacy-specialist')}>Stock Campioni</a>
                </li>
                <li className="nav-item">
                    <a className={location.pathname.includes('pharmacies') ? 'nav-link nav-link-custom custom-active' : 'nav-link nav-link-custom '}
                        onClick={() => history.push('/stock-management/pharmacies')}>Stock Farmacie</a>
                </li>
            </ul>
            <br />
            {
                location.pathname.includes('pharmacy-specialist') && <StockPharmacySpecialist />
            }
            {
                location.pathname.includes('pharmacies') && <StockPharmacy />
            }
        </div>
    );
}
