import {IPharmacy} from '../../interfaces/IPharmacy';

export const fetchAllPharmacies = (): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchPharmacyById = (id: any): Promise<IPharmacy> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchAddPharmacy = (pharmacy: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(pharmacy)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchUpdatePharmacy = (id: number, pharmacy: IPharmacy) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(pharmacy)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchUpdatePharmacyByAgent = (agentId: number, pharmacyId: number, partial: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/users/agents/${agentId}/pharmacies/${pharmacyId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(partial)
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// Pharmacy Employee

export const fetchAllPharmacyEmployees = (id: number) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/employees`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchAddPharmacyEmployee = (id: number, data: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/employees`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(data)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchUpdatePharmacyEmployee = (pharmacyId: number, employeeId: number, data: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${pharmacyId}/employees/${employeeId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(data)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// Pharmacy Cycle

export const fetchPharmacyCycleDetails = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/cycle/details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchPharmacyCycleDetailsByPharmacyId = (id: number) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/cycle/details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    return resolve((await response.json()));
                }
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};


export const fetchAllDocumentAvailableEmployees = (id: number,employeeId: number) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/employees/${employeeId}/documents`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchDocument = (id: number,employeeId: number, documentId: number | undefined) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/employees/${employeeId}/documents/${documentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.text()));
                reject((await response.json()));
            })
            .catch((error) => {
                console.log('[error]', error)
                reject(error);
            })
    });
};

export const fetchDocumentFile = (id: number,employeeId: number, documentId: number | undefined) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/employees/${employeeId}/files/${documentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.text()));
                reject((await response.json()));
            })
            .catch((error) => {
                console.log('[error]', error)
                reject(error);
            })
    });
};


export const uploadDocument = (id: number,employeeId: number, documentId: number | undefined, file: any, email: string | null, metadati: any[]) => {
    return new Promise((resolve, reject) => {

        const formData     = new FormData();
        formData.append( 'file', file );

        if(email !== null){
            formData.append( 'email', email );
        }

        formData.append( 'statements', JSON.stringify(metadati) );

        console.log('statements', JSON.stringify(metadati))

        fetch(`/api/v1/pharmacies/${id}/employees/${employeeId}/documents/${documentId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: formData
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.text()));
                reject((await response.json()));
            })
            .catch((error) => {
                console.log('[error]', error)
                reject(error);
            })
    });
};
