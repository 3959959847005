import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';

interface ProductStockAuditProps {
    pharmacyId: number;
    productId: number;
    rowData: any,
    year: number,
    data: {year: number, month: number},
    handleCurrentYear: (month: number, currentStock?: number | null, previousStock?: number | null, orderQuantity?: number | null, reorderQuantity?: number | null, selloutQuantity?: number | null, isNotAvailable?: boolean) => JSX.Element,
    handlePreviousYear: (month: number, currentStock?: number | null, previousStock?: number | null, orderQuantity?: number | null, reorderQuantity?: number | null, selloutQuantity?: number | null, isNotAvailable?: boolean) => JSX.Element,
    handleColor: (value: number) => string
}

export const StockPharmacyAudit: React.FC<ProductStockAuditProps> = (props: ProductStockAuditProps) => {
    const {rowData, year, data, handleCurrentYear, handlePreviousYear} = props;

    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState<any[]>([]);
    const [detailYear, setDetailYear] = useState(new Date().getFullYear());
    const {addToast} = useToasts();

    // useEffect(() => {
    //     if (!props || entries.length !== 0) {
    //         return;
    //     }
    //     setLoading(true);
    //     load(props)
    //         .then(() => setLoading(false))
    //         .catch((e) => {
    //             addToast(e.message, { appearance: 'error', autoDismiss: true });
    //             setLoading(false);
    //         });
    // }, [props]);

    // const load = async (props: ProductStockAuditProps) => {
    //     const entries: Array<any> = await fetchPharmacyProductsAudits(props.pharmacyId, props.productId) as Array<any>;
    //     setEntries(entries);
    // }

    const formatTime = (date: any) => {
        return new Date(date).toLocaleString('it-IT');
    }

    const isAuditEntriesEmpty = (auditEntries: Array<any>) => {
        return auditEntries?.length === 0 || auditEntries?.filter(entry => ((entry.currentStock - entry.previousStock) !== 0))?.length === 0;
    }

    const handleActionIcon = (entry: any): any => {
        switch (entry.action) {
            case 1:
                // ORDER
                return <FontAwesomeIcon icon={faPlus} className="text-success"/>;
            case 2:
                // RE-ORDER
                return <FontAwesomeIcon icon={faPlus} className="text-success"/>;
            case 3:
                // SELL-OUT-CHECK
                return <FontAwesomeIcon icon={faMinus} className="text-danger"/>;
            default:
                // ADMIN EDIT
                const difference = (entry.currentStock - entry.previousStock);
                return (Math.sign(difference) === -1) ?
                    <FontAwesomeIcon icon={faMinus} className="text-danger"/>
                    :
                    <FontAwesomeIcon icon={faPlus} className="text-success"/>;
        }
    }

    const isCurrentMonth = (index: number): boolean => {
        return (year === data.year && index === data.month);
    }

    return (
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">

                    {
                        !loading ?
                            <>
                                <div className="d-flex justify-content-start">
                                    <label style={{color: 'black', fontSize: '18px'}}>{rowData?.productName}</label>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr style={{height: '2rem'}}>
                                            <th scope="col" style={{fontWeight: 'bold', fontSize: '0.8rem'}}/>
                                            {
                                                ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'].map((month, index) => (
                                                    <th scope="col" key={index} style={{
                                                        opacity: isCurrentMonth(index + 1) ? '1' : '0.5',
                                                        fontWeight: 'bold',
                                                        fontSize: '.8rem'
                                                    }}>{month}</th>
                                                ))
                                            }
                                            <th scope="col" style={{
                                                fontWeight: 'bold',
                                                fontSize: '1rem',
                                                textAlign: 'center'
                                            }}>Totale</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><b>Stock Precedente</b></td>
                                            {
                                                rowData.months.map((stock: IStock, index: number) => {
                                                    return <td key={index}>{(year == data.year) ?
                                                        handleCurrentYear(stock.month, null, stock.previousStock, null, null, null, stock.isNotAvailable) :
                                                        handlePreviousYear(stock.month, null, stock.previousStock, null, null, null, stock.isNotAvailable)}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td>Ordine</td>
                                            {
                                                rowData.months.map((stock: IStock, index: number) => {
                                                    return <td key={index}>{(year == data.year) ?
                                                        handleCurrentYear(stock.month, null, null, stock.orderQuantity, null, null, stock.isNotAvailable) :
                                                        handlePreviousYear(stock.month, null, null, stock.orderQuantity, null, null, stock.isNotAvailable)}</td>
                                                })
                                            }
                                            <td style={{textAlign: 'center'}}>
                                                {
                                                    rowData.months.reduce((a: any, b: any) => a + (b.orderQuantity), 0)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Riordine</td>
                                            {
                                                rowData.months.map((stock: IStock, index: number) => {
                                                    return <td key={index}>{(year == data.year) ?
                                                        handleCurrentYear(stock.month, null, null, null, stock.reorderQuantity, null, stock.isNotAvailable) :
                                                        handlePreviousYear(stock.month, null, null, null, stock.reorderQuantity, null, stock.isNotAvailable)}</td>
                                                })
                                            }
                                            <td style={{textAlign: 'center'}}>
                                                {
                                                    rowData.months.reduce((a: any, b: any) => a + (b.reorderQuantity), 0)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Verifica Sellout</td>
                                            {
                                                rowData.months.map((stock: IStock, index: number) => {
                                                    return <td key={index}>{(year == data.year) ?
                                                        handleCurrentYear(stock.month, null, null, null, null, stock.selloutQuantity, stock.isNotAvailable) :
                                                        handlePreviousYear(stock.month, null, null, null, null, stock.selloutQuantity, stock.isNotAvailable)}</td>
                                                })
                                            }
                                            <td style={{textAlign: 'center'}}>
                                                {
                                                    rowData.months.reduce((a: any, b: any) => a + (b.selloutQuantity), 0)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Stock Attuale</b></td>
                                            {
                                                rowData.months.map((stock: IStock, index: number) => {
                                                    return <td key={index}>{(year == data.year) ?
                                                        handleCurrentYear(stock.month, stock.currentStock, null, null, null, null, stock.isNotAvailable) :
                                                        handlePreviousYear(stock.month, stock.currentStock, null, null, null, null, stock.isNotAvailable)}</td>
                                                })
                                            }
                                            <td/>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            :
                            <div className="d-flex flex-row justify-content-center align-items-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Caricamento....</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

interface IStock {
    month: number;
    previousStock: number;
    orderQuantity: number;
    reorderQuantity: number;
    selloutQuantity: number;
    currentStock: number;
    isNotAvailable: boolean;
    isVerified: boolean;
}
