// APPOINTMENTS

export const fetchAllAppointments = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/appointments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchAppointmentById = (id: number): Promise<any> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/appointments/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchAppointmentsByPharmacyId = (id: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/appointments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchCreateAppointment = (obj: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/appointments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(obj)
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchUpdateAppointment = (id: number, obj: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/appointments/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(obj)
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// TRAININGS

export const fetchAllTrainingAppointments = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchAllTrainingAppointmentsByPharmacyId = (pharmacyId: number): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments/pharmacies/${pharmacyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchTrainingAppointmentById = (id: number) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchTrainingAppointmentTrainableByPharmacyId = (id: number) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments/pharmacies/${id}/trainable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchSaveTrainingAppointment = (obj: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(obj)
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchUpdateTrainingAppointment = (id: number, obj: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(obj)
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchUpdatedTrainingAppointmentPharmacyCycle = (pharmacyId: number, appointmentId: number, obj: any) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments/pharmacies/${pharmacyId}/cycle/${appointmentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            body: JSON.stringify(obj)
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    return resolve((await response.json()));
                }
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

// OTHERS

export const fetchAllPharmacies = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchAllProducts = (): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/products`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchAllPharmacyOrderableProductsByPharmacyId = (id: number): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/products/orderable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export const fetchAllPharmacyReorderableProductsByPharmacyId = (id: number): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/products/reorderable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export const fetchTrainableProductsByPharmacyId = (id: number, detailed: boolean = false) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/${id}/products/trainable?detailed=${detailed}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchPharmacyStockByPharmacyId = (pharmacy_id: any): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/stock/${pharmacy_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchTrainablePharmacyIds = (): Promise<Array<number>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/trainable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchProductSampleStockByAgentId = (id: any): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/product-samples/stock/agent/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchGeneralOrdersPerYear = (pharmacyId: number, year: number): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/pharmacies/stock/${pharmacyId}/${year}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })

            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};
