import React, {useEffect} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Routes from '../routes/routes';
import fetchIntercept from 'fetch-intercept';
import useAuth from '../hooks/useAuth';
import './App.css';

const customHistory = createBrowserHistory();

function App() {
    const {logout} = useAuth();

    useEffect(() => {
        const unregister = fetchIntercept.register({
            request: function (url, config) {
                // Add Common Fetch Headers Here
                return [url, config];
            },

            requestError: function (error) {
                return Promise.reject(error);
            },

            response: function (response) {
                if (response.status === 403 || response.status === 401) {
                    if (!response.url.includes('login')) logout();
                }
                // Modify the response object
                return response;
            },

            responseError: function (error) {
                if (error?.status && (error.status === 403 || error.status === 401)) {
                    logout();
                }
                return Promise.reject(error);
            }
        });
        return () => {
            unregister();
        };
    }, []);

    return (
        <>
            <Router history={customHistory}>
                <Routes/>
            </Router>
        </>
    );
}

export default App;
