import React from 'react';
import './CommonStyles.css';
import UserImagePlaceholder from '../images/user-image-placeholder.png'
import useAuth from '../hooks/useAuth';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { fetchNavbarLogout } from './Service';
import EXIT from "../images/exit.png";

interface NavbarProps {
}

export const Navbar: React.FC<NavbarProps> = (props: any) => {
    const { user, logout } = useAuth();
    const { location } = props;
    let currentPage;

    if (location.pathname === ("/dashboard/product")) currentPage = "ProductComponent Management";
    else if (location.pathname.includes("/pharmacy-registry")) currentPage = "Registro Farmacie";
    else if (location.pathname.includes("/appointments-and-training")) currentPage = "Appuntamenti e Training";
    else if (location.pathname.includes("/user-management")) currentPage = "User Management";
    else if (location.pathname.includes("/product-management")) currentPage = "ProductComponent Management";
    else if (location.pathname.includes("/stock-management")) currentPage = "Stock Management";
    else if (location.pathname.includes("/report")) currentPage = "Report";
    else currentPage = "";

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleLogout = async () => {
        await fetchNavbarLogout();
        logout();
    }

    return (
        <nav id="navbar" className="navbar navbar-expand-lg navbar-absolute fixed-top common-nav">
            <div className="container-fluid">
                <div className="navbar-wrapper">
                    <h4 style={{ fontWeight: 'bold' }}>{currentPage}</h4>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapseExample"
                    aria-controls="navigation-index" aria-expanded="false" aria-label="ToggleComponent navigation">
                    <span className="sr-only">ToggleComponent navigation</span>
                    <span className="navbar-toggler-icon icon-bar" />
                    <span className="navbar-toggler-icon icon-bar" />
                    <span className="navbar-toggler-icon icon-bar" />
                </button>
                <div id="collapseExample" className="collapse navbar-collapse justify-content-end">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <label className="text-muted">{user?.firstName} {user?.lastName}</label>
                        </li>
                        <li className="nav-item dropdown">
                            <div>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    <img className="ml-2" style={{ width: '30px' }} src={UserImagePlaceholder} alt="Logo" />
                                </Button>
                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
                                    disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={open} id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}>
                                                        <MenuItem onClick={handleLogout}>Esci</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
