import { IReports } from '../../interfaces/IReports';

export const retrievesReportsFor = (role: string, agentId?: number): Promise<IReports> => {
    return new Promise((resolve, reject) => {
        const url: string = agentId ? `/api/v1/reports/${role}?agentId=${agentId}` : `/api/v1/reports/${role}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const pharmacyListFilteredByAgent = (id: number) => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/users/agents/${id}/pharmacies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const fetchAllAppointments = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/appointments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};

export const fetchAllTrainings = () => {
    return new Promise((resolve, reject) => {
        fetch(`/api/v1/training-appointments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) return resolve((await response.json()));
                reject((await response.json()));
            })
            .catch((error) => {
                reject(error);
            })
    });

};
