import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import './Style.css';

registerLocale('it', it);

interface DatePickerProps {
    label?: string | undefined,
    dateType: string,
    setDate: any,
    currentDate: any,
    dateFormat: string,
    showTimeSelect: boolean,
    timeFormat?: string,
    timeIntervals?: number,
    timeCaption?: string,
    popperModifiers?: any,
    disabled?: boolean,
    readonly?: boolean,
    minDate?: Date | null,
    maxDate?: Date | null,
}

export const DatePickerComponent: React.FC<DatePickerProps> = ({
    label,
    currentDate,
    setDate,
    dateType,
    dateFormat,
    showTimeSelect,
    timeFormat,
    timeIntervals,
    timeCaption,
    popperModifiers,
    disabled = false,
    readonly,
    minDate,
    maxDate
}) => {
    return (
        <>
            {label && <label className="common-form-label">{label}</label>}
            <DatePicker
                locale="it"
                className="form-control common-form-input"
                placeholderText="Seleziona la data"
                selected={currentDate}
                onChange={date => setDate(date, dateType)}
                dateFormat={dateFormat}
                showTimeSelect={showTimeSelect}
                timeIntervals={timeIntervals}
                timeCaption={timeCaption}
                disabled={disabled}
                readOnly={readonly}
                popperModifiers={popperModifiers}
                minDate={minDate}
                maxDate={maxDate}
            />
        </>
    );
}

DatePickerComponent.defaultProps = {
    dateFormat: 'dd/MM/yyyy',
    showTimeSelect: false,
    timeFormat: "HH:mm",
    timeIntervals: 15,
    timeCaption: "Orario",
    readonly: false,
    disabled: false,
    minDate: null,
    maxDate: null
}
