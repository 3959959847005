// API to Login a user
export const login = (user: any) => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })

      .then(async (response) => {
        if (response.status === 200 || response.status === 201) return resolve((await response.json()));
        reject((await response.json()));
      })
      .catch((error) => {
        reject(error);
      })
  });
};
