export const PositiveResultLabels: any = {
    ORDER: 'Ordine',
    RE_ORDER: 'Riordine',
    VISIT: 'Visita',
    SELL_OUT_CHECK: 'Verifica sell-out',
    ALLESTIMENTO: 'Allestimento',
    MAIL_REMINDER: 'Mail Reminder',
    CATENE_E_GRUPPI: 'Catene e Gruppi',
    NEGATIVO: 'Negativo',
    COACHING: 'Attività di Coaching'
}

export const ContactTypeLabels: any = {
    PHONE: 'Telefonico',
    IN_PERSON: 'Fisico',
    MAIL: 'Mail',
}

export const OutcomeLabels: any = {
    POSITIVE: 'Positivo',
    NEGATIVE: 'Negativo',
    MAIL_REMINDER: 'Mail Reminder'
}

export const TrainingTypeLabels: any = {
    IN_PERSON: 'Di Persona',
    REMOTE: 'Da Remoto'
}

export const PharmacyStatusLabels: any = {
    CLOSED: 'Chiusa',
    OPEN: 'Aperta'
}

export const CoachingTypeLabels: any = {
    ALL: 'Tutto',
    MORNING: 'Mattina',
    AFTERNOON: 'Pomeriggio'
}

export enum EnAppointmentStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED'
}

export enum EnAppointmentPharmacyStatus {
    NORMAL = 'NORMAL',
    ELIMINATA = 'ELIMINATA',
    RICONTATTARE = 'RICONTATTARE',
    CATENE_E_GRUPPI = 'CATENE_E_GRUPPI',
}

export enum EnPharmacyStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED'
}

export enum EnTrainingSessionType {
    A_PERSON = 'A PERSON',
    TWO_PEOPLE = 'TWO PEOPLE',
    THREE_PEOPLE = 'THREE PEOPLE',
    GROUP = 'GROUP',
    FULL_STAFF = 'FULL STAFF'
}

export enum EnTrainingType {
    IN_PERSON = 'IN_PERSON',
    REMOTE = 'REMOTE',
}

export enum EnContactType {
    PHONE = 'Telefonico',
    IN_PERSON = 'Fisico',
    MAIL = 'Mail'
}

export enum EnOutCome {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    MAIL_REMINDER = 'MAIL_REMINDER'
}

export enum General {
    ADD_NAME_SURNAME = 'Perfavore specifica Nome e Cognome',
    NO_SAMPLE_ORDERS = 'Perfavore compila la tabella dei CAMPIONI',
    NO_ORDERS = 'Perfavore compila la tabella ORDINE',
    NO_REORDERS = 'Perfavore compila la tabella dei RIORDINE',
    NO_SELLOUTS = 'Perfavore compila la tabella dei SELL-OUT',
}

export enum EnAppointmentPositiveResult {
    ORDER = 'ORDER',
    RE_ORDER = 'RE_ORDER',
    VISIT = 'VISIT',
    SELL_OUT_CHECK = 'SELL_OUT_CHECK',
    ALLESTIMENTO = 'ALLESTIMENTO',
    MAIL_REMINDER = 'MAIL_REMINDER',
    CATENE_E_GRUPPI = 'CATENE_E_GRUPPI',
    DELETE = 'DELETE',
    COACHING = 'COACHING',
}

export enum EnAppointmentCoachingType {
    ALL = 'ALL',
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
}
