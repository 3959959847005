import React, {Component} from 'react';
import {Redirect, Route} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export enum EnUserRole {
    AGENT = 'AGENT',
    PM = 'PM',
    ADMIN = 'ADMIN'
}

interface PrivateRouteProps {
    path: string,
    exact?: boolean,
    component?: any,
    roleComponents?: Array<any>,
    children?: any
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({children, ...allProps}) => {
    const {user, saveUser} = useAuth();

    const getComponent = () => {

        if (Array.isArray(children)) {
            for (const child of children) {
                if (child.props.roles.includes(user.role)) {
                    return child;
                }
            }
        } else if (children) {
            if (children.props.roles.includes(user.role)) {
                return children;
            }
        }
        return <Redirect to={{pathname: '/'}}/>;
    };

    return (
        <>
            {(!user.loggedIn) ? <Redirect to={{pathname: '/login'}}/> :
                ((allProps.component) ? <Route {...allProps} render={props => <Component {...props} />}/> :
                    getComponent())}
        </>
    );
}

export default PrivateRoute;
