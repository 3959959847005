import React, { ChangeEvent, useEffect, useState } from "react";
import { TrainingProductComponent } from "../TrainingProduct/TrainingProductComponent";

interface EditTrainingEmployeeProps {
  id: string;
  appointmentId: number;
  employee: any;
  duration?: number;
  products: Array<any>;
  details: any;
  lookup: boolean;
  loading: boolean;
  handleTrainingEmployeeData: (
    employeeId: number,
    duration: number | undefined,
    productIds: Array<number>
  ) => void;
}

export const TrainingEmployeeComponent: React.FC<EditTrainingEmployeeProps> = ({
  id,
  appointmentId,
  employee,
  duration,
  products = [],
  details = [],
  lookup,
  loading,
  handleTrainingEmployeeData,
}) => {
  const [productIds, setProductIds] = useState<Array<number>>([]);
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (duration && Number.isInteger(duration)) {
      setValue(duration);
    }
  }, [duration]);

  useEffect(() => {
    if (Array.isArray(products) && products.length > 0) {
      products.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
  }, [products]);

  useEffect(
    () => {},
    [productIds]
  );

  const parseEmployeeFullName = (employee: any): string => {
    return employee ? `${employee.firstName} ${employee.lastName}` : "";
  };

  const handleOnChangeDuration = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event?.target?.value;
    const duration: number = Number.isNaN(parseInt(value))
      ? 0
      : parseInt(value);
    handleTrainingEmployeeData(employee.id, duration, productIds);
    setValue(duration);
  };

  const handleOnClickProduct = (productId: number): void => {
    const index: number = productIds.findIndex((id) => id === productId);
    index === -1 ? productIds.push(productId) : productIds.splice(index, 1);
    setProductIds([...productIds]);
    handleTrainingEmployeeData(employee.id, value, productIds);
  };

  const isProductActive = (employee: any, product: any): boolean => {
    return (
      details.findIndex(
        (value: any) =>
          value?.trainingAppointment.id === appointmentId &&
          value?.pharmacyEmployee?.id === employee.id &&
          value?.product?.id === product.id
      ) !== -1
    );
  };

  const isProductHidden = (employee: any, product: any): boolean => {
    return (
      details.find(
        (value: any) =>
          value?.trainingAppointment.id !== appointmentId &&
          value?.pharmacyEmployee?.id === employee.id &&
          value?.product?.id === product.id
      ) !== undefined
    );
  };

  const isProductTrained = (employee: any, product: any): boolean => {
    return (
      details.find(
        (value: any) =>
          value?.trainingAppointment.id !== appointmentId &&
          value?.pharmacyEmployee?.id === employee.id &&
          value?.product?.id === product.id
      ) !== undefined
    );
  };

  const isReadOnly = () => {
    return productIds.length === 0;
  };

  return (
    <div id={id} className="row justify-content-start align-items-start p-2">
      <div className="col-12 col-md-2">
        <label
          className="common-form-label"
          htmlFor={`staff-${id}`}
          style={{ marginBottom: "0.2rem" }}
        >
          Staff
        </label>
        <input
          type="text"
          id={`staff-${id}`}
          name={`staff-${id}`}
          className="form-control common-form-input"
          aria-describedby="employeeHelp"
          value={parseEmployeeFullName(employee)}
          disabled={true}
          readOnly={true}
        />
      </div>
      <div className="col-12 col-md-2">
        <label
          className="common-form-label"
          htmlFor={`duration-${id}`}
          style={{ marginBottom: "0.2rem" }}
        >
          Durata<small className="ml-2">(Minuti)</small>
        </label>
        <input
          type="number"
          id={`duration-${id}`}
          name={`duration-${id}`}
          className="form-control common-form-input"
          aria-describedby="durationHelp"
          placeholder="Minuti"
          disabled={loading || lookup}
          readOnly={isReadOnly()}
          value={value === 0 ? "" : value}
          onChange={handleOnChangeDuration}
        />
      </div>
      <div className="col-12 col-md-8">
        <label htmlFor={`duration-${id}`} style={{ marginBottom: "0.2rem" }}>
          Prodotti
        </label>
        {products.length === 0 ? (
          <div className="d-flex flex-row justify-content-center align-items-center py-2">
            <small className="text-muted">Nessun prodotto disponibile</small>
          </div>
        ) : (
          <div className="d-flex flex-row flex-wrap justify-content-start align-items-start">
            <>
              {lookup
                ? products.map((product, index) => (
                    <TrainingProductComponent
                      key={index}
                      id={`${id}-product-${index}`}
                      product={product}
                      lookup={lookup}
                      active={isProductActive(employee, product)}
                      loading={loading}
                      onClickCallback={handleOnClickProduct}
                    />
                  ))
                : products.map((product, index) => (
                    <TrainingProductComponent
                      key={index}
                      id={`${id}-product-${index}`}
                      product={product}
                      lookup={lookup}
                      active={isProductActive(employee, product)}
                      loading={loading}
                      trained={isProductTrained(employee, product)}
                      onClickCallback={handleOnClickProduct}
                    />
                  ))}
            </>
          </div>
        )}
      </div>
    </div>
  );
};
