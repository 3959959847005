import React, {useEffect, useState} from 'react';
import './Style.css';

interface ToggleProps {
    checked: boolean,
    labelLeft: string;
    labelRight: string;
    callback: (value: boolean) => void;
}

export const ToggleComponent: React.FC<ToggleProps> = ({
                                                  checked = false,
                                                  labelLeft = 'Left',
                                                  labelRight = 'Right',
                                                  callback = (value: boolean) => {}
                                              }) => {

    const [value, setValue] = useState<boolean>(false);

    useEffect(() => {
        setValue(checked);
    },[checked])

    const handleOnChange = (event: any) => {
        const value: any = event?.target?.checked;
        setValue(value);
        callback(value);
    }

    return (
        <div className="toggle d-flex flex-row justify-content-center align-items-center">
            <label className="left">{labelLeft}</label>
            <label className="switch">
                <input type="checkbox" checked={value} onChange={handleOnChange}/>
                <span/>
            </label>
            <label className="right">{labelRight}</label>
        </div>
    );
}
