import React from "react";
import './Style.css';

interface GeneralSelectionProps {
    title: string,
    data?: Array<any>,
    handleChange?: any,
    hasStock?: boolean | undefined,
    readOnly?: boolean
}

export const GeneralSelection: React.FC<GeneralSelectionProps> = ({
                                                                      title = 'Titolo',
                                                                      data = [],
                                                                      handleChange,
                                                                      hasStock = false,
                                                                      readOnly = false
                                                                  }) => {

    return (
        <div className="general-selection">
            <label className="common-form-label">{title}</label>
            <div className="container-fluid striped">
                <div className="row row-header justify-content-start align-items-center">
                    <div className={`${hasStock ? 'col-5' : 'col-6'} text-left`}>Prodotto</div>
                    <div className={`${hasStock ? 'col-5' : 'col-6'} text-right`}>Quantità</div>
                    {hasStock &&
                    <div className="col-2 text-right text-nowrap overflow-hidden"><small>Scorte</small></div>}
                </div>
                {
                    data.length > 0 ?
                        data.map((value: any, index: number) => (
                            <div key={index} className="row row-element justify-content-center align-items-center my-2">
                                <div className={`${hasStock ? 'col-5' : 'col-6'} text-left`}>{value.name}</div>
                                <div className={`${hasStock ? 'col-5' : 'col-6'} text-right`}>
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder="Q.tà"
                                        name="quantity"
                                        readOnly={readOnly}
                                        onChange={(e) => {
                                            const parsed: number | null = Number.isNaN(parseInt(e.target.value, 10)) ? null : parseInt(e.target.value, 10);
                                            if (handleChange) handleChange(value, parsed);
                                        }}
                                        style={{
                                            width: '100px',
                                            borderRadius: 5,
                                            textIndent: 2,
                                            border: '1px solid #ddd'
                                        }}
                                    />
                                </div>
                                {hasStock && <div className="col-2 text-right">{value.stock}</div>}
                            </div>
                        ))
                        :
                      <div className="d-flex flex-row justify-content-center align-items-center bg-white py-5 bordered">
                        <small className="text-muted">Nessun prodotto disponibile</small>
                      </div>
                }
            </div>
        </div>
    );
}
