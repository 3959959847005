import React from "react";
import { Link, useLocation } from "react-router-dom";
import { fetchNavbarLogout } from "./Service";
import LOGO_NHCO from "../images/LOGO_NHCO_LIGHT.png";
import HOME from "../images/home.png";
import PHARMACY from "../images/pharmacy.png";
import NOTES from "../images/notes.png";
import EXIT from "../images/exit.png";
import Stock from "../images/icon-stock.png";
import Product from "../images/icon-product-management.png";
import Report from "../images/search.png";
import useAuth from "../hooks/useAuth";
import { EnUserRole } from "../helpers/PrivateRoute/PrivateRoute";

export const Sidebar: React.FC<any> = () => {
  const location = useLocation();
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    await fetchNavbarLogout();
    logout();
  };

  return (
    <div
      className="sidebar custom-sidebar"
      data-color="purple"
      data-background-color="white"
      data-image="../assets/img/sidebar-1.jpg"
    >
      <div className="logo" style={{ padding: "0" }}>
        <a href="/" className="">
          <img src={LOGO_NHCO} className="LOGO_NHCO" alt="LOGO_NHCO" />
        </a>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          <li
            className={
              location.pathname.includes("/dashboard")
                ? "nav-item sidebar-active"
                : "nav-item "
            }
          >
            <Link
              className="nav-link sidebar-text sidebar-tabs"
              to="/dashboard"
            >
              <p>
                <img className="material-icons sidebar-icon mr-3" src={HOME} />
                Home
              </p>
            </Link>
          </li>

          <li
            className={
              location.pathname.includes("/pharmacy-registry")
                ? "nav-item sidebar-active"
                : "nav-item "
            }
          >
            <Link
              className="nav-link sidebar-text sidebar-tabs"
              to="/pharmacy-registry"
            >
              <p>
                <img
                  className="material-icons sidebar-icon mr-3"
                  src={PHARMACY}
                />{" "}
                Anagrafica farmacie
              </p>
            </Link>
          </li>

          <li
            className={
              location.pathname.includes("/appointments-and-training") ||
              location.pathname.includes("/training/edit/") ||
              location.pathname.includes("/appointment/edit/")
                ? "nav-item sidebar-active"
                : "nav-item "
            }
          >
            <Link
              className="nav-link sidebar-text sidebar-tabs"
              to="/appointments-and-training"
            >
              {/* <i className="material-icons">content_paste</i> */}
              <p>
                <img className="material-icons sidebar-icon mr-3" src={NOTES} />{" "}
                Appuntamenti e Training
              </p>
            </Link>
          </li>

          <li
            className={
              location.pathname.includes("/stock-management")
                ? "nav-item sidebar-active"
                : "nav-item "
            }
          >
            <Link
              className="nav-link sidebar-text sidebar-tabs"
              to="/stock-management"
            >
              <p>
                <img className="material-icons sidebar-icon mr-3" src={Stock} />{" "}
                Stock Management
              </p>
            </Link>
          </li>

          {[EnUserRole.ADMIN].includes(user.role) && (
            <li
              className={
                location.pathname.includes("/product-management")
                  ? "nav-item sidebar-active"
                  : "nav-item "
              }
            >
              <Link
                className="nav-link sidebar-text sidebar-tabs"
                to="/product-management"
              >
                <p>
                  <img
                    className="material-icons sidebar-icon mr-3"
                    src={Product}
                  />{" "}
                  Product Management
                </p>
              </Link>
            </li>
          )}

          {[EnUserRole.PM, EnUserRole.ADMIN].includes(user.role) && (
            <li
              className={
                location.pathname.includes("/report")
                  ? "nav-item sidebar-active"
                  : "nav-item "
              }
            >
              <Link className="nav-link sidebar-text sidebar-tabs" to="/report">
                <p>
                  <img
                    className="material-icons sidebar-icon mr-3"
                    src={Report}
                  />{" "}
                  Report
                </p>
              </Link>
            </li>
          )}

          <li className="nav-item active-pro" style={{ cursor: "pointer" }}>
            <div
              className="nav-link sidebar-bottom-text sidebar-tabs"
              onClick={handleLogout}
            >
              <p>
                <img className="material-icons sidebar-icon mr-3" src={EXIT} />{" "}
                Esci
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
