import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {fetchStockProductAuditEntries} from './Service';
import {useToasts} from 'react-toast-notifications';

interface ProductStockAuditProps {
    agentId: number;
    productId: number;
}

export const StockPharmacySpecialistAudit: React.FC<ProductStockAuditProps> = (props: ProductStockAuditProps) => {
    const { addToast } = useToasts();

    const [loading, setLoading] = useState<boolean>(false);
    const [auditEntries, setAuditEntries] = useState<Array<any>>([]);

    useEffect(() => {
        if (props) {
            load().catch(error => console.error(error));
        }
    }, [props]);

    const load = async () => {
        try {
            setLoading(true);
            const entries: Array<any> = await fetchStockProductAuditEntries(props.agentId, props.productId) as Array<any>;
            setAuditEntries(entries);
            setLoading(false);
        } catch (error) {
            console.error('Stock Pharmacy Specialist Audit', error);
            setLoading(false);
            addToast('Errore durante il recupero delle informazioni necessari.', {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    const parseDatetime = (date: any) => {
        return new Date(date).toLocaleDateString('it-IT', {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const isAuditEntriesEmpty = (auditEntries: Array<any>) => {
        return auditEntries?.length === 0 || auditEntries?.filter(entry => ((entry.currentStock - entry.previousStock) !== 0))?.length === 0;
    }

    const getQuantity = (entry: any) => {
        return (entry?.currentStock - entry?.previousStock) || 0;
    }

    return (
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    {
                        !isAuditEntriesEmpty(auditEntries) ?
                            <div className="table-responsive">
                                <table className="table table-sm">
                                    <thead>
                                        <tr style={{ height: '2rem' }}>
                                            <th scope="col" style={{ fontSize: '1em', fontWeight: 'bold' }}>Scorta
                                            precedente
                                        </th>
                                            <th scope="col" style={{ fontSize: '1em', fontWeight: 'bold' }}>Azione</th>
                                            <th scope="col" style={{ fontSize: '1em', fontWeight: 'bold' }}>Q.tà</th>
                                            <th scope="col" style={{ fontSize: '1em', fontWeight: 'bold' }}>Autore</th>
                                            <th scope="col" style={{ fontSize: '1em', fontWeight: 'bold' }}>Tipologia</th>
                                            <th scope="col" style={{ fontSize: '1em', fontWeight: 'bold' }}>Nome Farmacia</th>
                                            <th scope="col"
                                                style={{ fontSize: '1em', fontWeight: 'bold', textAlign: 'right' }}>Eseguito
                                                il
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            auditEntries.map((entry, index) =>
                                                <tr key={'ixc-' + index} style={{ height: '2rem' }}>
                                                    <td>{entry?.previousStock}</td>
                                                    <td>{(entry?.action === 0) ?
                                                        <FontAwesomeIcon icon={faMinus} className="text-danger" /> :
                                                        <FontAwesomeIcon icon={faPlus} className="text-success" />}</td>
                                                    <td className={getQuantity(entry) < 0 ? "qty-red" : "qty-green"}>{getQuantity(entry)}</td>
                                                    <td>{entry?.actorName}</td>
                                                    <td>{entry?.appointment_type === null ? 'N/A' : entry?.appointment_type === 'NORMAL' ? 'Appuntamento' : 'Training'}</td>
                                                    <td>{entry?.pharmacy_name === null ? 'N/A' : entry?.pharmacy_name}</td>
                                                    <td style={{ textAlign: 'right' }}>{parseDatetime(entry?.entryDateTime)}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <p className="card-text d-flex flex-row justify-content-center align-items-center px-2 py-4">Nessun dato disponibile</p>
                    }
                </div>
            </div>
        </div>
    )
}
