import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import Select from "react-select";
import {
  fetchAllTrainingAppointmentsByPharmacyId,
  fetchSaveTrainingAppointment,
  fetchTrainingAppointmentTrainableByPharmacyId,
  fetchUpdateTrainingAppointment,
} from "../Service";
import { useToasts } from "react-toast-notifications";
import { faEye, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import { PharmacySelect } from "../../../components/PharmacySelect";
import { DatePickerComponent } from "../../../components/DatePicker/DatePickerComponent";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { IPharmacy } from "../../../interfaces/IPharmacy";
import { BackButton } from "../../../components/BackButton/BackButton";
import useAuth from "../../../hooks/useAuth";
import { PatchedPagination } from "../../../components/PatchedPagination/PatchedPagination";
import {parseISO} from 'date-fns';

const ContactOptions: any = [
  { value: "PHONE", label: "Telefonico" },
  { value: "IN_PERSON", label: "Fisico" },
  { value: "MAIL", label: "Mail" },
];

const TrainingOptions = [
  { value: "IN_PERSON", label: "Fisico" },
  { value: "REMOTE", label: "Remoto" },
];

interface TrainingFormProps {}

export const AddTrainingComponent: React.FC<TrainingFormProps> = ({}) => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToasts();
  const queryParams: URLSearchParams = useQueryParams();


  const pharmacyId: number | null = Number(queryParams.get('pharmacyId')) || null;
  const [loading, setLoading] = useState<boolean>(false);
  const [trainings, setTrainings] = useState<Array<any>>([]);
  const [pharmacy, setPharmacy] = useState<IPharmacy>();
  const [employeeCount, setEmployeeCount] = useState<number>(0);
  const [pharmacyProductCount, setPharmacyProductCount] = useState<number>(0);
  const [trainable, setTrainable] = useState<boolean>(false);
  const [
    emptyDataSourceMessage,
    setEmptyDataSourceMessage,
  ] = useState<React.ReactNode>("Nessun dato disponibile");

  const popperModifiers = {
    // flip: {
    //     // don't allow it to flip to be above
    //     behavior: ["bottom"]
    // },
    preventOverflow: {
      // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
      enabled: true,
    },
    hide: {
      // turn off since needs preventOverflow to be enabled
      enabled: true,
    },
  };

  const load = async (pharmacyId: number) => {
    setLoading(true);
    const results: Array<any> = (await fetchAllTrainingAppointmentsByPharmacyId(
      pharmacyId
    )) || [];
    setTrainings([...results]);
    const status: any = await fetchTrainingAppointmentTrainableByPharmacyId(
      pharmacyId
    );
    setEmployeeCount(status.employeeCount);
    setPharmacyProductCount(status.pharmacyProductCount);
    setTrainable(status.trainable);
    elaborateStatusMessage(pharmacyId, status);
    setLoading(false);
  };

  const handlePharmacyChanges = (pharmacy: IPharmacy | undefined) => {
    if (pharmacy) {
      load(pharmacy.id).catch((error) => console.error(error));
    }
  };

  const goBack = () => {
    history.push("/appointments-and-training?toggleState=TRAININGS");
  };

  const handleActionStart = (training: any) => {
    history.push(`/appointments-and-training/trainings/${training.id}/edit`);
  };

  const handleActionDetail = (training: any) => {
    handleActionStart(training);
  };

  const navigateToEmployeesPage = (pharmacyId: number | null = null) => {
    if (pharmacyId === null) return;
    const path: string = `/pharmacy-registry/${pharmacyId}/employees`;
    history.push(path);
  };

  const elaborateStatusMessage = (pharmacyId: number, status: any): void => {
    let message: React.ReactNode = <p>"Nessun dato disponibile"</p>;
    if (status?.employeeCount === 0) {
      message = (
        <p className="my-4">
          <span className="text-muted">Necessario inserire lo staff</span>
          {pharmacyId && (
            <span
              className="ml-4"
              style={{ cursor: "pointer" }}
              onClick={() => navigateToEmployeesPage(pharmacyId)}
            >
              <i className="fa fa-users fa-lg" aria-hidden="true" />
            </span>
          )}
        </p>
      );
    } else if (status?.pharmacyProductCount === 0) {
      message = <p className="text-muted my-4">Nessun ordine esistente.</p>;
    }
    setEmptyDataSourceMessage(message);
  };

  const isTrainable = (): boolean => {
    return trainable || false;
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <BackButton callback={goBack} />
        </div>

        <div className="container mt-4 mb-5">
          <PharmacySelect
            pharmacy={pharmacy}
            id={pharmacyId}
            setPharmacy={setPharmacy}
            onChange={handlePharmacyChanges}
          />
        </div>

        {pharmacy && (
          <div className="container-fluid">
            <MaterialTable
              components={{
                // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                Pagination: PatchedPagination
              }}
              actions={[
                (rowData: any) => {
                  return {
                    icon: () => (
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ fontSize: "1rem" }}
                          className="text-muted"
                        />
                      </div>
                    ),
                    hidden: rowData.status === 0,
                    tooltip: "Dettaglio Training",
                    onClick: (event, rowData) => handleActionDetail(rowData),
                  };
                },
                (rowData: any) => {
                  return {
                    icon: () => (
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlay}
                          style={{ fontSize: "1rem" }}
                          className="text-muted"
                        />
                      </div>
                    ),
                    hidden: rowData.status === 1,
                    tooltip: "Avvia Training",
                    onClick: (event, rowData) => handleActionStart(rowData),
                  };
                },
              ]}
              columns={[
                {
                  title: "Ciclo",
                  field: "pharmacyCycle",
                  type: "string",
                  render: (rowData) => rowData?.pharmacyCycle?.cycle,
                  editable: "never",
                },
                {
                  title: "Data Contatto",
                  field: "contactDate",
                  type: "date",
                  dateSetting: {
                    locale: "it",
                  },
                  editComponent: (props) => {
                    const contactDate = props?.rowData?.contactDate || null;
                    return (
                      <DatePickerComponent
                        currentDate={contactDate ? parseISO(contactDate) : null}
                        setDate={(date: any, dateType: any) => props.onChange(date.toISOString())}
                        dateType="contactDate"
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                        popperModifiers={popperModifiers}
                      />
                    );
                  },
                },
                {
                  title: "Tipo di Contatto",
                  field: "contactType",
                  initialEditValue: "",
                  type: "string",
                  lookup: {
                    PHONE: "Telefonico",
                    IN_PERSON: "Fisico",
                    MAIL: "Mail",
                  },
                  editComponent: (props) => {
                    const ctIndex = ContactOptions.findIndex(
                      (elm: any) => elm.value === props.rowData.contactType
                    );
                    return (
                      <Select
                        className="basic-single"
                        classNamePrefix="custom-dropdown"
                        placeholder="Tipo contatto"
                        name="Tippo contatto"
                        options={ContactOptions}
                        value={ContactOptions[ctIndex]}
                        onChange={(value) => props.onChange(value)}
                      />
                    );
                  },
                },
                {
                  title: "Data Training",
                  field: "trainingDate",
                  type: "datetime",
                  dateSetting: {
                    locale: "it",
                  },
                  editComponent: (props) => {
                    const trainingDate = props?.rowData?.trainingDate || null;
                    return (
                      <DatePickerComponent
                        currentDate={trainingDate ? parseISO(trainingDate) : null}
                        setDate={(date: any, dateType: any) => props.onChange(date.toISOString())}
                        dateType="trainingDate"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect={true}
                        popperModifiers={popperModifiers}
                        minDate={new Date(props.rowData.contactDate)}
                      />
                    );
                  },
                },
                {
                  title: "Tipo di Training",
                  field: "trainingType",
                  initialEditValue: "",
                  type: "string",
                  lookup: {
                    IN_PERSON: "Fisico",
                    REMOTE: "Remoto",
                  },
                  editComponent: (props) => {
                    const ttIndex = TrainingOptions.findIndex(
                      (elm: any) => elm.value === props.rowData.trainingType
                    );
                    return (
                      <Select
                        className="basic-single"
                        classNamePrefix="custom-dropdown"
                        name="Positivo/Negativo"
                        placeholder="Tipo di formazione"
                        options={TrainingOptions}
                        value={TrainingOptions[ttIndex]}
                        onChange={(value) => props.onChange(value)}
                      />
                    );
                  },
                }
              ]}
              data={trainings}
              icons={{
                Add: forwardRef((props, ref) => (
                  <AddBox {...props} ref={ref} color="action" />
                )),
                Edit: forwardRef((props, ref) => (
                  <Edit {...props} ref={ref} color="action" />
                )),
                Delete: forwardRef((props, ref) => (
                  <Delete {...props} ref={ref} color="action" />
                )),
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage,
                  addTooltip: "Aggiungi",
                  deleteTooltip: "Elimina",
                  editTooltip: "Modifica",
                  filterRow: {
                    filterTooltip: "Filtro",
                  },
                  editRow: {
                    deleteText: "Vuoi effettuare la modifica?",
                    cancelTooltip: "Annulla",
                    saveTooltip: "Salva",
                  },
                },
                pagination: {
                  labelRowsSelect: "righe",
                },
                header: {
                  actions: "",
                },
                toolbar: {
                  searchTooltip: "Cerca",
                  searchPlaceholder: "Cerca",
                },
              }}
              options={{
                search: false,
                showTitle: false,
                header: trainable,
                paging: trainable,
                pageSize: 25,
                pageSizeOptions: [10, 25, 50],
                actionsColumnIndex: -1,
                toolbar: trainable,
                draggable: false,
                rowStyle: (rowData) => ({
                  color: rowData.status === 1 ? "black" : "orange",
                }),
              }}
              isLoading={loading}
              editable={{
                // Add
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    if (!trainable) {
                      addToast(
                        <div>
                          <h6>Appuntamento non creato</h6>
                          <ol>
                            {employeeCount === 0 && (
                              <li>Staff farmacia non inserito</li>
                            )}
                            {pharmacyProductCount === 0 && (
                              <li>Nessun prodotto disponibile</li>
                            )}
                          </ol>
                        </div>,
                        { appearance: "error", autoDismiss: false }
                      );
                      reject("No trainable pharmacy");
                      return;
                    }
                    fetchSaveTrainingAppointment({
                      appointment: {
                        pharmacyId: pharmacy.id,
                        contactDate: newData.contactDate,
                        contactType: newData.contactType.value,
                        trainingDate: newData.trainingDate,
                        trainingType: newData.trainingType.value,
                      },
                    })
                      .then(() => {
                        resolve(true);
                        // addToast('Salvato con successo!', {
                        //     appearance: 'success',
                        //     autoDismiss: true
                        // });
                        load(pharmacy?.id).catch((error) =>
                          console.error(error)
                        );
                      })
                      .catch((error: any) => {
                        if (error.type === "FORM_VALIDATION") {
                          error.response.forEach((err: any) => {
                            addToast(err, {
                              appearance: "error",
                              autoDismiss: false,
                            });
                          });
                        } else {
                          addToast(error.message, {
                            appearance: "error",
                            autoDismiss: false,
                          });
                        }
                        reject(error);
                      });
                  }),
                // Edit
                isEditHidden: (rowData) => rowData.status === 1,
                isEditable: (rowData) => rowData.status === 0,
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    fetchUpdateTrainingAppointment(newData.id, {
                      appointment: {
                        contactDate: newData.contactDate,
                        contactType: newData.contactType.hasOwnProperty("value")
                          ? newData.contactType.value
                          : newData.contactType,
                        trainingDate: newData.trainingDate,
                        trainingType: newData.trainingType.hasOwnProperty(
                          "value"
                        )
                          ? newData.trainingType.value
                          : newData.trainingType,
                      },
                    })
                      .then(() => {
                        resolve(true);
                        // addToast('Salvato con successo!', {
                        //     appearance: 'success',
                        //     autoDismiss: true
                        // });
                        load(pharmacy?.id).catch((error) =>
                          console.error(error)
                        );
                      })
                      .catch((error: any) => {
                        if (error.type === "FORM_VALIDATION") {
                          error.response.forEach((err: any) => {
                            addToast(err, {
                              appearance: "error",
                              autoDismiss: false,
                            });
                          });
                        } else {
                          addToast(error.message, {
                            appearance: "error",
                            autoDismiss: false,
                          });
                        }
                        reject(error);
                      });
                  }),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
