import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {
  fetchAppointmentById,
  fetchProductSampleStockByAgentId,
  fetchPharmacyStockByPharmacyId,
  fetchUpdateAppointment,
} from "../Service";
import swal from "sweetalert";
import * as _ from "lodash";
import { INegativeResult } from "../../../interfaces/INegativeResult";
import {
  EnAppointmentPharmacyStatus,
  EnAppointmentStatus,
} from "../LanguageMappings";
import { GeneralSelection } from "../../../components/GeneralSelection/GeneralSelection";
import { SelloutTable } from "../../../components/SelloutTable/SelloutTable";
import { fetchProducts } from "../../StockManagement/Service";
import { NegativeResult } from "../../../components/NegativeResult/NegativeResult";
import { DateTime } from "luxon";
import "./Style.css";
import { BackButton } from "../../../components/BackButton/BackButton";
import { DatePickerComponent } from "../../../components/DatePicker/DatePickerComponent";
import Select from "react-select";

interface EditAppointmentProps {}

export const EditAppointmentComponent: React.FC<EditAppointmentProps> = (
  props: any
) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { user } = useAuth();

  // Default
  const [products, setProducts] = useState<Array<any>>([]);
  const [stockSpecialist, setStockSpecialist] = useState<Array<any>>([]);
  const [stockPharmacy, setStockPharmacy] = useState<Array<any>>([]);
  const [appointment, setAppointment] = useState<any>(null);

  // Form entities
  const [appointmentDate, setAppointmentDate] = useState<any>();
  const [positiveResults, setPositiveResults] = useState<Array<any>>([]);
  const [negativeResult, setNegativeResult] = useState<INegativeResult>();
  const [checkboxPharmacyStatusDeleted, setCheckboxPharmacyStatusDeleted] =
    useState<boolean>(false);
  const [checkboxPharmacyStatusReContact, setCheckboxPharmacyStatusReContact] =
    useState<boolean>(false);
  const [
    checkboxPharmacyStatusChainGroups,
    setCheckboxPharmacyStatusChainGroups,
  ] = useState<boolean>(false);
  const [pharmacyStatus, setPharmacyStatus] =
    useState<EnAppointmentPharmacyStatus>(EnAppointmentPharmacyStatus.NORMAL);
  const [samples, setSamples] = useState<Array<any>>([]);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [reorders, setReOrders] = useState<Array<any>>([]);
  const [sellouts, setSellouts] = useState<Array<any>>([]);
  const [sampling, setSampling] = useState<0 | 1 | 2>(2);
  const [coachingType, setCoachingType] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const appointmentId = props.match.params.id || null;
    if (appointmentId === null) {
      addToast("Appuntamento non valido", {
        appearance: "error",
        autoDismiss: false,
      });
      goBack();
      return;
    }
    setLoading(true);
    fetchAppointmentById(appointmentId)
      .then((appointment: any) => {
        setAppointment(appointment || null);
        setLoading(false);
      })
      .catch((e) => {
        console.error("An error occurred", e);
        addToast("Errore durante il caricamento dei dati.", {
          appearance: "error",
          autoDismiss: false,
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!appointment) {
      return;
    }
    if (appointment?.status === EnAppointmentStatus.COMPLETED) {
      addToast("Appuntamento non modificabile", {
        appearance: "error",
        autoDismiss: false,
      });
      goBack();
      return;
    }
    setLoading(true);
    Promise.all([
      fetchProducts(),
      fetchPharmacyStockByPharmacyId(appointment?.pharmacy?.id),
      fetchProductSampleStockByAgentId(user.sub),
    ])
      .then((results: Array<any>) => {
        setProducts(results[0] || []);
        setStockPharmacy(results[1] || []);
        setStockSpecialist(results[2] || []);
        setLoading(false);
      })
      .catch((e) => {
        console.error("An error occurred", e);
        addToast(
          "Errore durante il caricamento dei dati relativi allo stock della farmacia.",
          {
            appearance: "error",
            autoDismiss: false,
          }
        );
        setLoading(false);
      });
  }, [appointment]);

  useEffect(() => {
    if (!products) {
      return;
    }
    setOrders(elaborateStockFor("order", stockPharmacy));
    setReOrders(elaborateStockFor("reOrder", stockPharmacy));
  }, [products]);

  useEffect(() => {
    handlePharmacyStatusCheckboxChanges();
    handleNegativeResultChanges(undefined);
  }, [positiveResults]);

  useEffect(() => {
    if (sampling !== 1) {
      return;
    }
    setSamples(
      elaborateStockFor("sample", stockSpecialist, (value) => value.stock > 0)
    );
  }, [sampling]);

  const elaborateStockFor = (
    key: "sample" | "order" | "reOrder",
    stock: Array<any>,
    callback: (value: any) => boolean = () => true
  ): Array<any> => {
    const samples: Array<any> = products.filter((value) => value[key] === 1);
    const mapped: Array<any> = samples.map((value) => ({
      id: value.id,
      name: value.name,
      quantity: undefined,
      active: false,
      stock: stockSpecialist.reduce(
        (previousValue, currentValue) =>
          currentValue.productId === value.id
            ? currentValue.quantity
            : previousValue,
        0
      ),
    }));
    return mapped.filter(callback);
  };

  const handleAppointmentDateChanges = (date: any) => {
    setAppointmentDate(date);
  };

  const handlePositiveResultsChanges = (
    type:
      | "ORDER"
      | "RE_ORDER"
      | "VISIT"
      | "SELL_OUT_CHECK"
      | "ALLESTIMENTO"
      | "NEGATIVO"
      | "MAIL_REMINDER"
      | "COACHING"
      | null = null,
    intersection: Array<string> = []
  ) => {
    if (intersection.length !== 0) {
      return;
    }
    const results: Array<string> =
      type !== null
        ? _.xor(positiveResults, [type])
        : _.drop(positiveResults, positiveResults.length);
    setPositiveResults(results);
  };

  const handleCoachingType = (value: string | undefined) => {
    setCoachingType(value);
  }

  const handleNegativeResultChanges = (
    negativeResult: INegativeResult | undefined = undefined
  ) => {
    setNegativeResult(negativeResult);
  };

  const handlePharmacyStatusCheckboxChanges = (
    type: EnAppointmentPharmacyStatus = EnAppointmentPharmacyStatus.NORMAL
  ) => {
    switch (type) {
      case EnAppointmentPharmacyStatus.ELIMINATA:
        setCheckboxPharmacyStatusDeleted(true);
        setCheckboxPharmacyStatusReContact(false);
        setCheckboxPharmacyStatusChainGroups(false);
        break;
      case EnAppointmentPharmacyStatus.RICONTATTARE:
        setCheckboxPharmacyStatusDeleted(false);
        setCheckboxPharmacyStatusReContact(true);
        setCheckboxPharmacyStatusChainGroups(false);
        break;
      case EnAppointmentPharmacyStatus.CATENE_E_GRUPPI:
        setCheckboxPharmacyStatusDeleted(false);
        setCheckboxPharmacyStatusReContact(false);
        setCheckboxPharmacyStatusChainGroups(true);
        break;
      case EnAppointmentPharmacyStatus.NORMAL:
        setCheckboxPharmacyStatusDeleted(false);
        setCheckboxPharmacyStatusReContact(false);
        setCheckboxPharmacyStatusChainGroups(false);
        break;
    }
    setPharmacyStatus(type);
  };

  const handleSamplingChanges = (value: 0 | 1 | 2) => {
    setSampling(sampling === value ? 2 : value);
  };

  const handleSampleChanges = (sample: any, value: number) => {
    const index: number = samples.findIndex((o) => o.id === sample.id);
    const updated: any = samples[index];
    updated.quantity = value;
    samples.splice(index, 1, updated);
    setSamples([...samples]);
  };

  const handleReOrderChanges = (reorder: any, value: number) => {
    const index: number = reorders.findIndex((o) => o.id === reorder.id);
    const updated: any = reorders[index];
    updated.quantity = value;
    reorders.splice(index, 1, updated);
    setReOrders([...reorders]);
  };

  const handleOrderChanges = (order: any, value: number) => {
    const index: number = orders.findIndex((o) => o.id === order.id);
    const updated: any = orders[index];
    updated.quantity = value;
    orders.splice(index, 1, updated);
    setOrders([...orders]);
  };

  const hasSelloutInvalidMonth = (): boolean => {
    return sellouts.some((sellout: any) => {
      return sellout.months.some((month: any, i: number) => {
        return month.invalid === true;
      });
    });
  };

  const hasSelloutNegativeStock = (): boolean => {
    return sellouts.some((sellout: any) => {
      if (!sellout.hasOwnProperty("newCurrentStock")) return false;
      return Math.sign(sellout.newCurrentStock) === -1;
    });
  };

  const isSelloutUntouched = (): boolean => {
    return sellouts.every((sellout: any) => {
      return sellout.months.every((month: any, i: number) => {
        return month.dirty === false && month.invalid === false;
      });
    });
  };

  const submit = async (dto: any) => {
    setSubmitting(true);
    fetchUpdateAppointment(appointment.id, dto)
      .then(() => {
        setSubmitting(false);
        addToast("Salvato con successo!", {
          appearance: "success",
          autoDismiss: true,
        });
        goBack();
      })
      .catch((error: any) => {
        setSubmitting(false);
        console.error("An error occurred", error);
        if (error.type === "FORM_VALIDATION") {
          error.response.forEach((e: any) =>
            addToast(e, { appearance: "error", autoDismiss: false })
          );
        } else {
          addToast(error.message, { appearance: "error", autoDismiss: false });
        }
      });
  };

  const preSubmit = async () => {
    try {
      let isConfirmed;

      const dto: any = {
        appointment: {
          pharmacyId: appointment?.pharmacy?.id,
          contactDate: appointment?.contactDate,
          appointmentDate: appointment?.appointmentDate || DateTime.fromJSDate(appointmentDate).toISO(),
          contactType: appointment?.contactType,
          contactOutcome: appointment?.contactOutcome,
          positiveResults: appointment?.positiveResults?.length !== 0 ? appointment?.positiveResults : positiveResults,
          negativeResultId: appointment?.negativeResult?.id || negativeResult?.id,
          pharmacyStatus: appointment?.pharmacyStatus || pharmacyStatus,
          sampling: appointment?.sampling === 2 ? sampling : appointment?.sampling,
          coachingType: appointment?.coachingType || coachingType,
        },
        orders: [],
        reorders: [],
        samples: [],
        sellouts: [],
        makePharmacyInactive:
          (appointment?.contactOutcome?.value === "NEGATIVE" ||
            positiveResults?.includes("NEGATIVO")) &&
          (pharmacyStatus === EnAppointmentPharmacyStatus.ELIMINATA ||
            pharmacyStatus === EnAppointmentPharmacyStatus.CATENE_E_GRUPPI),
      };
      console.log('[Edit Appointment]', dto);

      if (positiveResults?.includes("ORDER")) {
        dto.orders = orders
          ?.filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity,
          }));
        if (dto.orders.length === 0) {
          addToast("L'ordine richiede almeno un articolo!", {
            appearance: "error",
            autoDismiss: false,
          });
          return;
        }
      }

      if (positiveResults?.includes("RE_ORDER")) {
        dto.reorders = reorders
          ?.filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity,
          }));
        if (dto.reorders.length === 0) {
          addToast("Il riordine richiede almeno un articolo!", {
            appearance: "error",
            autoDismiss: false,
          });
          return;
        }
      }

      if (positiveResults?.includes("SELL_OUT_CHECK")) {
        if (isSelloutUntouched()) {
          addToast(`La verifica sell-out richiede almeno un articolo!`, {
            appearance: "error",
            autoDismiss: false,
          });
          return;
        }
        if (hasSelloutInvalidMonth()) {
          addToast(
            `La verifica sell-out richiede tutti gli articoli per i mesi valorizzati!`,
            {
              appearance: "error",
              autoDismiss: false,
            }
          );
          return;
        }
        dto.sellouts = [];
        sellouts?.forEach((s: any) => {
          s.months.forEach((m: any) => {
            let createSelloutDto: any = {};
            createSelloutDto.quantity = m.selloutQuantity;
            createSelloutDto.month = m.month;
            createSelloutDto.year = s.year;
            createSelloutDto.productId = s.productId;
            const element =
              dto.sellouts.find(
                (value: any) =>
                  value.productId === s.productId && value.month === m.month
              ) || undefined;
            if (
              element === undefined &&
              !m.isNotAvailable &&
              !m.isVerified &&
              m.selloutQuantity !== null
            ) {
              dto.sellouts.push(createSelloutDto);
            }
          });
        });
      }

      if (sampling === 1) {
        if (samples.length === 0) {
          addToast("Compila la tabella CAMPIONI", {
            appearance: "error",
            autoDismiss: false,
          });
          return;
        }
        dto.samples = samples
          .filter((item: any) => item.quantity > 0)
          .map((value: any) => ({
            productId: value.id,
            quantity: value.quantity,
          }));
      }

      if (hasSelloutNegativeStock()) {
        await swal(
          "ATTENZIONE",
          "La verifica sell-out contiene stock negativi.",
          "warning",
          {
            buttons: {
              checked: {
                text: "Ok",
                value: true,
                visible: true,
                className: "btn btn-sm btn-outline-dark",
                closeModal: true,
              },
            },
          }
        );
      }

      isConfirmed = await swal(
        "",
        "Sei sicuro di voler salvare questo appuntamento?",
        "info",
        {
          buttons: {
            cancel: {
              text: "No",
              value: false,
              visible: true,
              className: "btn btn-sm btn-outline-dark",
              closeModal: true,
            },
            confirm: {
              text: "Si",
              value: true,
              visible: true,
              className: "btn btn-sm btn-outline-dark",
              closeModal: true,
            },
          },
        }
      );

      if (isConfirmed) {
        await submit(dto);
      }
    } catch (error) {
      console.error("An error occurred", error);
      if (error.type === "FORM_VALIDATION") {
        error.response.forEach((err: any) =>
          addToast(err, { appearance: "error", autoDismiss: false })
        );
      } else {
        addToast(error.message, { appearance: "error", autoDismiss: false });
      }
    }
  };

  const goBack = () => {
    history.push("/appointments-and-training?toggleState=APPOINTMENTS");
  };

  const parseAppointmentDate = (value: string | null = null) => {
    if (value === null) {
      return;
    }
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
  };

  const parseAppointmentCoachingType = (value: string) => {
    switch (value) {
      case "ALL":
        return "Tutto";
      case "MORNING":
        return "Mattina";
      case "AFTERNOON":
        return "Pomeriggio";
      default:
        return "";
    }
  };

  const parseAppointmentContactType = (value: string) => {
    switch (value) {
      case "PHONE":
        return "Telefonico";
      case "IN_PERSON":
        return "Fisico";
      case "MAIL":
        return "Mail";
      default:
        return "";
    }
  };

  const parseAppointmentOutcome = (value: string) => {
    switch (value) {
      case "POSITIVE":
        return "Positivo";
      case "NEGATIVE":
        return "Negativo";
      case "MAIL_REMINDER":
        return "Mail Reminder";
      default:
        return "";
    }
  };

  const parseAppointmentPositiveResult = (value: string) => {
    switch (value) {
      case "ORDER":
        return "ORDINE";
      case "RE_ORDER":
        return "RIORDINE";
      case "VISIT":
        return "VISITA";
      case "SELL_OUT_CHECK":
        return "VERIFICA SELL-OUT";
      case "ALLESTIMENTO":
        return "ALLESTIMENTO";
      case "MAIL_REMINDER":
        return "MAIL REMINDER";
      case "NEGATIVO":
        return "NEGATIVO";
      case "COACHING":
        return "ATTIVITÀ DI COACHING";
    }
  };

  const getProductName = (productId: number): string => {
    const product: any = products.find((p) => p.id === productId);
    return product?.name;
  };

  return (
    <form className="content">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <BackButton callback={goBack} />
          </div>
          <div>
            <button
              type="button"
              className="btn common-btn"
              onClick={preSubmit}
              disabled={submitting || !appointment}
            >
              Salva
            </button>
          </div>
        </div>
        {appointment && (
          <>
            <div className="container my-5">
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Nome Farmacia</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.name || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Persona di riferimento</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.contact || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Telefono</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.phone || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Città</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.city || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Indirizzo</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.address || "-"}
                </div>
              </div>
            </div>

            <div
              className="container-fluid animate__animated animate__fadeIn p-5"
              style={{
                position: "relative",
                zIndex: 0,
                border: "3px dashed #CACACA",
              }}
            >
              <div className="row justify-content-start align-items-center">
                <div className="col-12 col-md-4 my-1">
                  <label className="common-form-label">Data Contatto</label>
                  <input
                    type="text"
                    className="form-control common-form-input"
                    id="contactDate"
                    name="contactDate"
                    readOnly={true}
                    defaultValue={parseAppointmentDate(
                      appointment?.contactDate
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 my-1">
                  <label className="common-form-label">Tipo Contatto</label>
                  <input
                    type="text"
                    className="form-control common-form-input"
                    id="contactType"
                    name="contactType"
                    readOnly={true}
                    defaultValue={parseAppointmentContactType(
                      appointment?.contactType
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 my-1">
                  <label className="common-form-label">Esito Contatto</label>
                  <input
                    type="text"
                    className="form-control common-form-input"
                    id="contactOutcome"
                    name="contactOutcome"
                    readOnly={true}
                    defaultValue={parseAppointmentOutcome(
                      appointment?.contactOutcome
                    )}
                  />
                </div>
              </div>

              {appointment?.contactOutcome === "POSITIVE" && (
                <>
                  <hr className="hr-text" data-content="ESITO POSITIVO" />
                  {/* APPOINTMENT DATE */}
                  <>
                    {/* detail */}
                    {appointment?.appointmentDate && (
                      <div className="row mb-4">
                        <div className="col-4">
                          <label className="common-form-label">
                            Data Appuntamento
                          </label>
                          <input
                            type="text"
                            className="form-control common-form-input"
                            id="appointmentDate"
                            name="appointmentDate"
                            readOnly={true}
                            defaultValue={parseAppointmentDate(
                              appointment?.appointmentDate
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {!appointment?.appointmentDate && (
                      <div className="row mb-4">
                        <div className="col-4">
                          <DatePickerComponent
                            label="Data appuntamento"
                            currentDate={appointmentDate}
                            setDate={handleAppointmentDateChanges}
                            dateType="appointmentDate"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeSelect={true}
                          />
                        </div>
                      </div>
                    )}
                  </>
                  {/* POSITIVE RESULT */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.length > 0 && (
                      <div className="row mb-4">
                        <div className="col-6">
                          <label className="common-form-label">
                            Esito Appuntamento
                          </label>
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            {appointment?.positiveResults?.map(
                              (value: string, index: number) => (
                                <span
                                  key={index}
                                  className="badge badge-dark mr-1 p-2"
                                >
                                  {parseAppointmentPositiveResult(value)}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {appointment?.positiveResults?.length === 0 && (
                      <div className="row mb-4">
                        <div className="col-12">
                          <label className="common-form-label">
                            Esito Appuntamento
                          </label>
                          <ul id="contact-outcome" className="list-group">
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                              onClick={() =>
                                handlePositiveResultsChanges(
                                  "ORDER",
                                  _.intersection(positiveResults, [
                                    "RE_ORDER",
                                    "SELL_OUT_CHECK",
                                    "VISIT",
                                    "ALLESTIMENTO",
                                    "MAIL_REMINDER",
                                    "NEGATIVO",
                                    "COACHING",
                                  ])
                                )
                              }
                            >
                              ORDINE
                              <div
                                id="order"
                                className={`custom-checkbox ${
                                  positiveResults.includes("ORDER") &&
                                  "checkbox-active"
                                } ${
                                  _.intersection(positiveResults, [
                                    "RE_ORDER",
                                    "SELL_OUT_CHECK",
                                    "VISIT",
                                    "ALLESTIMENTO",
                                    "MAIL_REMINDER",
                                    "NEGATIVO",
                                    "COACHING",
                                  ]).length !== 0 && "combo-disabled"
                                }`}
                              />
                            </li>
                            {appointment?.pharmacy?.isClient && (
                              <>
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                  onClick={() =>
                                    handlePositiveResultsChanges(
                                      "RE_ORDER",
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ])
                                    )
                                  }
                                >
                                  RIORDINE
                                  <div
                                    id="order"
                                    className={`custom-checkbox ${
                                      positiveResults.includes("RE_ORDER") &&
                                      "checkbox-active"
                                    } ${
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ]).length !== 0 && "combo-disabled"
                                    }`}
                                  />
                                </li>
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                  onClick={() =>
                                    handlePositiveResultsChanges(
                                      "SELL_OUT_CHECK",
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ])
                                    )
                                  }
                                >
                                  VERIFICA SELL-OUT
                                  <div
                                    id="order"
                                    className={`custom-checkbox ${
                                      positiveResults.includes(
                                        "SELL_OUT_CHECK"
                                      ) && "checkbox-active"
                                    } ${
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ]).length !== 0 && "combo-disabled"
                                    }`}
                                  />
                                </li>
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                  onClick={() =>
                                    handlePositiveResultsChanges(
                                      "COACHING",
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ])
                                    )
                                  }
                                >
                                  ATTIVITÀ DI COACHING
                                  <div
                                    id="order"
                                    className={`custom-checkbox ${
                                      positiveResults.includes("COACHING") &&
                                      "checkbox-active"
                                    } ${
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ]).length !== 0 && "combo-disabled"
                                    }`}
                                  />
                                </li>

                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                  onClick={() =>
                                    handlePositiveResultsChanges(
                                      "VISIT",
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ])
                                    )
                                  }
                                >
                                  VISITA
                                  <div
                                    id="order"
                                    className={`custom-checkbox ${
                                      positiveResults.includes("VISIT") &&
                                      "checkbox-active"
                                    } ${
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ]).length !== 0 && "combo-disabled"
                                    }`}
                                  />
                                </li>
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                                  onClick={() =>
                                    handlePositiveResultsChanges(
                                      "ALLESTIMENTO",
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ])
                                    )
                                  }
                                >
                                  ALLESTIMENTO
                                  <div
                                    id="order"
                                    className={`custom-checkbox ${
                                      positiveResults.includes("ALLESTIMENTO") &&
                                      "checkbox-active"
                                    } ${
                                      _.intersection(positiveResults, [
                                        "ORDER",
                                        "MAIL_REMINDER",
                                        "NEGATIVO",
                                      ]).length !== 0 && "combo-disabled"
                                    }`}
                                  />
                                </li>
                              </>
                            )}
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                              onClick={() =>
                                handlePositiveResultsChanges(
                                  "MAIL_REMINDER",
                                  _.intersection(positiveResults, [
                                    "ORDER",
                                    "RE_ORDER",
                                    "VISIT",
                                    "SELL_OUT_CHECK",
                                    "ALLESTIMENTO",
                                    "NEGATIVO",
                                    "COACHING",
                                  ])
                                )
                              }
                            >
                              MAIL REMINDER
                              <div
                                id="order"
                                className={`custom-checkbox ${
                                  positiveResults.includes("MAIL_REMINDER") &&
                                  "checkbox-active"
                                } ${
                                  _.intersection(positiveResults, [
                                    "ORDER",
                                    "RE_ORDER",
                                    "VISIT",
                                    "SELL_OUT_CHECK",
                                    "ALLESTIMENTO",
                                    "NEGATIVO",
                                    "COACHING",
                                  ]).length !== 0 && "combo-disabled"
                                }`}
                              />
                            </li>
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center common-small-list"
                              onClick={() =>
                                handlePositiveResultsChanges(
                                  "NEGATIVO",
                                  _.intersection(positiveResults, [
                                    "ORDER",
                                    "RE_ORDER",
                                    "VISIT",
                                    "SELL_OUT_CHECK",
                                    "ALLESTIMENTO",
                                    "MAIL_REMINDER",
                                    "COACHING",
                                  ])
                                )
                              }
                            >
                              NEGATIVO
                              <div
                                id="order"
                                className={`custom-checkbox ${
                                  positiveResults.includes("NEGATIVO") &&
                                  "checkbox-active"
                                } ${
                                  _.intersection(positiveResults, [
                                    "ORDER",
                                    "RE_ORDER",
                                    "VISIT",
                                    "SELL_OUT_CHECK",
                                    "ALLESTIMENTO",
                                    "MAIL_REMINDER",
                                    "COACHING",
                                  ]).length !== 0 && "combo-disabled"
                                }`}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </>
                  {/* ORDER */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes("ORDER") && (
                      <>
                        <hr className="hr-text" data-content="ORDINE" />
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <ul className="list-group w-100">
                              {appointment?.orders?.map(
                                (order: any, index: number) => (
                                  <li
                                    key={index}
                                    className="list-group-item d-flex justify-content-between align-items-center text-center"
                                  >
                                    <span className="text-muted">
                                      {getProductName(order.productId)}
                                    </span>
                                    <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                                      {order.quantity}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {positiveResults.includes("ORDER") && (
                      <>
                        <hr className="hr-text" data-content="ORDINE" />
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <GeneralSelection
                              title="Ordine"
                              handleChange={handleOrderChanges}
                              data={orders}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  {/* RE_ORDER */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes("RE_ORDER") && (
                      <>
                        <hr className="hr-text" data-content="RIORDINE" />
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <ul className="list-group w-100">
                              {appointment?.reorders?.map(
                                (reorders: any, index: number) => (
                                  <li
                                    key={index}
                                    className="list-group-item d-flex justify-content-between align-items-center text-center"
                                  >
                                    <span className="text-muted">
                                      {getProductName(reorders.productId)}
                                    </span>
                                    <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                                      {reorders.quantity}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {positiveResults.includes("RE_ORDER") && (
                      <>
                        <hr className="hr-text" data-content="RIORDINE" />
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <GeneralSelection
                              title="Riordine"
                              handleChange={handleReOrderChanges}
                              data={reorders}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  {/* SELL-OUT */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes(
                      "SELL_OUT_CHECK"
                    ) && (
                      <>
                        <hr
                          className="hr-text"
                          data-content="VERIFICA SELL-OUT"
                        />
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <SelloutTable
                              pharmacy={appointment?.pharmacy?.id}
                              date={appointment?.appointmentDate}
                              sellouts={sellouts}
                              setSellouts={setSellouts}
                              isDetail={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {positiveResults.includes("SELL_OUT_CHECK") && (
                      <>
                        <hr
                          className="hr-text"
                          data-content="VERIFICA SELL-OUT"
                        />
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <SelloutTable
                              pharmacy={appointment?.pharmacy?.id}
                              date={appointmentDate}
                              sellouts={sellouts}
                              setSellouts={setSellouts}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  {/*ATTIVITÀ DI COACHING*/}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes("COACHING") && (
                      <>
                        <hr
                          className="hr-text"
                          data-content="ATTIVITÀ DI COACHING"
                        />
                        <div className="row justify-content-start align-items-center">
                          <div className="col-12 col-md-4 my-1">
                            <label className="common-form-label">
                              Giornata
                            </label>
                            <input
                              type="text"
                              className="form-control common-form-input"
                              id="coachingType"
                              name="coachingType"
                              readOnly={true}
                              defaultValue={parseAppointmentCoachingType(
                                appointment?.coachingType
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {positiveResults.includes("COACHING") && (
                      <>
                        <hr
                          className="hr-text"
                          data-content="ATTIVITÀ DI COACHING"
                        />
                        <div className="row justify-content-start align-items-center">
                          <div className="col-12 col-lg-4 mb-2">
                            <label
                              className="common-form-label"
                              htmlFor="coaching"
                            >
                              Giornata
                            </label>
                            <Select
                              isClearable={false}
                              className="basic-single"
                              classNamePrefix="custom-dropdown"
                              placeholder=""
                              name="coaching"
                              options={[
                                { label: "Tutto", value: "ALL" },
                                { label: "Mattina", value: "MORNING" },
                                { label: "Pomeriggio", value: "AFTERNOON" },
                              ]}
                              onChange={(type) => {
                                handleCoachingType(type?.value);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </>
              )}

              {(appointment?.contactOutcome === "NEGATIVE" || (appointment?.positiveResults?.includes("NEGATIVO") || positiveResults.includes("NEGATIVO"))) && (
                <>
                  <hr className="hr-text" data-content="ESITO NEGATIVO" />
                  {/* PHARMACY STATUS */}
                  <>
                    {/* detail */}
                    {appointment?.pharmacyStatus !==
                      EnAppointmentPharmacyStatus.NORMAL && (
                      <div className="row justify-content-start align-items-center my-4">
                        <div className="col-12 col-md-2">
                          <label className="common-form-label">
                            Stato Farmacia
                          </label>
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            {appointment?.pharmacyStatus ===
                              EnAppointmentPharmacyStatus.ELIMINATA && (
                              <span className="badge badge-danger mr-1 p-2">
                                Elimina
                              </span>
                            )}
                            {appointment?.pharmacyStatus ===
                              EnAppointmentPharmacyStatus.RICONTATTARE && (
                              <span className="badge badge-info mr-1 p-2">
                                Ricontattare
                              </span>
                            )}
                            {appointment?.pharmacyStatus ===
                              EnAppointmentPharmacyStatus.CATENE_E_GRUPPI && (
                              <span className="badge badge-danger mr-1 p-2">
                                Catene-Gruppi
                              </span>
                            )}
                          </div>
                        </div>
                        {appointment?.pharmacyStatus !==
                          EnAppointmentPharmacyStatus.CATENE_E_GRUPPI &&
                          !!appointment?.negativeResult && (
                            <div className="col-12 col-md-auto">
                              <label className="common-form-label">
                                Motivazione
                              </label>
                              <div className="d-flex flex-row justify-content-start align-items-center">
                                <span className="badge badge-info mr-1 p-2">
                                  {appointment?.negativeResult?.reason}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {appointment?.pharmacyStatus ===
                      EnAppointmentPharmacyStatus.NORMAL && (
                      <div className="row justify-content-start align-items-center my-4">
                        <div className="col-auto col-xl-2 my-2">
                          <label className="common-form-label">
                            Stato farmacia
                          </label>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            style={{ minWidth: "5rem" }}
                          >
                            <div
                              id="delete-pharmacy"
                              onClick={() =>
                                handlePharmacyStatusCheckboxChanges(
                                  EnAppointmentPharmacyStatus.ELIMINATA
                                )
                              }
                              className={
                                checkboxPharmacyStatusDeleted
                                  ? "custom-checkbox checkbox-delete checkbox-active"
                                  : "custom-checkbox checkbox-delete"
                              }
                            />
                            <label className="common-form-label ml-2 mb-0">
                              Eliminata
                            </label>
                          </div>
                        </div>
                        <div className="col-auto col-xl-2 my-2">
                          <label className="common-form-label">&nbsp;</label>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            style={{ minWidth: "5rem" }}
                          >
                            <div
                              id="re-contact-pharmacy"
                              onClick={() =>
                                handlePharmacyStatusCheckboxChanges(
                                  EnAppointmentPharmacyStatus.RICONTATTARE
                                )
                              }
                              className={
                                checkboxPharmacyStatusReContact
                                  ? "custom-checkbox checkbox-delete checkbox-active"
                                  : "custom-checkbox checkbox-delete"
                              }
                            />
                            <label className="common-form-label ml-2 mb-0">
                              Ricontattare
                            </label>
                          </div>
                        </div>
                        <div className="col-auto col-xl-2 my-2">
                          <label className="common-form-label">&nbsp;</label>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            style={{ minWidth: "5rem" }}
                          >
                            <div
                              id="chains-groups-pharmacy"
                              onClick={() =>
                                handlePharmacyStatusCheckboxChanges(
                                  EnAppointmentPharmacyStatus.CATENE_E_GRUPPI
                                )
                              }
                              className={
                                checkboxPharmacyStatusChainGroups
                                  ? "custom-checkbox checkbox-delete checkbox-active"
                                  : "custom-checkbox checkbox-delete"
                              }
                            />
                            <label className="common-form-label ml-2 mb-0">
                              Catene-Gruppi
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 my-1">
                          <NegativeResult
                            negativeResult={negativeResult}
                            setNegativeResult={setNegativeResult}
                            onChange={handleNegativeResultChanges}
                            isDisabled={
                              pharmacyStatus ===
                              EnAppointmentPharmacyStatus.NORMAL
                            }
                          />
                        </div>
                      </div>
                    )}
                  </>
                </>
              )}

              {/* SAMPLING */}
              {(((appointment?.contactOutcome === "POSITIVE" && (appointment.appointmentDate || appointmentDate)) || appointment?.contactOutcome === "NEGATIVE") &&
                ((appointment?.contactOutcome !== "MAIL_REMINDER") && (!appointment?.positiveResults.includes("MAIL_REMINDER") && !positiveResults.includes("MAIL_REMINDER")))) && (
                <>
                  <hr className="hr-text" data-content="CAMPIONATURA" />
                  <>
                    {/* detail */}
                    {appointment?.sampling !== 2 && (
                      <>
                        <div className="row justify-content-start align-items-center mb-4">
                          <div className="col-12">
                            <label className="common-form-label">
                              Campioni
                            </label>
                            <div className="d-flex flex-row justify-content-start align-items-center">
                              <span className="badge badge-dark mr-1 p-2">
                                {appointment?.sampling &&
                                appointment?.sampling === 1
                                  ? "SI"
                                  : "NO"}
                              </span>
                            </div>
                          </div>
                        </div>
                        {appointment?.sampling === 1 && (
                          <div className="row justify-content-start align-items-center mb-4">
                            <div className="col-12">
                              <ul className="list-group w-100">
                                <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                                  <b>Dettaglio</b>
                                </li>
                                {appointment?.samples?.map(
                                  (sample: any, index: number) => (
                                    <li
                                      key={index}
                                      className="list-group-item d-flex justify-content-between align-items-center text-center"
                                    >
                                      <span className="text-muted">
                                        {sample?.product?.name}
                                      </span>
                                      <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                                        {sample?.quantity}
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                  <>
                    {/* edit */}
                    {
                      (appointment?.sampling === 2) && (
                      <div className="row justify-content-start align-items-center mb-4">
                        <div className="col my-1">
                          <label style={{ color: "black" }}>Campioni</label>
                          <br />
                          <button
                            type="button"
                            className={
                              sampling === 1
                                ? "btn btn-sm btn-dark"
                                : "btn btn-sm btn-outline-dark"
                            }
                            onClick={() => handleSamplingChanges(1)}
                          >
                            Si
                          </button>
                          <button
                            type="button"
                            className={
                              sampling === 0
                                ? "btn btn-sm btn-dark"
                                : "btn btn-sm btn-outline-dark"
                            }
                            onClick={() => handleSamplingChanges(0)}
                          >
                            No
                          </button>
                        </div>
                        {sampling === 1 && (
                          <div className="col-12 my-2">
                            <GeneralSelection
                              title="Campionatura"
                              hasStock={true}
                              handleChange={handleSampleChanges}
                              data={samples}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </>
              )}

              {loading && (
                <div
                  className="container-fluid w-100 h-100"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 10,
                    backgroundColor: "rgba(255, 255, 255, .6)",
                  }}
                >
                  <div className="row justify-content-center align-items-center w-100 h-100">
                    <div className="col text-center">
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Caricamento...</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </form>
  );
};
