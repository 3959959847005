import React, {useEffect, useRef, useState} from 'react';
import MaterialTable from 'material-table';
import {useHistory, useParams} from "react-router-dom";
import {useToasts} from 'react-toast-notifications';
import {
    fetchAllDocumentAvailableEmployees,
    fetchDocument, fetchDocumentFile,
    uploadDocument
} from '../Service';
import {Switch, withStyles} from '@material-ui/core';
import {green, grey} from '@material-ui/core/colors';
import {BackButton} from '../../../components/BackButton/BackButton';
import {PatchedPagination} from "../../../components/PatchedPagination/PatchedPagination";
import SignatureCanvas from 'react-signature-canvas';
import {PDFDocument, degrees, grayscale, rgb, StandardFonts } from 'pdf-lib'
import './Style.css'

import PictureAsPdf from "@material-ui/icons/FindInPage";
import ArrowDropDown from "@material-ui/icons/CloudDownload";

enum EnModalAction {
    VIEW = 1,
    SING = 2
}

interface PharmacyEmployeesProps {
}

class IconPen extends React.Component {
    render() {
        return <svg className="umbrella" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" aria-labelledby="title" fill={"#707070"}>
            <title id="title">Umbrella Icon</title>
            <path d="m18.85 10.39 1.06-1.06c.78-.78.78-2.05 0-2.83L18.5 5.09c-.78-.78-2.05-.78-2.83 0l-1.06 1.06 4.24 4.24zm-5.66-2.83L4 16.76V21h4.24l9.19-9.19-4.24-4.25zM19 17.5c0 2.19-2.54 3.5-5 3.5-.55 0-1-.45-1-1s.45-1 1-1c1.54 0 3-.73 3-1.5 0-.47-.48-.87-1.23-1.2l1.48-1.48c1.07.63 1.75 1.47 1.75 2.68zM4.58 13.35C3.61 12.79 3 12.06 3 11c0-1.8 1.89-2.63 3.56-3.36C7.59 7.18 9 6.56 9 6c0-.41-.78-1-2-1-1.26 0-1.8.61-1.83.64-.35.41-.98.46-1.4.12-.41-.34-.49-.95-.15-1.38C3.73 4.24 4.76 3 7 3s4 1.32 4 3c0 1.87-1.93 2.72-3.64 3.47C6.42 9.88 5 10.5 5 11c0 .31.43.6 1.07.86l-1.49 1.49z"/>
        </svg>
    }
}
export const DocumentsEmployees: React.FC<PharmacyEmployeesProps> = ({ }) => {
    let history = useHistory();
    let params: any = useParams();
    const { addToast } = useToasts();

    const [employee, setEmployee] = useState<any>([]);
    const [selectDocument, setSelectDocument] = useState<any>(null);

    const [serverDocument, setServerDocument] = useState<any>(null);

    const [documents, setDocuments] = useState<any>([]);
    const [documentId, setDocumentsId] = useState<number>();
    const [metadata, setMetadata] = useState<any>([]);
    const [informatives, setInformatives] = useState<any>([]);
    const [statements, setStatements] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const signatureRef = useRef(null);
    const [signedPdfBytes, setSignedPdfBytes] = useState('');
    const [signedPdf, setSignedPdf] = useState(null);
    const [modalemail, setModalEmail] = useState<string | null>(null);

    const [visualEmail, setVisualEmail] = useState<boolean>(false);



    const iframeRef = useRef(null);

    const [modalAction, setModalAction] = useState<EnModalAction>(EnModalAction.VIEW);

    useEffect(() => {
        load().catch(error => console.error(error));
    }, []);

    const load = async () => {
        const pharmacyId: any = params?.id;
        const employeesId: any = params?.idEmp;
        if (!pharmacyId || !employeesId) {
            history.goBack();
            return;
        }
        setLoading(true);
        const documents: Array<any> = await fetchAllDocumentAvailableEmployees(pharmacyId, employeesId) as Array<any>;
        console.log("DOCS", documents)
        setDocuments(documents);
        setLoading(false);
    }

    const goBack = () => {
        history.goBack();
    }

    const reset = () => {
        const ROLE: any = document.getElementById('role');
        if (ROLE != null) {
            ROLE.value = '';
        }
        const MODAL: any = document.getElementById("closePharmacyEmployeesModal");
        if (MODAL != null) {
            MODAL.click();
        }
    }

    const handleSignPdf = async () => {
        if(signatureRef !== null) {
            // @ts-ignore
            if (!signatureRef.current.isEmpty()) {

                /*if(modalemail === null){
                    addToast('Per firmare il file devi inserire la tua email', { appearance: 'error', autoDismiss: false });
                    setLoading(false)
                    return;
                }*/

                const pdfDoc = await PDFDocument.create();
                pdfDoc.setTitle(selectDocument.fileName);

                //const page = pdfDoc.addPage();
                const timesRomanBold = await pdfDoc.embedFont(StandardFonts.HelveticaOblique)

                const pdfLoadDoc = await PDFDocument.load(serverDocument);

                const copiedPagesA = await pdfDoc.copyPages(pdfLoadDoc, pdfLoadDoc.getPageIndices());
                copiedPagesA.forEach((page, index) => {
                    //if(index === 0) return;
                    pdfDoc.addPage(page)
                });

                /*const preamble = await pdfDoc.embedPage(pdfLoadDoc.getPages()[0]);

                const preambleDims = preamble.scale(1);

                page.drawPage(preamble, {
                    ...preambleDims,
                    x: page.getWidth() / 2 - preambleDims.width / 2,
                    y: page.getHeight() / 2 - preambleDims.height / 2 - 50,
                });*/

                const lastPage = pdfDoc.getPage(pdfDoc.getPageCount() -1 );
                // @ts-ignore
                const image = signatureRef.current.toDataURL('image/png');
                const pngImage = await pdfDoc.embedPng(image);
                lastPage.drawImage(pngImage, {
                    x: metadata.signs[0].x,
                    y: metadata.signs[0].y,
                    width: 200,
                    height: 100,
                });

                const textSize = 14

                const email = modalemail ? modalemail : '';

                let general = false;
                informatives.map((informative: any) => {
                    if(informative.check){
                        general = true;
                    }
                    const page = pdfDoc.getPage(informative.page);
                    page.drawText(informative.check ? "Opt In" : "Opt Out", {
                        x: informative.x,
                        y: informative.y,
                        size: 8,
                        font: timesRomanBold,
                        color: rgb(0, 0, 0),
                    })

                })

                if(general){
                    lastPage.drawText(email, {
                        x: metadata.emails[0].x,
                        y: metadata.emails[0].y,
                        size: 8,
                        font: timesRomanBold,
                        color: rgb(0, 0, 0),
                    })
                }
                const signedPdf = await pdfDoc.save();

                download(signedPdf)
            }else{
                addToast('Per firmare il file devi aggiungere una firma!', { appearance: 'error', autoDismiss: false });

                setLoading(false)
            }
        }
    };

    const download = async (signedPdfFinal: Uint8Array) => {

        try {
            // @ts-ignore
            const blob = new Blob([signedPdfFinal], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            const pharmacyId: any = params?.id;
            const employeesId: any = params?.idEmp;

            const metadata =  informatives.map((informative: any) => {
                let inf = statements.filter((obj : any) => {
                    return obj.name === informative.text
                })
                console.log(inf)
                return {
                    value: informative.check ? "Opt In" : "Opt Out",
                    name: informative.text,
                    id: inf.length > 0 && inf[0].id ? inf[0].id : null
                }
            })

            const pdf = await uploadDocument(pharmacyId, employeesId, documentId!, blob, modalemail, metadata);

            const documents: Array<any> = await fetchAllDocumentAvailableEmployees(pharmacyId, employeesId) as Array<any>;
            setDocuments(documents);

            /*
            const a = document.createElement('a');
            document.body.appendChild(a);
            //a.style = 'display: none';
            a.href = url;
            a.download = 'Test.pdf';
            //a.target = '_blank';
            a.click();
            */
            setLoading(false)

            resetModal();

            $('#pharmacyEmployeesModal').modal('hide');

            addToast('Firmato con successo!', { appearance: 'success', autoDismiss: true });
        } catch (error) {
            setLoading(false);
            const response = Array.isArray(error?.response) && error?.response.length > 0 ? error?.response[0] : null;
            const message = response || error?.message || 'Errore generico. Riprova più tardi.';
            addToast(message, { appearance: 'error', autoDismiss: false });
        }

    }

    const getPdf = async (document: any, action: any) => {
        setDocumentsId(document!.document.id)
        setEmployee(document!.pharmacyEmployee);
        setMetadata(document!.document.metadata);
        setSelectDocument(document!.document);
        setStatements(document!.statements);

        const info = document!.document.metadata.informatives.map( (informative: any) => {
            informative.check = false;
            return informative;
        })
        setInformatives(info);

        const metadata = document!.document.metadata;
        const pharmacyId: any = params?.id;
        const employeesId: any = params?.idEmp;
        let pdf = null;

        if(action === EnModalAction.VIEW){
            pdf = await fetchDocumentFile(pharmacyId, employeesId, document!.file.id);
        }else{
            pdf = await fetchDocument(pharmacyId, employeesId, document!.document.id);
        }


        const pdfDoc = await PDFDocument.create();
        pdfDoc.setTitle(document!.document.fileName);

        const timesRomanBold = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);

        //const page = pdfDoc.addPage();

        // @ts-ignore
        const pdfLoadDoc = await PDFDocument.load(pdf);

        const copiedPagesA = await pdfDoc.copyPages(pdfLoadDoc, pdfLoadDoc.getPageIndices());
        copiedPagesA.forEach((page, index) => {
            //if(index === 0) return;
            pdfDoc.addPage(page)
        });

        /*const preamble = await pdfDoc.embedPage(pdfLoadDoc.getPages()[0]);

        const preambleDims = preamble.scale(1);

        page.drawPage(preamble, {
            ...preambleDims,
            x: page.getWidth() / 2 - preambleDims.width / 2,
            y: page.getHeight() / 2 - preambleDims.height / 2 - 50,
        });*/

        if(action === EnModalAction.SING) {

            const text = document!.pharmacyEmployee.lastName + " " + document!.pharmacyEmployee.firstName;
            const textSize = 14


            metadata.names.map((name: any) => {
                const page = pdfDoc.getPage(name.page);
                page.drawText(text, {
                    x: name.x,
                    y: name.y,
                    size: textSize,
                    font: timesRomanBold,
                    color: rgb(0, 0, 0),
                })
            })
        }


        const signedPdfBase64 = await pdfDoc.saveAsBase64();
        const sigPdf = await pdfDoc.save();
        // @ts-ignore
        setSignedPdf(sigPdf);
        setServerDocument(signedPdfBase64);

        $('#pharmacyEmployeesModal').modal('show');
    }

    const resetModal = () => {
        setDocumentsId(undefined);
        setServerDocument(null);

        if(signatureRef.current){
            // @ts-ignore
            signatureRef.current.clear();
        }

        setVisualEmail(false);

        setSignedPdfBytes('');
        setModalEmail(null);
    }

    const changeCheck = (index: number) => {
        if(index === 0 || index === 1){ setVisualEmail(true)}
        const i = informatives;
        i[index].check = ! informatives[index].check;
        setInformatives([...i]);
    }

    const dowloadPDF = async (serverDocument: any) => {

        const realDocument = serverDocument!.file;
        const pharmacyId: any = params?.id;
        const employeesId: any = params?.idEmp;
        const pdf = await fetchDocumentFile(pharmacyId, employeesId, realDocument.id);

        const pdfDoc = await PDFDocument.create();
        pdfDoc.setTitle(serverDocument.document.fileName);

        const page = pdfDoc.addPage();

        // @ts-ignore
        const pdfLoadDoc = await PDFDocument.load(pdf);

        const copiedPagesA = await pdfDoc.copyPages(pdfLoadDoc, pdfLoadDoc.getPageIndices());
        copiedPagesA.forEach((page, index) => {
            if(index === 0) return;
            pdfDoc.addPage(page)
        });

        const preamble = await pdfDoc.embedPage(pdfLoadDoc.getPages()[0]);

        const preambleDims = preamble.scale(1);
        page.drawPage(preamble, {
            ...preambleDims,
            x: page.getWidth() / 2 - preambleDims.width / 2,
            y: page.getHeight() / 2 - preambleDims.height / 2 - 50,
        });

        const signedPdf = await pdfDoc.save();

        const blob = new Blob([signedPdf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        document.body.appendChild(a);

        //a.href = url;
        a.download = serverDocument.document.fileName + '.pdf';
        a.target = '_blank';
        // @ts-ignore
        a.onclick = window.open(url,"_blank");
        document.body.appendChild(a);
        a.click();

    }

    const dowloadPDFLink = async () => {
        // @ts-ignore
        const blob = new Blob([signedPdf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        //a.href = url;
        a.download = 'NHCO.pdf';
        a.target = '_blank';
        // @ts-ignore
        a.onclick = window.open(url,"_blank");

        document.body.appendChild(a);

        a.click();
    }

    const handleOnChangeEmail = (event: any) => {
        setModalEmail(event.target.value);
    }

    // @ts-ignore
    return (
        <div className="content">
            <div className="container-fluid container-material-table-height-fix">

                <div className="d-flex flex-row justify-content-start mb-4">
                    <BackButton />
                </div>

                <MaterialTable
                    title={"Lista Documenti"}
                    components={{
                        // https://github.com/mbrn/material-table/pull/2937#issuecomment-879017952
                        Pagination: PatchedPagination
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Nessun dato disponibile',
                            addTooltip: 'Aggiungi',
                            deleteTooltip: 'Elimina',
                            editTooltip: 'Modifica',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            },
                            editRow: {
                                deleteText: 'Vuoi effettuare la modifica?',
                                cancelTooltip: 'Annulla',
                                saveTooltip: 'Salva'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'righe'
                        },
                        header: {
                            actions: ''
                        },
                        toolbar: {
                            searchTooltip: 'Cerca',
                            searchPlaceholder: 'Cerca'
                        }
                    }}
                    options={{
                        search: false,
                        showTitle: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50],
                        actionsColumnIndex: -1,
                        draggable: false,
                    }}
                    columns={[
                        {
                            title: 'Nome',
                            render: (rowData) => {
                                return (
                                    <div style={{position:'relative', display:'flex', flexDirection:'row', justifyContent:'start', alignItems:'center'}}>
                                        <div className={`traffic-light ${rowData.signed ? "bg-success" : "bg-warning"} animate__animated animate__bounceIn`} style={{ width: '10px', height: '10px', borderRadius: '5px' }} />

                                        <div>
                                            <p style={{ margin: '0 0 0 10px'}}>{rowData.document.name}</p>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Data firma',
                            render: (rowData) => {
                                return (
                                    <div style={{position:'relative', display:'flex', flexDirection:'row', justifyContent:'start', alignItems:'center'}}>
                                        <div>
                                            <p style={{ margin: '0 0 0 0'}}>{rowData.signedAt ? new Date(rowData.signedAt).toLocaleString("it-IT") : ''}</p>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Versione',
                            render: (rowData) => {
                                return (
                                    <div style={{position:'relative', display:'flex', flexDirection:'row', justifyContent:'start', alignItems:'center'}}>
                                        <div>
                                            <p style={{ margin: '0 0 0 0'}}>{rowData.version ? rowData.version : ''}</p>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    ]}
                    data={documents}
                    actions={[
                        (rowData : any) => {
                            return {
                                icon: () => <div
                                    style={{ width: '24px', height: '24px', display: 'flex', flexDirection: 'row' }}
                                ><IconPen /></div>,
                                tooltip: 'Documento da firmare',
                                onClick: (event, rowData) => {
                                    const document: any = rowData;
                                    //if(document.signed) {return;}
                                    /*if(!document.pharmacyEmployee.email || document.pharmacyEmployee.email === '' || document.pharmacyEmployee.email === undefined) {
                                        addToast('Si prega di inserire l\'email prima di procerdere.', { appearance: 'error', autoDismiss: false });
                                        return
                                    }*/

                                    setModalAction(EnModalAction.SING);
                                    // @ts-ignore
                                    getPdf(document, EnModalAction.SING);
                                }
                            }
                        },
                        (rowData : any) => {
                            return {
                                hidden: !rowData.signed,
                                icon: () => {
                                    if('ontouchstart' in window) {
                                        return (
                                            <div
                                                style={{width: '24px', height: '24px', display: 'flex', flexDirection: 'row'}}
                                            ><ArrowDropDown color="action"/></div>
                                        )
                                    }
                                    return (
                                        <div
                                            style={{width: '24px', height: '24px', display: 'flex', flexDirection: 'row'}}
                                        ><PictureAsPdf color="action"/></div>
                                    )

                                },
                                tooltip: 'Documento Firmato',
                                onClick: (event, rowData) => {
                                    const document: any = rowData;
                                    //if(!document.signed) return;

                                    if('ontouchstart' in window){
                                        dowloadPDF(document);
                                        return;
                                    }

                                    setModalAction(EnModalAction.VIEW);

                                    // @ts-ignore
                                    getPdf(document, EnModalAction.VIEW);
                                }
                            }
                        }
                    ]}
                />
            </div>
            <div className="modal fade" id="pharmacyEmployeesModal" tabIndex={-1} role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"
                                id="exampleModalLabel">{modalAction === EnModalAction.SING ? 'Aggiungi Firma' : 'Visualizza PDF'}</h5>
                            <button type="button" id="closePharmacyEmployeesModal" className="close" onClick={()=>{
                                if(modalAction === EnModalAction.SING ){
                                    resetModal();
                                }

                                $('#pharmacyEmployeesModal').modal('hide');
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="d-modal-body">

                            { 'ontouchstart' in window ?
                                <div className={"d-flex flex-row justify-content-center"}><button type="button" className="btn btn-outline" style={{ borderRadius: 20, height: 40}}
                                                                                                  onClick={() => {
                                                                                                      dowloadPDFLink();
                                                                                                  }}
                                >
                                    Visualizza PDF non firmato
                                </button></div> : <iframe src={'data:application/pdf;base64,' + serverDocument}
                                                          width="100%"
                                                          height={modalAction === EnModalAction.SING ? "650px" : "600px"}
                                                          title="PDF Viewer"
                                                          ref={iframeRef}
                                ></iframe>}
                            {
                                modalAction === EnModalAction.SING &&
                                <div className="modal-body-sign d-flex flex-column justify-content-center">
                                    <div style={{flexDirection: 'column', width: "100%", display: 'flex', marginTop: 10}}>
                                        {
                                            informatives.map((informative: any, index: number) => {
                                                return (
                                                    <div style={{height: 50, width: '100%', display:'flex', alignItems:'center', marginTop: 10}}>
                                                        <div style={{height: "100%", width: '10%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                            <input
                                                                type="checkbox"
                                                                id="topping"
                                                                name="topping"
                                                                value="Paneer"
                                                                checked={informative.check}
                                                                onChange={(value) => {
                                                                    changeCheck(index);
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{height: "100%", width: '90%', display:'flex', alignItems:'center', paddingLeft: 5}}>
                                                            <span onClick={()=>{
                                                                if(informative.link) {
                                                                    window.open(informative.link, "_blank");
                                                                }
                                                            }} aria-hidden="true">{informative.text}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <input disabled={!visualEmail} type="text" className="form-control common-form-input" id="email" name="email"
                                               aria-describedby="lastNameHelp"
                                               placeholder="Inserisci l'email" value={modalemail === null ? '' : modalemail}
                                               onChange={handleOnChangeEmail}
                                               style={{ marginTop:50, width:'80%', alignSelf:'center'}}
                                        />

                                    </div>
                                    <div className="modal-body-sign-final" style={{ maxWidth: "80%", alignSelf:'center', marginTop:70}}>
                                        <span aria-hidden="true">Firma</span>
                                        <SignatureCanvas ref={signatureRef} penColor="black"
                                                         canvasProps={{
                                                             width: 350,
                                                             height: 160,
                                                             className: 'signature-canvas',
                                                         }} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn common-btn add-new-pharmacy"
                                    style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        //handleModalActionAdd()
                                        if(modalAction === EnModalAction.SING) {
                                            setLoading(true)
                                            handleSignPdf()
                                        }else {

                                            $('#pharmacyEmployeesModal').modal('hide');
                                        }

                                    }}
                                    disabled={loading}>
                                {loading ? <span>Loading...</span> : ( modalAction === EnModalAction.SING ? "Salva" : "Chiudi")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
