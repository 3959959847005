import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {fetchAddPharmacy} from '../Service';
import {fetchAllAgents} from '../../StockManagement/Service';
import useAuth from '../../../hooks/useAuth';
import {EnUserRole} from '../../../helpers/PrivateRoute/PrivateRoute';
import {useToasts} from 'react-toast-notifications';
import Select from 'react-select';
import {IPharmacy} from '../../../interfaces/IPharmacy';
import {BackButton} from '../../../components/BackButton/BackButton';

interface AddPharmacyProps {

}

export const AddPharmacy: React.FC<AddPharmacyProps> = ({}) => {
    let history = useHistory();
    const {addToast} = useToasts();
    const {user, saveUser} = useAuth();

    const [agents, setAgents] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [pharmacy, setPharmacy] = useState<IPharmacy>({} as IPharmacy);

    useEffect(() => {
        load().catch(error => console.error(error));
    }, []);

    const load = async () => {
        setLoading(true);
        const agents: Array<any> = await fetchAllAgents() as Array<any>;
        agents.forEach((agent: any) => {
            agent.label = `${agent.firstName} ${agent.lastName}`;
            agent.value = `${agent.firstName} ${agent.lastName}`;
        });
        setAgents(agents);
        setLoading(false);
    }

    const handleChange = (e: any, type: string) => {
        if (type === 'name') setPharmacy({...pharmacy, name: e.target.value});
        else if (type === 'address') setPharmacy({...pharmacy, address: e.target.value});
        else if (type === 'city') setPharmacy({...pharmacy, city: e.target.value});
        else if (type === 'province') setPharmacy({...pharmacy, province: e.target.value});
        else if (type === 'region') setPharmacy({...pharmacy, region: e.target.value});
        else if (type === 'microbrick') setPharmacy({...pharmacy, microbrick: e.target.value});
        else if (type === 'vat') setPharmacy({...pharmacy, VAT: e.target.value});
        else if (type === 'organic') setPharmacy({...pharmacy, organic: e.target.value});
        else if (type === 'email') setPharmacy({...pharmacy, email: e.target.value});
        else if (type === 'phone') setPharmacy({...pharmacy, phone: e.target.value});
        else if (type === 'contact') setPharmacy({...pharmacy, contact: e.target.value});
        else if (type === 'facebook') setPharmacy({...pharmacy, facebook: e.target.value});
        else if (type === 'instagram') setPharmacy({...pharmacy, instagram: e.target.value});
        else if (type === 'website') setPharmacy({...pharmacy, website: e.target.value});
        else setPharmacy({...pharmacy, note: e.target.value});
    }

    const handleAgent = (value: any) => {
        setPharmacy({...pharmacy, agentId: value.id});
    }

    const handleAddPharmacy = () => {
        setSubmit(true);
        fetchAddPharmacy(pharmacy).then(() => {
            history.push('/pharmacy-registry');
            setSubmit(false);
        }).catch((error: any) => {
            if (error.type === 'FORM_VALIDATION') {
                error.response.forEach((err: any) => {
                    addToast(err, {appearance: 'error', autoDismiss: false});
                });
            } else {
                addToast(error.message, {appearance: 'error', autoDismiss: false});
            }
            setSubmit(false);
        })
    }

    const goBack = () => {
        history.goBack();
    }

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <BackButton />
                    </div>
                    <button type="button" className="btn common-btn"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            disabled={loading || submit}
                            onClick={handleAddPharmacy}>
                        {
                            submit &&
                            <div className="spinner-wrapper mr-2">
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only text-light">Loading...</span>
                                </div>
                            </div>
                        }
                        Salva
                    </button>
                </div>
                <div className="container mt-3">
                    {
                        !loading ?
                            <form>
                                <div className="form-row">
                                    <div className="col-md-9 col-lg-9 mb-3">
                                        <label className="common-form-label">Nome Farmacia<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Nome Farmacia" defaultValue={pharmacy.name}
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'name')}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-9 col-lg-9 mb-3">
                                        <label className="common-form-label">Indirizzo<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Indirizzo"
                                               disabled={loading || submit}
                                               defaultValue={pharmacy.address}
                                               onChange={(e) => handleChange(e, 'address')}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 col-lg-6 mb-3">
                                        <label className="common-form-label">Citta<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Citta"
                                               disabled={loading || submit}
                                               aria-label="Citta" aria-describedby="basic-addon2"
                                               defaultValue={pharmacy.city}
                                               onChange={(e) => handleChange(e, 'city')}/>
                                    </div>
                                    <div className="col-md-3 col-lg-3 mb-3">
                                        <label className="common-form-label">Provincia<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Provincia"
                                               disabled={loading || submit}
                                               aria-label="Provincia" aria-describedby="basic-addon2"
                                               defaultValue={pharmacy.province}
                                               onChange={(e) => handleChange(e, 'province')}/>
                                    </div>
                                    <div className="col-md-3 col-lg-3 mb-3">
                                        <label className="common-form-label">Regione<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Regione"
                                               disabled={loading || submit}
                                               aria-label="Regione" aria-describedby="basic-addon2"
                                               defaultValue={pharmacy.region}
                                               onChange={(e) => handleChange(e, 'region')}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <label className="common-form-label">Codice VAT<span className="mandatory">*</span></label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               id="validationDefault04"
                                               placeholder="Codice VAT" defaultValue={pharmacy.VAT}
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'vat')}/>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <label className="common-form-label">Microbrick</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               id="validationDefault03"
                                               placeholder="Microbrick" defaultValue={pharmacy.microbrick}
                                               disabled={loading || submit}
                                               onChange={(e) => handleChange(e, 'microbrick')}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-11 mb-3">
                                                <label className="common-form-label">Persona di riferimento</label><br/>
                                                <input type="text" className="form-control common-form-input"
                                                       id="validationDefault03"
                                                       placeholder="Persona di riferimento"
                                                       disabled={loading || submit}
                                                       defaultValue={pharmacy.contact}
                                                       onChange={(e) => handleChange(e, 'contact')}/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-11 mb-3">
                                                <label className="common-form-label">Telefono</label><br/>
                                                <input type="text" className="form-control common-form-input"
                                                       id="validationDefault03"
                                                       placeholder="Telefono" defaultValue={pharmacy.phone}
                                                       disabled={loading || submit}
                                                       onChange={(e) => handleChange(e, 'phone')}/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-11 mb-3">
                                                <label className="common-form-label">E-mail</label><br/>
                                                <input type="text" className="form-control common-form-input"
                                                       id="validationDefault03"
                                                       placeholder="E-mail"
                                                       disabled={loading || submit}
                                                       defaultValue={pharmacy.email}
                                                       onChange={(e) => handleChange(e, 'email')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-8">
                                        <div className="form-row">
                                            <label className="common-form-label">Note</label><br/>
                                            <textarea
                                                className="form-control common-form-input"
                                                placeholder="Note"
                                                disabled={loading || submit}
                                                id="exampleFormControlTextarea1" defaultValue={pharmacy?.note}
                                                onChange={(e) => handleChange(e, 'note')} rows={12}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label className="common-form-label">Sito Web</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Sito Web"
                                               disabled={loading || submit}
                                               aria-label="Sito Web" aria-describedby="basic-addon2"
                                               defaultValue={pharmacy.website}
                                               onChange={(e) => handleChange(e, 'website')}/>
                                    </div>
                                    <div className="col">
                                        <label className="common-form-label">Facebook</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Facebook"
                                               disabled={loading || submit}
                                               aria-label="Facebook" aria-describedby="basic-addon2"
                                               defaultValue={pharmacy.facebook}
                                               onChange={(e) => handleChange(e, 'facebook')}/>
                                    </div>
                                    <div className="col">
                                        <label className="common-form-label">Instagram</label><br/>
                                        <input type="text" className="form-control common-form-input"
                                               placeholder="Instagram"
                                               disabled={loading || submit}
                                               aria-label="Instagram" aria-describedby="basic-addon2"
                                               defaultValue={pharmacy.instagram}
                                               onChange={(e) => handleChange(e, 'instagram')}/>
                                    </div>
                                </div>
                                {
                                    (user.role != EnUserRole.AGENT) && <div className="form-row mt-3">
                                        <div className="col-md-4 col-lg-4">
                                            <label>Pharmacy Specialist assegnato</label><br/>
                                            {
                                                agents.length > 0 && <Select
                                                    className="basic-single"
                                                    classNamePrefix="custom-dropdown"
                                                    placeholder="Select"
                                                    name="agent"
                                                    options={agents}
                                                    disabled={loading || submit}
                                                    onChange={(value) => handleAgent(value)}
                                                />
                                            }
                                        </div>
                                    </div>
                                }
                            </form>
                            :
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div className="spinner-wrapper mr-2">
                                    <div className="spinner-border spinner-border-md" role="status">
                                        <span className="sr-only text-light">Loading...</span>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}
