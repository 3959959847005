import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {
  fetchAppointmentById,
  fetchPharmacyStockByPharmacyId,
  fetchProductSampleStockByAgentId,
} from "../Service";
import { DateTime } from "luxon";
import { fetchProducts } from "../../StockManagement/Service";
import { EnAppointmentPharmacyStatus } from "../LanguageMappings";
import { SelloutTable } from "../../../components/SelloutTable/SelloutTable";
import { BackButton } from "../../../components/BackButton/BackButton";

interface DetailAppointmentProps {}

export const DetailAppointmentComponent: React.FC<DetailAppointmentProps> = (
  props: any
) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { user } = useAuth();

  // Default
  const [products, setProducts] = useState<Array<any>>([]);
  const [stockSpecialist, setStockSpecialist] = useState<Array<any>>([]);
  const [stockPharmacy, setStockPharmacy] = useState<Array<any>>([]);
  const [appointment, setAppointment] = useState<any>(null);

  const [samples, setSamples] = useState<Array<any>>([]);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [reorders, setReOrders] = useState<Array<any>>([]);
  const [sellouts, setSellouts] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const appointmentId = props.match.params.id || null;
    if (appointmentId === null) {
      addToast("Appuntamento non valido", {
        appearance: "error",
        autoDismiss: false,
      });
      goBack();
      return;
    }
    setLoading(true);
    fetchAppointmentById(appointmentId)
      .then((appointment: any) => {
        setAppointment(appointment || null);
        setLoading(false);
      })
      .catch((e) => {
        console.error("An error occurred", e);
        addToast("Errore durante il caricamento dei dati.", {
          appearance: "error",
          autoDismiss: false,
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!appointment) {
      return;
    }
    setLoading(true);
    Promise.all([
      fetchProducts(),
      fetchPharmacyStockByPharmacyId(appointment?.pharmacy?.id),
      fetchProductSampleStockByAgentId(user.sub),
    ])
      .then((results: Array<any>) => {
        setProducts(results[0] || []);
        setStockPharmacy(results[1] || []);
        setStockSpecialist(results[2] || []);
        setLoading(false);
      })
      .catch((e) => {
        console.error("An error occurred", e);
        addToast(
          "Errore durante il caricamento dei dati relativi allo stock della farmacia.",
          {
            appearance: "error",
            autoDismiss: false,
          }
        );
        setLoading(false);
      });
  }, [appointment]);

  useEffect(() => {
    if (!products) {
      return;
    }
    setOrders(elaborateStockFor("order", stockPharmacy));
    setReOrders(elaborateStockFor("reOrder", stockPharmacy));
    setSamples(
      elaborateStockFor("sample", stockSpecialist, (value) => value.stock > 0)
    );
  }, [products]);

  // const load = async () => {
  //   try {
  //     setLoading(true);
  //     const appointmentId = props.match.params.id;
  //     const appointment: any = await fetchAppointmentById(appointmentId);
  //     setAppointment(appointment);
  //     const products: Array<any> = await fetchProducts();
  //     setProducts(products);
  //     // Load and handle specialist samples and pharmacy stock to retrieve: samples, orders and reorders
  //     let specialistSampleList: Array<any> = await fetchProductSampleStockByAgentId(
  //       user.sub
  //     );
  //     let pharmacyStockList: Array<any> = await fetchPharmacyStockByPharmacyId(
  //       appointment?.pharmacy?.id
  //     );
  //     setSamples(
  //       await elaborateCollectedData(
  //         products,
  //         specialistSampleList,
  //         "sample",
  //         (value) => value.stock > 0
  //       )
  //     );
  //     setOrders(
  //       await elaborateCollectedData(products, pharmacyStockList, "order")
  //     );
  //     setReOrders(
  //       await elaborateCollectedData(products, pharmacyStockList, "reOrder")
  //     );
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error", error);
  //     addToast("Appuntamento non valido", {
  //       appearance: "error",
  //       autoDismiss: false,
  //     });
  //     goBack();
  //     return;
  //   }
  // };

  const elaborateStockFor = (
    key: "sample" | "order" | "reOrder",
    stock: Array<any>,
    callback: (value: any) => boolean = () => true
  ): Array<any> => {
    const samples: Array<any> = products.filter((value) => value[key] === 1);
    const mapped: Array<any> = samples.map((value) => ({
      id: value.id,
      name: value.name,
      quantity: undefined,
      active: false,
      stock: stockSpecialist.reduce(
        (previousValue, currentValue) =>
          currentValue.productId === value.id
            ? currentValue.quantity
            : previousValue,
        0
      ),
    }));
    return mapped.filter(callback);
  };

  const goBack = () => {
    history.push("/appointments-and-training?toggleState=APPOINTMENTS");
  };

  const parseAppointmentDate = (value: string | null = null) => {
    if (value === null) {
      return;
    }
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
  };

  const parseAppointmentCoachingType = (value: string) => {
    switch (value) {
      case "ALL":
        return "Tutto";
      case "MORNING":
        return "Mattina";
      case "AFTERNOON":
        return "Pomeriggio";
      default:
        return "";
    }
  };

  const parseAppointmentContactType = (value: string) => {
    switch (value) {
      case "PHONE":
        return "Telefonico";
      case "IN_PERSON":
        return "Fisico";
      case "MAIL":
        return "Mail";
      default:
        return "";
    }
  };

  const parseAppointmentOutcome = (value: string) => {
    switch (value) {
      case "POSITIVE":
        return "Positivo";
      case "NEGATIVE":
        return "Negativo";
      case "MAIL_REMINDER":
        return "Mail Reminder";
      default:
        return "";
    }
  };

  const parseAppointmentPositiveResult = (value: string) => {
    switch (value) {
      case "ORDER":
        return "ORDINE";
      case "RE_ORDER":
        return "RIORDINE";
      case "VISIT":
        return "VISITA";
      case "SELL_OUT_CHECK":
        return "VERIFICA SELL-OUT";
      case "ALLESTIMENTO":
        return "ALLESTIMENTO";
      case "MAIL_REMINDER":
        return "MAIL REMINDER";
      case "NEGATIVO":
        return "NEGATIVO";
      case "COACHING":
        return "ATTIVITÀ DI COACHING";
    }
  };

  const getProductName = (productId: number): string => {
    const product: any = products.find((p) => p.id === productId);
    return product?.name;
  };

  return (
    <form className="content">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <BackButton callback={goBack} />
          </div>
          <div>{/* add actions here */}</div>
        </div>

        {appointment && (
          <>
            <div className="container my-5">
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Nome Farmacia</label>
                </div>
                <div className="col-auto">{appointment?.pharmacy?.name}</div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Persona di riferimento</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.contact || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Telefono</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.phone || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Città</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.city || "-"}
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-2">
                  <label>Indirizzo</label>
                </div>
                <div className="col-auto">
                  {appointment?.pharmacy?.address || "-"}
                </div>
              </div>
            </div>

            <div
              className="container-fluid animate__animated animate__fadeIn p-5"
              style={{
                position: "relative",
                zIndex: 0,
                border: "3px dashed #CACACA",
              }}
            >
              <div className="row justify-content-start align-items-center">
                <div className="col-12 col-md-4 my-1">
                  <label className="common-form-label">Data Contatto</label>
                  <input
                    type="text"
                    className="form-control common-form-input"
                    id="contactDate"
                    name="contactDate"
                    readOnly={true}
                    defaultValue={parseAppointmentDate(
                      appointment?.contactDate
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 my-1">
                  <label className="common-form-label">Tipo Contatto</label>
                  <input
                    type="text"
                    className="form-control common-form-input"
                    id="contactType"
                    name="contactType"
                    readOnly={true}
                    defaultValue={parseAppointmentContactType(
                      appointment?.contactType
                    )}
                  />
                </div>
                <div className="col-12 col-md-4 my-1">
                  <label className="common-form-label">Esito Contatto</label>
                  <input
                    type="text"
                    className="form-control common-form-input"
                    id="contactOutcome"
                    name="contactOutcome"
                    readOnly={true}
                    defaultValue={parseAppointmentOutcome(
                      appointment?.contactOutcome
                    )}
                  />
                </div>
              </div>

              {(appointment?.contactOutcome === "POSITIVE") && (
                <>
                  {(appointment.appointmentDate || appointment.positiveResults.length !== 0) && <hr className="hr-text" data-content="ESITO POSITIVO" />}
                  {/* APPOINTMENT DATE */}
                  <>
                    {/* detail */}
                    {appointment?.appointmentDate && (
                      <div className="row mb-4">
                        <div className="col-4">
                          <label className="common-form-label">
                            Data Appuntamento
                          </label>
                          <input
                            type="text"
                            className="form-control common-form-input"
                            id="appointmentDate"
                            name="appointmentDate"
                            readOnly={true}
                            defaultValue={parseAppointmentDate(
                              appointment?.appointmentDate
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </>
                  {/* POSITIVE RESULT */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.length > 0 && (
                      <div className="row mb-4">
                        <div className="col-6">
                          <label className="common-form-label">
                            Esito Appuntamento
                          </label>
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            {appointment?.positiveResults?.map(
                              (value: string, index: number) => (
                                <span
                                  key={index}
                                  className="badge badge-dark mr-1 p-2"
                                >
                                  {parseAppointmentPositiveResult(value)}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  {/* ORDER */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes("ORDER") && (
                      <>
                        <hr className="hr-text" data-content="ORDINE" />
                        <div className="row justify-content-start align-items-center mb-4">
                        <div className="col-12">
                          <ul className="list-group w-100">
                            {appointment?.orders?.map(
                              (order: any, index: number) => (
                                <li
                                  key={index}
                                  className="list-group-item d-flex justify-content-between align-items-center text-center"
                                >
                                  <span className="text-muted">
                                    {getProductName(order.productId)}
                                  </span>
                                  <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                                    {order.quantity}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      </>
                    )}
                  </>
                  {/* RE_ORDER */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes("RE_ORDER") && (
                      <>
                        <hr className="hr-text" data-content="RIORDINE" />
                        <div className="row justify-content-start align-items-center mb-4">
                        <div className="col-12">
                          <ul className="list-group w-100">
                            {appointment?.reorders?.map(
                              (reorders: any, index: number) => (
                                <li
                                  key={index}
                                  className="list-group-item d-flex justify-content-between align-items-center text-center"
                                >
                                  <span className="text-muted">
                                    {getProductName(reorders.productId)}
                                  </span>
                                  <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                                    {reorders.quantity}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      </>
                    )}
                  </>
                  {/* SELL-OUT */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes(
                      "SELL_OUT_CHECK"
                    ) && (
                      <>
                        <hr className="hr-text" data-content="VERIFICA SELL-OUT" />
                        <div className="row justify-content-start align-items-center mb-4">
                        <div className="col-12">
                          <SelloutTable
                            pharmacy={appointment?.pharmacy?.id}
                            date={appointment?.appointmentDate}
                            sellouts={sellouts}
                            setSellouts={setSellouts}
                            isDetail={true}
                          />
                        </div>
                      </div>
                      </>
                    )}
                  </>
                  {/* ATTIVITÀ DI COACHING */}
                  <>
                    {/* detail */}
                    {appointment?.positiveResults?.includes("COACHING") && (
                      <>
                        <hr className="hr-text" data-content="ATTIVITÀ DI COACHING" />
                        <div className="row justify-content-start align-items-center">
                          <div className="col-12 col-md-4 my-1">
                            <label className="common-form-label">Giornata</label>
                            <input
                              type="text"
                              className="form-control common-form-input"
                              id="coaching"
                              name="coaching"
                              readOnly={true}
                              defaultValue={parseAppointmentCoachingType(
                                appointment?.coachingType
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </>
              )}

              {(appointment?.contactOutcome === "NEGATIVE" || appointment?.positiveResults.includes("NEGATIVO")) && (
                <>
                  <hr className="hr-text" data-content="ESITO NEGATIVO" />
                  {/* PHARMACY STATUS */}
                  <>
                    {/* detail */}
                    {appointment?.pharmacyStatus !==
                      EnAppointmentPharmacyStatus.NORMAL && (
                      <div className="row justify-content-start align-items-center my-4">
                        <div className="col-12 col-md-2">
                          <label className="common-form-label">
                            Stato Farmacia
                          </label>
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            {appointment?.pharmacyStatus ===
                              EnAppointmentPharmacyStatus.ELIMINATA && (
                              <span className="badge badge-danger mr-1 p-2">
                                Elimina
                              </span>
                            )}
                            {appointment?.pharmacyStatus ===
                              EnAppointmentPharmacyStatus.RICONTATTARE && (
                              <span className="badge badge-info mr-1 p-2">
                                Ricontattare
                              </span>
                            )}
                            {appointment?.pharmacyStatus ===
                              EnAppointmentPharmacyStatus.CATENE_E_GRUPPI && (
                              <span className="badge badge-danger mr-1 p-2">
                                Catene-Gruppi
                              </span>
                            )}
                          </div>
                        </div>
                        {appointment?.pharmacyStatus !==
                          EnAppointmentPharmacyStatus.CATENE_E_GRUPPI &&
                          !!appointment?.negativeResult && (
                            <div className="col-12 col-md-auto">
                              <label className="common-form-label">
                                Motivazione
                              </label>
                              <div className="d-flex flex-row justify-content-start align-items-center">
                                <span className="badge badge-info mr-1 p-2">
                                  {appointment?.negativeResult?.reason}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </>
                </>
              )}

              {/* SAMPLING */}
              {(appointment?.sampling !== 2) && (
                <>
                  {/* detail */}
                  <hr className="hr-text" data-content="CAMPIONATURA" />
                  <div className="row justify-content-start align-items-center mb-4">
                    <div className="col-12">
                      <label className="common-form-label">Campioni</label>
                      <div className="d-flex flex-row justify-content-start align-items-center">
                        <span className="badge badge-dark mr-1 p-2">
                          {appointment?.sampling && appointment?.sampling === 1
                            ? "SI"
                            : "NO"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {appointment?.samples?.length > 0 && (
                    <div className="row justify-content-start align-items-center mb-4">
                      <div className="col-12">
                        <ul className="list-group w-100">
                          <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                            <b>Dettaglio</b>
                          </li>
                          {appointment?.samples?.map(
                            (sample: any, index: number) => (
                              <li
                                key={index}
                                className="list-group-item d-flex justify-content-between align-items-center text-center"
                              >
                                <span className="text-muted">
                                  {sample?.product?.name}
                                </span>
                                <span className="badge badge-pill badge-info mat-appoint-badge-table px-2 py-1 m-1">
                                  {sample?.quantity}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </>
              )}

              {loading && (
                <div
                  className="container-fluid w-100 h-100"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 10,
                    backgroundColor: "rgba(255, 255, 255, .6)",
                  }}
                >
                  <div className="row justify-content-center align-items-center w-100 h-100">
                    <div className="col text-center">
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Caricamento...</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </form>
  );
};
